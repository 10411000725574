import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Logo } from "../../components/Logo";
import AuthCTA from '../../components/AuthCTA';
import Title from '../../components/Title';
import axios from '../../../api/axios';
import { backendUrl } from "../../../config";

const Password = () => {

    const { id } = useParams();
    const RECOVERY_URL = `${backendUrl}/w/recovery/${id}`;
    const PASSWORD_URL = `${backendUrl}/w/recovery/${id}/password`;

    const icon_ok = '<svg className="w-[16px] h-[16px] mr-2" aria-label="ok" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="green"><path d="M15.736 3.872L13.7 1.836l-7.939 7.94L2.3 6.315.264 8.35l5.497 5.497 9.975-9.975z"></path></svg>';
    const icon_remove = '<svg className="w-[16px] h-[16px] mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="red"><path d="M14.3 3.625L12.375 1.7 8 6.075 3.625 1.7 1.7 3.625 6.075 8 1.7 12.375 3.625 14.3 8 9.925l4.375 4.375 1.925-1.925L9.925 8 14.3 3.625z"></path></svg>';

    const [pr1, setPr1] = useState({__html: icon_remove});
    const [pr1Success, setPr1Success] = useState(false);
    const [pr2, setPr2] = useState({__html: icon_remove});
    const [pr2Success, setPr2Success] = useState(false);
    const [pr3, setPr3] = useState({__html: icon_remove});
    const [pr3Success, setPr3Success] = useState(false);
    const [pr4, setPr4] = useState({__html: icon_remove});
    const [pr4Success, setPr4Success] = useState(false);
    const [pr5, setPr5] = useState({__html: icon_remove});
    const [pr5Success, setPr5Success] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [inputType, setInputType] = useState('password');
    const [passwordSwitch, setPasswordSwitch] = useState('Show');
    const [fl, setFL] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const fetchData = async () => {
        try {
            // Get key status
            const response = await axios.get(RECOVERY_URL);
            if (response?.status === 200) {
                if ( typeof response.data.username === 'string' && response.data.username.length > 0 ) {
                    setShowForm(true);
                    setUsername(response.data.username);
                    setFL(response.data.username.substring(0, 1));
                }
            }
        } catch (error) {
            // Invalid or expired key
            setShowForm(false);
        }
    };

    useEffect(() => {
        fetchData(); // Call the async function here
    }, []);

    useEffect(() => {
        // Whenever any of prXSuccess variables changes, update isDisabled
        setIsDisabled(!(pr1Success && pr2Success && pr3Success && pr4Success && pr5Success));
    }, [pr1Success, pr2Success, pr3Success, pr4Success, pr5Success]);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);

        console.log( (event.target.value).length )

        if ( (event.target.value).length > 8 && (event.target.value).length < 25 ) {
            setPr1({__html: icon_ok});
            setPr1Success(true);
        } else {
            setPr1({__html: icon_remove});
            setPr1Success(false);
        }

        if ( (/[0-9]/).test(event.target.value) ) {
            setPr2({__html: icon_ok});
            setPr2Success(true);
        } else {
            setPr2({__html: icon_remove});
            setPr2Success(false);
        }

        if ( (/[a-z]/).test(event.target.value) ) {
            setPr3({__html: icon_ok});
            setPr3Success(true);
        } else {
            setPr3({__html: icon_remove});
            setPr3Success(false);
        }

        if ( (/[A-Z]/).test(event.target.value) ) {
            setPr4({__html: icon_ok});
            setPr4Success(true);
        } else {
            setPr4({__html: icon_remove});
            setPr4Success(false);
        }
        
        //if ( (/[~`!@#$%^&*()_+\-=\[\]{}<>'"\.]/).test(event.target.value) ) {
        if ( (/[-!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/).test(event.target.value) ) {
            setPr5({__html: icon_ok});
            setPr5Success(true);
        } else {
            setPr5({__html: icon_remove});
            setPr5Success(false);
        }

    };


    const handlePasswordSwitch = () => {
        if ( passwordSwitch === 'Show' ) {
            setPasswordSwitch('Hide');
            setInputType('text');
        } else {
            setPasswordSwitch('Show');
            setInputType('password');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = {
            password: password
        };

        try {

            // Get key status
            const response = await axios.post(PASSWORD_URL, data);
            
            if (response?.status === 200) {
                setShowForm(false);
                setShowSuccess(true);
                setUsername('');
                setPassword('');
                setFL('');
            }
        } catch (error) {
            // Invalid or expired key
            setShowForm(false);
            window.showToast('WCL19903');
        }
    };

    return ( 
        <section className="select-none">
            
            <Title set="Account Recovery" />

            { showSuccess && ( 
                <div className="grid lg:h-screen lg:grid-cols-2">
                    <AuthCTA />
                    <div className="flex items-center justify-center px-4 py-6 lg:py-0 sm:px-0">
                        <form className="w-full max-w-md space-y-6 md:space-y-8 xl:max-w-xl">
                            <div>
                                <div className="flex justify-center items-center text-2xl font-semibold text-white my-8">
                                    <Link to="/"><Logo size="medium" /></Link>
                                </div>
                                <div className="flex justify-center items-center text-2xl font-bold text-gray-900 my-4 uppercase">Successful Password Reset</div>
                                <p className="flex justify-center items-center text-sm text-gray-500">Your password has been successfully reset. Please proceed to sign in.</p>
                            </div>
                            <div className="flex items-center justify-center">
                                <Link to="/login" className="text-sm font-semibold text-kb-300 hover:underline ml-1">Return to Log In</Link>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            { !showSuccess && showForm && (
                <div className="grid lg:h-screen lg:grid-cols-2">
                    <AuthCTA />
                    <div className="flex items-center justify-center px-4 py-6 lg:py-0 sm:px-0">
                        <form className="w-full max-w-md space-y-6 md:space-y-8 xl:max-w-xl" onSubmit={handleSubmit}>
                            <div>
                                <div className="flex justify-center items-center text-2xl font-semibold text-white my-8">
                                    <Link to="/"><Logo size="medium" /></Link>
                                </div>
                                <div className="flex justify-center items-center text-2xl font-bold text-gray-900 my-4 uppercase">Reset Your Password</div>
                                <p className="flex justify-center items-center text-sm text-gray-500">This action will forcefully sign every device out.</p>
                            </div>
                            <div className="flex justify-center">
                                <div className="flex items-center text-sm p-2 rounded-full border border-gray-300 bg-gray-50">
                                    <div className="flex items-center justify-center uppercase rounded-full bg-gray-300 mr-2 w-[32px] h-[32px] pl-[1px] pt-[1px]">
                                        <div className="font-serif font-bold text-lg text-gray-900">{fl}</div>
                                    </div>
                                    <div className="text-gray-900 mr-1">{username}</div>
                                </div>
                            </div>
                            <div className="relative pb-8">
                                <div>
                                    <input type={inputType} name="password" onChange={handlePasswordChange} autoComplete="hostney-username" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full py-3.5" placeholder="Password" required="" />
                                </div>
                                <p className="absolute right-0 p-1" onClick={handlePasswordSwitch}><a href="#" className="text-sm font-semibold text-kb-300 hover:underline ml-1">{passwordSwitch}</a></p>
                            </div>

                            <div>
                                <div className="text-sm text-gray-900">Create a password that contains at least:</div>
                                <div className="text-sm py-4 text-gray-900 space-y-1">

                                    <div className={`flex items-center rounded ${ pr1Success ? 'bg-green-200' : 'bg-gray-200'} py-1 px-2`}>
                                        <div className="w-[16px] h-[16px] mr-2" dangerouslySetInnerHTML={pr1}></div>
                                        <span className="small align-middle font-bold">8-24 characters</span>
                                    </div>

                                    <div className={`flex items-center rounded ${ pr2Success ? 'bg-green-200' : 'bg-gray-200'} py-1 px-2`}>
                                        <div className="w-[16px] h-[16px] mr-2" dangerouslySetInnerHTML={pr2}></div>
                                        <span className="small align-middle font-bold">1 number</span>
                                    </div>

                                    <div className={`flex items-center rounded ${ pr3Success ? 'bg-green-200' : 'bg-gray-200'} py-1 px-2`}>
                                        <div className="w-[16px] h-[16px] mr-2" dangerouslySetInnerHTML={pr3}></div>
                                        <span className="small align-middle font-bold">1 lowercase letter</span>
                                    </div>

                                    <div className={`flex items-center rounded ${ pr4Success ? 'bg-green-200' : 'bg-gray-200'} py-1 px-2`}>
                                        <div className="w-[16px] h-[16px] mr-2" dangerouslySetInnerHTML={pr4}></div>
                                        <span className="small align-middle font-bold">1 uppercase letter</span>
                                    </div>

                                    <div className={`flex items-center rounded ${ pr5Success ? 'bg-green-200' : 'bg-gray-200'} py-1 px-2`}>
                                        <div className="w-[16px] h-[16px] mr-2" dangerouslySetInnerHTML={pr5}></div>
                                        <span className="small align-middle font-bold">1 special character <span className="font-normal">e.g., $, !, @, %, &</span></span>
                                    </div>

                                </div>
                            </div>

                            <button type="submit" className="w-full text-white bg-kb-300 hover:bg-kb-600 focus:ring-4 focus:outline-none focus:ring-kb-300 font-medium rounded text-sm px-5 text-center py-3.5 disabled:bg-kb-500 disabled:text-kb-400" disabled={isDisabled}>Continue</button>
                            <div className="flex items-center justify-center">
                                <Link to="/login" className="text-sm font-semibold text-kb-300 hover:underline ml-1">Return to Log In</Link>
                            </div>

                        </form>
                    </div>
                </div>
            )}

            { !showSuccess && !showForm && ( 
                <div className="grid lg:h-screen lg:grid-cols-2">
                    <AuthCTA />
                    <div className="flex items-center justify-center px-4 py-6 lg:py-0 sm:px-0">
                        <form className="w-full max-w-md space-y-6 md:space-y-8 xl:max-w-xl">
                            <div>
                                <div className="flex justify-center items-center text-2xl font-semibold text-white my-8">
                                    <Link to="/"><Logo size="medium" /></Link>
                                </div>
                                <div className="flex justify-center items-center text-2xl font-bold text-gray-900 my-4 uppercase">Invalid Recovery Key</div>
                                <p className="flex justify-center items-center text-sm text-gray-500">Your recovery key is either not valid or has expired.</p>
                            </div>
                            <div className="flex items-center justify-center">
                                <Link to="/login" className="text-sm font-semibold text-kb-300 hover:underline ml-1">Return to Log In</Link>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            
        </section>
    );
}

export default Password;

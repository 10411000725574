import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from 'axios';
import { backendUrl } from "../../../config";

const MegaMenuItems = ({ categories, onDragEnd, openModal }) => {
    const [subcategories, setSubcategories] = useState([]);

    useEffect(() => {
        const fetchSubcategories = async () => {
            try {
                const subcategoriesData = await Promise.all(
                    categories.map(async (category) => {
                        const response = await axios.get(`${backendUrl}/a/menu/items/${category.id}`, { withCredentials: true });
                        return {
                            ...category,
                            originalId: category.id,
                            subcategories: response.data.items
                        };
                    })
                );
                setSubcategories(subcategoriesData);
            } catch (error) {
                console.log(error);
            }
        };
    
        fetchSubcategories();
    }, [categories]);
    

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const sourceDroppableIdParts = result.source.droppableId.split('-');
        const destinationDroppableIdParts = result.destination.droppableId.split('-');
        const sourceCategoryId = parseInt(sourceDroppableIdParts[1]);
        const destinationCategoryId = parseInt(destinationDroppableIdParts[1]);

        if (sourceCategoryId === destinationCategoryId) {
            const sourceSubcategories = subcategories.find(category => category.id === sourceCategoryId).subcategories;
            const updatedSourceSubcategories = reorder(sourceSubcategories, result.source.index, result.destination.index);

            const updatedSubcategories = subcategories.map(category => {
                if (category.id === sourceCategoryId) {
                    return {
                        ...category,
                        subcategories: updatedSourceSubcategories
                    };
                }
                return category;
            });

            setSubcategories(updatedSubcategories);

        } else {
            const updatedCategories = reorder(categories, result.source.index, result.destination.index);
            onDragEnd(updatedCategories);
            window.showToast('WCL20406');
        }
    };

    const handleSubcategoryDragEnd = (result) => {
        const { source, destination } = result;
    
        if (!destination) {
            return;
        }
    
        const sourceSubcategoryId = parseInt(source.droppableId.split('-')[1]);
        const destinationSubcategoryId = parseInt(destination.droppableId.split('-')[1]);
    
        if (sourceSubcategoryId === destinationSubcategoryId) {
            // Same subcategory, reorder the items
            const sourceSubcategory = subcategories.find(category => category.id === sourceSubcategoryId);
            const destinationSubcategory = subcategories.find(category => category.id === destinationSubcategoryId);
    
            if (sourceSubcategory && destinationSubcategory) {
                const reorderedSubcategories = reorder(sourceSubcategory.subcategories, source.index, destination.index);
                sourceSubcategory.subcategories = reorderedSubcategories;
    
                setSubcategories(prevSubcategories => {
                    return prevSubcategories.map(subcategory => {
                        if (subcategory.id === sourceSubcategoryId) {
                            return sourceSubcategory;
                        } else if (subcategory.id === destinationSubcategoryId) {
                            return destinationSubcategory;
                        } else {
                            return subcategory;
                        }
                    });
                });
    
                // Call the API to update the subcategory order
                updateSubcategoryOrder(subcategories, destinationSubcategoryId);

                window.showToast('WCL20406');
            }
        }
    };
    

    const updateSubcategoryOrder = async (subcategories, category_id) => {
        try {
            const response = await axios.post(`${backendUrl}/a/menu/items/${category_id}`, subcategories, { withCredentials: true });
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };


    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };


    return (

        //  Generate Main Categories
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="categories" type="CATEGORY" key={categories.map(category => category.id).join('-')}>
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} className="select-none">
                        {subcategories.map((category, index) => (
                            <Draggable key={`category-${category.id}`} draggableId={`category-${category.id}`} index={index}>

                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps} className="p-4 bg-gray-50 border border-gray-200 mb-6">

                                        {/* Mark the Accessories Columns */}
                                        {index === 5 || index === 11 ? <div className="pb-6 text-red-600 font-semibold">
                                            This category shows in the Accessories column
                                        </div> : null}

                                        {/* Mark inactive categories */}
                                        {index >= 12 ? <div className="pb-6 text-red-300 font-semibold">
                                            This category will not show on the website
                                        </div> : null}

                                        <div className="flex gap-x-3 items-center">
                                            <div className="text-kb-300/50 hover:text-kb-300" {...provided.dragHandleProps}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" className="fill-current text-kb-300/50 hover:fill-kb-300 hover:text-kb-300" ><circle cx="12" cy="6" r="2"></circle> <circle cx="12" cy="12" r="2"></circle> <circle cx="12" cy="18" r="2"></circle> <circle cx="6" cy="6" r="2"></circle> <circle cx="6" cy="12" r="2"></circle> <circle cx="6" cy="18" r="2"></circle></svg>
                                            </div>
                                            <div className="flex gap-x-10">
                                                <div className="text-kb-300 font-bold">{category.name}</div>
                                                <div className="flex gap-x-6">
                                                    <a href="#" className="hover:text-kb-300 hover:underline" onClick={(e) => { e.preventDefault(); openModal('editCategory', category.id, null, category.name, category.slug, null, category.relation_id)} }>Edit</a>
                                                    <a href="#" className="hover:text-kb-300 hover:underline" onClick={(e) => { e.preventDefault(); openModal('deleteCategory', category.id, null, category.name)} }>Delete</a>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Generate Subcategory Content */}
                                        <DragDropContext onDragEnd={handleSubcategoryDragEnd}>
                                            <Droppable droppableId={`category-${category.id}-subcategories`} type="SUBCATEGORY">
                                                {(provided) => (
                                                    <div {...provided.droppableProps} ref={provided.innerRef} className="border-t border-l border-r border-gray-200 mt-4">
                                                        {/* Subcategories */}
                                                        {category.subcategories.map((subcategory, subIndex) => (
                                                            <Draggable key={`subcategory-${subcategory.id}`} draggableId={`subcategory-${subcategory.id}`} index={subIndex}>
                                                                {(provided) => (
                                                                    <>
                                                                        <div ref={provided.innerRef} {...provided.draggableProps} className="p-4 bg-gray-100 border-b border-gray-200 gap-x-3 items-center">
                                                                            <div>
                                                                                {/* Mark as hidden if more than 6 items */}
                                                                                {subIndex >= 6 ? <div className="pb-6 text-red-300 font-semibold w-full">
                                                                                    This menu item will not show on the website
                                                                                </div> : null}
                                                                            </div>
                                                                            <div className="flex">
                                                                                <div className="text-kb-300/50 hover:text-kb-300" {...provided.dragHandleProps}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" className="fill-current text-kb-300/50 hover:fill-kb-300 hover:text-kb-300" ><circle cx="12" cy="6" r="2"></circle> <circle cx="12" cy="12" r="2"></circle> <circle cx="12" cy="18" r="2"></circle> <circle cx="6" cy="6" r="2"></circle> <circle cx="6" cy="12" r="2"></circle> <circle cx="6" cy="18" r="2"></circle></svg>
                                                                                </div>
                                                                                <div className="flex justify-between w-full">
                                                                                    <div className="text-kb-300/75 font-bold">{subcategory.name}</div>
                                                                                    <div className="flex gap-x-6">
                                                                                        <a href="#" className="hover:text-kb-300 hover:underline" onClick={(e) => { e.preventDefault(); openModal('editSubCategory', null, subcategory.id, subcategory.name, subcategory.slug, subcategory.is_group)} }>Edit</a>
                                                                                        <a href="#" className="hover:text-kb-300 hover:underline" onClick={(e) => { e.preventDefault(); openModal('deleteSubCategory', null, subcategory.id, subcategory.name)} }>Delete</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {/* End of Subcategories */}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>

                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default MegaMenuItems;


import { staticUrl } from "../../../../config";

const ProductCategoriesItem = (props) => {

    const product = props.product;
    const colors = props.colors;

    return (
        <>
            <div className="bg-white relative" style={{ boxShadow: '0 0 25px rgba(112, 128, 144, 0.30)' }}>
                { product.is_new ? (
                    <div className="absolute top-6 right-6 bg-kb-400 text-white py-1 px-3 rounded items-center text-sm uppercase font-medium">New</div>
                ) : (
                    <></>
                ) }

                { product.relation_id ? (
                    <img src={`${staticUrl}/img/media/products/product_${product.relation_id}.jpg`} alt="" />
                ) : (
                    <img src={`${staticUrl}/img/noimage.jpg`} alt="" />
                ) }

                { colors && (
                    <div className="flex items-center space-x-3 p-6 pt-5">
                        <div className="h-[32px] -ml-3"></div>
                        { colors[`${product.product_id}`].slice(0, 3).map((color, index) => (
                            <div key={index} className="w-[32px] h-[32px] bg-gray-200">
                                <img src={`${staticUrl}/img/media/colors/thumbnail_${color}.jpg`} alt="" />
                            </div>
                        )) }
                        
                        { colors[`${product.product_id}`].length > 3 && (
                            <span className="text-kb-400/70">+ More</span>
                        ) }
                    </div>
                )}
            </div>
            <div className="flex w-full justify-center py-6">
                <div className="text-center">
                    <div className="text-kb-400">
                        {product.product_name}
                    </div>
                    <div className="text-kb-300 font-bold uppercase">
                        {product.collection_name}
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default ProductCategoriesItem;
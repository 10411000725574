import { Outlet, Route, Routes, useNavigate, useParams } from "react-router-dom";
import Layout from "../../../layout/logged_out/Layout";
import { useEffect, useState } from "react";
import axios from "axios";
import Fabrics from "./Fabrics";
import Loading from '../../components/Loading';
import { backendUrl } from "../../../config";
import { MdKeyboardArrowUp } from "react-icons/md";
import FabricFilter from "./components/FabricFilter";

const FabricsCategories = () => {

    const { '*': collectionId } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const [showMainCategory, setShowMainCategory] = useState(false);
    const [fabricsSP, setFabricsSP] = useState([]);
    const [fabricsHP, setFabricsHP] = useState([]);
    const [groupOrder, setGroupOrder] = useState([]);
    const [topText, setTopText] = useState('');
    const [middleText, setMiddleText] = useState('');
    const [bottomText, setBottomText] = useState('');

    const filter = async () => {
        try {

            setIsLoading(true);

            let data = {
                type: 'fabrics'
            };

            let response = await axios.post(`${backendUrl}/w/filter`, data);

            setFabricsSP(response.data.fabrics_sp);
            setFabricsHP(response.data.fabrics_hp);
            setGroupOrder(response.data.group_order);

            response = await axios.get(`${backendUrl}/w/fabrics`);
            setTopText(response.data.fabrics.top);
            setMiddleText(response.data.fabrics.middle);
            setBottomText(response.data.fabrics.bottom);

            setIsLoading(false);
            
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {

        if ( collectionId.split('/').length === 1 && collectionId.split('/')[0].match(/[a-z]+/) ) {
            filter();
            setShowMainCategory(false);
        } else {
            filter();
            setShowMainCategory(true);
        }

    }, [navigate]);

    // Scroll to top
    const [showScrollButton, setShowScrollButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 0) {
                setShowScrollButton(true);
            } else {
                setShowScrollButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    if ( isLoading ) {
        return <Loading />;
    }

    return ( 
        <>
            <Layout title='Fabrics'>
                <main className='mt-[81px] kb-menu:mt-[97px] text-lg tracking-wider'>
                    <>
                        {!showMainCategory && (
                            <>
                                <Routes>
                                    <Route path=":fabricId" element={<Fabrics />} />
                                </Routes>
                                <Outlet />
                            </>
                        )}
                    </>

                    {showMainCategory && (
                        <FabricFilter hp={fabricsHP} sp={fabricsSP} topText={topText} middleText={middleText} bottomText={bottomText} slug={collectionId.split('/')[0]} groupOrder={groupOrder} />
                    )}

                </main>
                {showScrollButton && (
                    <button onClick={scrollToTop} className="fixed z-50 bottom-8 right-8 p-2 shadow-md text-white text-lg bg-kb-300 hover:bg-kb-600 rounded">
                        <MdKeyboardArrowUp />
                    </button>
                )}
            </Layout>
        </>
    );
}
 
export default FabricsCategories;
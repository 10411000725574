
import { useEffect, useState, useRef } from 'react';
import { staticUrl } from '../../config';

const Search = ({ type }) => {

    // Search
    const [searchQuery, setSearchQuery] = useState('');
    const [searchData, setSearchData] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const searchRef = useRef(null);
    const searchResultsRef = useRef(null);

    useEffect(() => {
        // Fetch JSON data on page load
        fetch(`${staticUrl}/search.json`)
            .then(response => response.json())
            .then(data => {
                setSearchData(data);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
        // Filter search results based on search query
        const filteredResults = searchData.filter(item =>
            item.name.toLowerCase().includes(query) ||
            item.description.toLowerCase().includes(query) ||
            item.category.toLowerCase().includes(query)
        );
        setSearchResults(filteredResults);
    };

    const groupSearchResultsBySource = () => {
        const groupedResults = {};
        searchResults.forEach(result => {
            if (groupedResults[result.source]) {
                groupedResults[result.source].push(result);
            } else {
                groupedResults[result.source] = [result];
            }
        });
        return groupedResults;
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchResultsRef.current && !searchResultsRef.current.contains(event.target) && !searchRef.current.contains(event.target)) {
                // Click occurred outside the search results, close it
                setSearchResults([]);
                setSearchQuery('');
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [searchResults]);

    const highlightText = (text, query) => {
        const regex = new RegExp(`(${query})`, 'gi');
        return text.replace(regex, '<mark style="background-color: #004d43; color: #fff;">$1</mark>');
    };


    return (
        <div>

            <div className="relative">
                <label htmlFor="search" className="sr-only">Search</label>
                <div className="relative lg:w-52">
                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <svg
                            className="w-4 h-4 text-gray-500"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                            />
                        </svg>
                    </div>
                    <input
                        type="text"
                        name="sf"
                        id="search"
                        className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full pl-10 py-1.5"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        ref={searchRef}
                    />
                </div>
            </div>
            
            {searchResults.length > 0 && searchQuery.match(/[a-zA-Z]+/) && (
                <div ref={searchResultsRef} className={`kb-search absolute pt-4 rounded ${ type === 'mobile' ? 'top-16' : 'top-20' } ${ type === 'mobile' ? '' : 'right-8' } bg-white text-sm ${ type === 'mobile' ? 'w-[370px]' : 'w-[400px]' } max-h-[270px] border border-kb-400/75 overflow-y-auto text-kb-400`}>
                    {searchQuery && (
                        <>
                            {/* Iterate over each source and display "Found in [Source]" headers if there are search results */}
                            {Object.entries(groupSearchResultsBySource()).map(([source, results], index) => (
                                <div key={source}>
                                    {results.length > 0 && (
                                        <h2 className={index !== 0 ? 'border-b border-kb-400/75 pt-4 pb-2 px-4 font-bold' : 'border-b border-kb-400/75 pb-2 font-bold px-4'}>
                                            Found in {source}:
                                        </h2>
                                    )}
                                    <ul>
                                        {results.map((result, itemIndex) => (
                                            <li key={itemIndex} className={itemIndex !== results.length - 1 ? 'border-b border-kb-400/25 hover:bg-gray-100/75 py-3 px-4' : 'hover:bg-gray-100/75 py-3 px-4'}>
                                                <a href={result.url} className='space-y-0.5'>
                                                    <h3 dangerouslySetInnerHTML={{ __html: highlightText(result.name, searchQuery) }} />
                                                    <p dangerouslySetInnerHTML={{ __html: highlightText(result.description, searchQuery) }} />
                                                    { result.category.match(/[a-z]/i) && (
                                                        <p>{result.category}</p>
                                                    ) }
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            )}

            {searchResults.length === 0 && searchQuery.match(/[a-zA-Z]+/) && (
                <div ref={searchResultsRef} className={`kb-search absolute rounded ${ type === 'mobile' ? 'top-16' : 'top-20' } ${ type === 'mobile' ? '' : 'right-8' } bg-white text-sm ${ type === 'mobile' ? 'w-[370px]' : 'w-[400px]' } max-h-[270px] border border-kb-400/75 overflow-y-auto text-kb-400`}>
                    <p className="p-4 text-center">Nothing to display.</p>
                </div>
            )}
            
        </div>
    );

}

export default Search;
import Title from "../../views/components/Title";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ title, mediaLibrary = false, children }) => {

    return (
        <>
            <Title set={title} />
            <Header mediaLibrary={mediaLibrary} />
                <div className="antialiased">
                    {children}
                </div>
            <Footer />
        </>
    );

};

export default Layout;
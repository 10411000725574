import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NotFound from './views/pages/error/404';
import Login from './views/pages/auth/Login';
// import Signup from './views/pages/auth/Signup';
import Recovery from './views/pages/auth/Recovery';
import Password from './views/pages/auth/Password';
import Authenticator from './views/pages/auth/Authenticator';
import Challenge from './views/pages/auth/Challenge';
import Dashboard from './layout/logged_in/Dashboard';
import Confirm from './views/pages/auth/Confirm';
import ProtectedRoute from './views/components/ProtectedRoute';
import ProtectedRouteAdmin from './views/components/ProtectedRouteAdmin';
import Toast from './views/components/Toast';
import Index from './views/pages/logged_out/Index';
import Product from './views/pages/logged_out/Product';
import Collections from './views/pages/logged_out/Collections';
import FAQ2 from './views/pages/logged_out/FAQ2';
import AboutUs from './views/pages/logged_out/AboutUs';
import PrivacyPolicy from './views/pages/logged_out/PrivacyPolicy';
import TermsAndConditions from './views/pages/logged_out/TermsAndConditions';
import ContactUs from './views/pages/logged_out/ContactUs';
import MenuCategories from './views/pages/logged_in/menu/MegaMenu';
import ProductsAdmin from './views/pages/logged_in/products/ProductsAdmin';
import ProductsList from './views/pages/logged_in/products/ProductsList';
import ImageLibrary from './views/pages/logged_in/Images/ImageLibrary';
import DocumentLibrary from './views/pages/logged_in/documents/DocumentLibrary';
import ProductCategories from './views/pages/logged_out/ProductCategories';
import DataLibrary from './views/pages/logged_in/data/DataLibrary';
import FabricsCategories from './views/pages/logged_out/FabricsCategories';
import FabricsAdmin from './views/pages/logged_in/fabrics/FabricsAdmin';
import FabricsList from './views/pages/logged_in/fabrics/FabricsList';
import LandingPageAdmin from './views/pages/logged_in/landing-page/LandingPageAdmin';
import WhereToBuy from './views/pages/logged_out/WhereToBuy';
import TradeShows from './views/pages/logged_out/TradeShows';
import FooterContent from './views/pages/logged_in/content/FooterContent';
import FAQContent from './views/pages/logged_in/content/FAQContent';
import AboutUsContent from './views/pages/logged_in/content/AboutUsContent';
import { useMegaMenu } from './lib/MegaMenuContext';
import { backendUrl } from './config';
import Sequence from './views/pages/logged_in/content/Sequence';
import MediaLibrary from './views/pages/logged_in/media-library/MediaLibrary';
import ApplyMediaLibrary from './views/pages/logged_out/ApplyMediaLibrary';
import RequestAccount from './views/pages/logged_out/RequestAccount';
import ContactUsContent from './views/pages/logged_in/content/ContactUsContent';
import TradeShowContent from './views/pages/logged_in/content/TradeShowContent';
import WheretoBuyContent from './views/pages/logged_in/content/WheretoBuyContent';
import FilterGroups from './views/pages/logged_in/filters/Groups';
import FilterNames from './views/pages/logged_in/filters/Names';
import FilterSequence from './views/pages/logged_in/filters/Sequence';
import MediaLibraryContent from './views/pages/logged_in/content/MediaLibraryContent';

// Add the solid icons to the library
//library.add(fas);


function App() {

    useEffect(() => {
        fetch('/data/codes-en.json')
            .then((response) => response.json())
            .then((data) => {
                window.ErrorCodes = data;
            })
            .catch((error) => {
                console.error('Error fetching JSON:', error);
            });
    }, []);

    const { setMegaMenuData } = useMegaMenu();

    useEffect(() => {
        fetch(`${backendUrl}/w/mega-menu`)
            .then((response) => response.json())
            .then((data) => {
                setMegaMenuData({ menu: data.menu, footer_about: data.footer_about, sections: data.sections, sale: data.sale, terms: data.terms, privacy: data.privacy, catalog: data.catalog, main_carousel: data.main_carousel, product_carousel: data.product_carousel, collection_carousel: data.collection_carousel, mobile_image: data.mobile_image, tax: data.tax, waiver: data.waiver, mobile_slug: data.mobile_slug });
            })
            .catch((error) => {
                console.error('Error fetching JSON:', error);
            });
    }, [setMegaMenuData]);

    return (
        <>
            <Toast />
            <Router>
                <Routes>
                    <Route path="/" element={<Index />} />
                    <Route path="/faq" element={<FAQ2 />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                    <Route path="/login" element={<Login />} />
                    {/* <Route path="/sign-up" element={<Signup />} /> */}
                    <Route path="/two-factor" element={<Authenticator />} />
                    <Route path="/challenge" element={<Challenge />} />
                    <Route path="/recovery" element={<Recovery />} />
                    <Route path="/recovery/:id" element={<Password />} />
                    <Route path="/confirm/:id" element={<Confirm />} />
                    <Route path="/image-library-application" element={<ApplyMediaLibrary />} />
                    <Route path="/request-account" element={<RequestAccount />} />
                    <Route path="/products/*" element={<Product />} />
                    <Route path="/fabrics/*" element={<FabricsCategories />} />
                    <Route path="/categories/*" element={<ProductCategories />} />
                    <Route path="/collections/*" element={<Collections />} />
                    <Route path="/where-to-buy" element={<WhereToBuy />} />
                    <Route path="/trade-shows" element={<TradeShows />} />
                    <Route path="/image-library/*" element={<ProtectedRoute><MediaLibrary /></ProtectedRoute>} />
                    <Route path="/dashboard/*" element={<ProtectedRouteAdmin><Dashboard /></ProtectedRouteAdmin>} />
                    <Route path="/admin/mega-menu/*" element={<ProtectedRouteAdmin><MenuCategories /></ProtectedRouteAdmin>} />
                    <Route path="/admin/content/footer" element={<ProtectedRouteAdmin><FooterContent /></ProtectedRouteAdmin>} />
                    <Route path="/admin/content/faq" element={<ProtectedRouteAdmin><FAQContent /></ProtectedRouteAdmin>} />
                    <Route path="/admin/content/about-us" element={<ProtectedRouteAdmin><AboutUsContent /></ProtectedRouteAdmin>} />
                    <Route path="/admin/content/contact-us" element={<ProtectedRouteAdmin><ContactUsContent /></ProtectedRouteAdmin>} />
                    <Route path="/admin/content/trade-show" element={<ProtectedRouteAdmin><TradeShowContent /></ProtectedRouteAdmin>} />
                    <Route path="/admin/content/where-to-buy" element={<ProtectedRouteAdmin><WheretoBuyContent /></ProtectedRouteAdmin>} />
                    <Route path="/admin/content/media-library" element={<ProtectedRouteAdmin><MediaLibraryContent /></ProtectedRouteAdmin>} />
                    <Route path="/admin/products/new" element={<ProtectedRouteAdmin><ProductsAdmin /></ProtectedRouteAdmin>} />
                    <Route path="/admin/products/list" element={<ProtectedRouteAdmin><ProductsList /></ProtectedRouteAdmin>} />
                    <Route path="/admin/products/:productId/:sectionId" element={<ProtectedRouteAdmin><ProductsAdmin /></ProtectedRouteAdmin>} />
                    <Route path="/admin/images/:libraryId" element={<ProtectedRouteAdmin><ImageLibrary /></ProtectedRouteAdmin>} />
                    <Route path="/admin/documents/:libraryId" element={<ProtectedRouteAdmin><DocumentLibrary /></ProtectedRouteAdmin>} />
                    <Route path="/admin/data/:libraryId" element={<ProtectedRouteAdmin><DataLibrary /></ProtectedRouteAdmin>} />
                    <Route path="/admin/fabrics/list" element={<ProtectedRouteAdmin><FabricsList /></ProtectedRouteAdmin>} />
                    <Route path="/admin/fabrics/sequence/:sequence" element={<ProtectedRouteAdmin><Sequence /></ProtectedRouteAdmin>} />
                    <Route path="/admin/fabrics/:productId/:sectionId" element={<ProtectedRouteAdmin><FabricsAdmin /></ProtectedRouteAdmin>} />
                    <Route path="/admin/pages/landing-page" element={<ProtectedRouteAdmin><LandingPageAdmin /></ProtectedRouteAdmin>} />

                    <Route path="/admin/filters/products/groups" element={<ProtectedRouteAdmin><FilterGroups filterType='products' /></ProtectedRouteAdmin>} />
                    <Route path="/admin/filters/products/names" element={<ProtectedRouteAdmin><FilterNames filterType='products' /></ProtectedRouteAdmin>} />
                    <Route path="/admin/filters/products/sequence" element={<ProtectedRouteAdmin><FilterSequence filterType='products' /></ProtectedRouteAdmin>} />

                    <Route path="/admin/filters/collections/groups" element={<ProtectedRouteAdmin><FilterGroups filterType='collections' /></ProtectedRouteAdmin>} />
                    <Route path="/admin/filters/collections/names" element={<ProtectedRouteAdmin><FilterNames filterType='collections' /></ProtectedRouteAdmin>} />
                    <Route path="/admin/filters/collections/sequence" element={<ProtectedRouteAdmin><FilterSequence filterType='collections' /></ProtectedRouteAdmin>} />

                    <Route path="/admin/filters/fabrics/groups" element={<ProtectedRouteAdmin><FilterGroups filterType='fabrics' /></ProtectedRouteAdmin>} />
                    <Route path="/admin/filters/fabrics/names" element={<ProtectedRouteAdmin><FilterNames filterType='fabrics' /></ProtectedRouteAdmin>} />
                    <Route path="/admin/filters/fabrics/sequence" element={<ProtectedRouteAdmin><FilterSequence filterType='fabrics' /></ProtectedRouteAdmin>} />

                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Router>
        </>
    );
}

export default App;

const ContentURLs = () => {

    return (
        <>
            <div><a href={`/admin/products/list`} className='hover:underline'>Products</a></div>
            <div><a href={`/admin/fabrics/list`} className='hover:underline'>Fabrics</a></div>
            <div><a href={`/admin/fabrics/sequence/1-1`} className='hover:underline'>Sequences</a></div>
            <div><a href={`/admin/content/footer`} className='hover:underline'>Footer</a></div>
            <div><a href={`/admin/content/faq`} className='hover:underline'>FAQ</a></div>
            <div><a href={`/admin/content/about-us`} className='hover:underline'>About Us</a></div>
            <div><a href={`/admin/content/contact-us`} className='hover:underline'>Contact Us</a></div>
            <div><a href={`/admin/content/where-to-buy`} className='hover:underline'>Where to Buy</a></div>
            <div><a href={`/admin/content/trade-show`} className='hover:underline'>Trade Show</a></div>
            <div><a href={`/admin/content/media-library`} className='hover:underline'>Media Library</a></div>
        </>
    );

};

export default ContentURLs;

const FiltersURLs = () => {

    return (
        <>
            <div className="font-semibold border-b border-gray-300 pb-3">Products</div>
            <div><a href={`/admin/filters/products/groups`} className='hover:underline'>Groups</a></div>
            <div><a href={`/admin/filters/products/names`} className='hover:underline'>Names</a></div>
            <div><a href={`/admin/filters/products/sequence`} className='hover:underline'>Sequence</a></div>
            <div className="font-semibold border-b border-gray-300 py-3">Fabrics</div>
            <div><a href={`/admin/filters/fabrics/groups`} className='hover:underline'>Groups</a></div>
            <div><a href={`/admin/filters/fabrics/names`} className='hover:underline'>Names</a></div>
            <div><a href={`/admin/filters/fabrics/sequence`} className='hover:underline'>Sequence</a></div>
            <div className="font-semibold border-b border-gray-300 py-3">Collections</div>
            <div><a href={`/admin/filters/collections/groups`} className='hover:underline'>Groups</a></div>
            <div><a href={`/admin/filters/collections/names`} className='hover:underline'>Names</a></div>
            <div><a href={`/admin/filters/collections/sequence`} className='hover:underline'>Sequence</a></div>
        </>
    );

};

export default FiltersURLs;

/**
 * Validated
 */

const AuthCTA = () => {
    return (
        <div className="hidden xl:flex items-center justify-center px-4 py-6 bg-kb-50 lg:py-0 sm:px-0">
            <div className="max-w-md xl:max-w-xl">
                <h1 className="mb-4 text-3xl font-extrabold tracking-tight text-kb-300 xl:text-5xl xl:leading-tight">Kingsley-Bate crafts elegant outdoor furniture from the finest teak wood, all-weather wicker, rope and stainless steel.</h1>
                <p className="mb-4 text-kb-300 lg:mb-8"><a href="/">kingsleybate.com</a></p>
            </div>
        </div>
    );
}
 
export default AuthCTA;

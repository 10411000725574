

import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Session from '../../../../api/session';
import Title from '../../../components/Title';
import Navbar from '../../../../layout/logged_in/Navbar';
import Sidenav from '../../../../layout/logged_in/Sidenav';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import { backendUrl } from '../../../../config';
import ContentURLs from '../../../components/ContentURLs';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import generateRandomKey from '../../../components/GenerateRandomKey';
import Modal from '../../../components/Modal';

const FAQContent = () => {

    // Modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItemQuestion, setSelectedItemQuestion] = useState('');
    const [selectedItemAnswer, setSelectedItemAnswer] = useState('');

    const openModal = (type, item) => {
        setModalType(type);

        if ( item ) {
            setSelectedItem(item);
            setSelectedItemQuestion(item.question);
            setSelectedItemAnswer(item.answer);
        } else {
            setSelectedItem(null);
            setSelectedItemQuestion('');
            setSelectedItemAnswer('');
        }

        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const updatedQuestionRef = useRef(null);
    const updatedAnswerRef = useRef(null);
    const newQuestionRef = useRef(null);
    const newAnswerRef = useRef(null);

    const handleUpdate = () => {

        const updatedQuestion = updatedQuestionRef.current.value;
        const updatedAnswer = updatedAnswerRef.current.value;

        const updatedItems = items.map(item =>
            item.id === selectedItem.id ? { ...item, question: updatedQuestion, answer: updatedAnswer } : item
        );

        setItems(updatedItems);
        closeModal();
    };

    const handleDelete = () => {
        if (selectedItem) {
            const updatedItems = items.filter(item => item.id !== selectedItem.id);
            setItems(updatedItems);
            closeModal();
        }
    };

    const handleAdd = () => {
        const newQuestion = newQuestionRef.current.value;
        const newAnswer = newAnswerRef.current.value;

        const newItem = {
            id: generateRandomKey(),
            question: newQuestion,
            answer: newAnswer,
        };

        setItems([newItem, ...items]);

        // Close the modal
        setIsModalOpen(false);
    };

    useEffect(() => {

        const getFAQ = async () => {

            const response = await axios.get(`${backendUrl}/a/meta/faq`, { withCredentials: true });
            setItems(response.data.value);

        };

        getFAQ();

    }, []);

    const [items, setItems] = useState([]);

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const newItems = Array.from(items);
        const [reorderedItem] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, reorderedItem);

        setItems(newItems);
    };

    const syncBackend = async () => {

        try {
            
            const data = {
                content: items
            };

            await axios.post(`${backendUrl}/a/meta/faq`, data, { withCredentials: true });
            window.showToast('WCL20468');

        } catch (error) {
            console.error(error);
            window.showToast('WCL20469');
        }

    };

    return (
        <>
            <Session />
            <Title set="FAQ Content" />
            <div className="App">
                
                <div className="antialiased">
                    <Navbar />
                    <Sidenav />
                    <main className="p-4 lg:pl-[270px] h-screen max-w-screen-2xl pt-20 z-0 mx-auto">
                        <div className='flex'>
                            <div className='hidden lg:block lg:fixed top-[95px] w-[175px] p-4 space-y-3 border border-dashed border-gray-200 bg-gray-50'>
                            <div className='font-bold'>CONTENT</div>
                                <ContentURLs />
                            </div>
                            <div className='lg:ml-[195px] w-full'>
                                <div className='block lg:hidden p-4 border border-dashed border-gray-200 bg-gray-50 space-x-0'>
                                    <div className='font-bold'>CONTENT</div>
                                    <ContentURLs />
                                </div>
                                <div className='lg:flex justify-between items-center'>
                                    <div className=''>
                                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light py-7 uppercase'>CONTENT MANAGEMENT</h2>
                                    </div>
                                    <div className='mb-10 lg:mb-0 justify-center lg:justify-start flex gap-x-3'>
                                        <Button size='sm' text='SAVE FAQ' onClick={syncBackend} />
                                    </div>
                                </div>
                                <div className='text-xl bg-gray-200 p-4'>
                                    <div className='flex space-x-2'>
                                        <span className='font-bold'>FAQ</span>
                                    </div>
                                </div>
                                <div className='lg:flex justify-between items-center w-full bg-gray-50 border border-gray-200'>
                                    <div className='justify-center lg:justify-start flex gap-x-3 p-4'>
                                        <Button size='sm' text='NEW FAQ' onClick={() => { openModal('add') }} />
                                    </div>
                                </div>

                                <div className='space-y-3 py-10'>

                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef} className='space-y-4'>
                                                {items.map((item, index) => (
                                                    <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index}>
                                                        {(provided) => (
                                                        <div ref={provided.innerRef} {...provided.draggableProps} className='flex space-x-2 border border-gray-200 p-4 bg-white'>
                                                            <div {...provided.dragHandleProps}>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="currentColor"
                                                                    className="fill-current text-kb-300/50 hover:fill-kb-300 hover:text-kb-300"
                                                                >
                                                                    <circle cx="12" cy="6" r="2"></circle>{" "}
                                                                    <circle cx="12" cy="12" r="2"></circle>{" "}
                                                                    <circle cx="12" cy="18" r="2"></circle>{" "}
                                                                    <circle cx="6" cy="6" r="2"></circle>{" "}
                                                                    <circle cx="6" cy="12" r="2"></circle>{" "}
                                                                    <circle cx="6" cy="18" r="2"></circle>
                                                                </svg>
                                                            </div>

                                                            <div className='space-y-0.5'>
                                                                <div className='font-bold text-kb-300'>{item.question}</div>
                                                                <div className="text-kb-400">{item.answer}</div>
                                                                <div className='space-x-4'>
                                                                    <button onClick={() => { openModal('edit', item) }} className='hover:underline'>Edit</button>
                                                                    <button onClick={() => { openModal('delete', item) }} className='hover:underline'>Delete</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>


                                </div>

                            </div>

                        </div>

                    </main>
                </div>
                
            </div>        
        
            <Modal isOpen={isModalOpen} onClose={closeModal} className='p-0 space-y-6' button width='w-[90%] md:w-[80%] lg:w-[60%] xl:w-[50%] 2xl:w-[40%]'>

                {modalType === 'add' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Add New FAQ</h2>
                        <div className="space-y-2">
                            <div className="font-bold">Question</div>
                            <input ref={newQuestionRef} type="text" className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                        </div>
                        <div className="space-y-2">
                            <div className="font-bold">Answer</div>
                            <textarea ref={newAnswerRef} rows={10} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                        </div>
                        <Button size='sm' text='ADD' onClick={handleAdd} />
                    </>
                )}

                {modalType === 'edit' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Edit FAQ</h2>
                        <div className="space-y-2">
                            <div className="font-bold">Question</div>
                            <input ref={updatedQuestionRef} defaultValue={selectedItemQuestion} type="text" className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                        </div>
                        <div className="space-y-2">
                            <div className="font-bold">Answer</div>
                            <textarea ref={updatedAnswerRef} defaultValue={selectedItemAnswer} rows={10} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                        </div>
                        <Button size='sm' text='UPDATE' onClick={handleUpdate} />
                    </>
                )}

                {modalType === 'delete' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete FAQ</h2>
                        <div className="space-y-2">
                            <div className="font-bold">Are you sure you want to delete this FAQ?</div>
                            <input defaultValue={selectedItemQuestion} disabled type="text" className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                        </div>
                        <Button size='sm' text='DELETE' onClick={handleDelete} />
                    </>
                )}

            </Modal>

        </>
    );
}

export default FAQContent;
import { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Session from '../../api/session';
import Title from '../../views/components/Title';
import Navbar from './Navbar';
import Sidenav from './Sidenav';
import Loading from '../../views/components/Loading';


const Dashboard = () => {

    return (
        <>
            <Session />
            <Title set="Dashboard" />           
            <div className="App">
                <div className="antialiased">
                    <div>
                        <Navbar />
                    </div>
                    <div>
                        <Sidenav />
                    </div>
                    <main className="p-4 md:ml-64 h-screen pt-20 z-0">

                    </main>
                </div>
            </div>
        </>
    );
}
 
export default Dashboard;
import { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
// import { Dropdown } from 'flowbite-react';
import { HiCog, HiAnnotation, HiLogout, HiViewGrid } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Logo } from '../../views/components/Logo';
import Sidenav from './Sidenav';
import { Get as LocalStorageGet, Remove as LocalStorageRemove } from '../../views/components/LocalStorage';
import { backendUrl } from '../../config';

const Navbar = () => {

    const navigate = useNavigate();

    const profileMenuRef = useRef(null);
    const profileButtonRef = useRef(null);
    const [isProfileOpen, setIsProfileOpen] = useState(false);

    const handleProfileClick = () => {
        setIsProfileOpen(!isProfileOpen);
    };

    const handleCloseProfile = (e) => {
        if ( profileMenuRef.current && !profileMenuRef.current.contains(e.target) && profileButtonRef.current && !profileButtonRef.current.contains(e.target)) {
            setIsProfileOpen(false);
        }
    };

    const handleLogoutClick = async () => {

        try {
            
            await axios.get(`${backendUrl}/w/logout`, { withCredentials: true })
            .then((response) => {
                if ( response.status === 200 ) {
                    LocalStorageRemove();
                    navigate('/login');
                }
            }).catch((error) => {
                console.log(error);
            });

        } catch (error) {
            console.log(error);
        }

    };

    const [isMobile, setIsMobile] = useState(false);
    const setDefaultView = isMobile ? false : true;
    const [isOpen, setIsOpen] = useState(setDefaultView);

    const handleMobileView = () => {
        if ( !isMobile ) {
            setIsOpen(true);
        } else {
            if ( isOpen ) {
                setIsOpen(false);
            } else {
                setIsOpen(true);
            }
        }
    };

    useEffect(() => {
        function checkMobileView() {
//            const mobileQuery = window.matchMedia('(max-width: 768px)');
            const mobileQuery = window.matchMedia('(max-width: 1023px)');
            setIsMobile(mobileQuery.matches);
            if ( mobileQuery.matches ) {
                setIsOpen(false);
            } else {
                setIsOpen(true);
            }
        }
    
        checkMobileView(); // Initial check
        window.addEventListener('resize', checkMobileView);
    
        return () => {
            window.removeEventListener('resize', checkMobileView);
        };
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleCloseProfile);
    
        return () => {
            document.removeEventListener('click', handleCloseProfile);
        };
    }, []);

    const user_data = LocalStorageGet();
    const [username, setUsername] = useState(user_data?.username);
    const [first_name, setFirstname] = useState(user_data?.first_name);
    const [last_name, setLastname] = useState(user_data?.last_name);
    const [fl, setFl] = useState(first_name?.substring(0, 1));

    return ( 
        <>
            <header className="antialiased fixed w-full z-40 lg:z-10">
                <nav className="bg-white px-6 py-3 align-middle border-b border-gray-200 max-w" style={{height: '66px'}}>
                    <div className="flex justify-between items-center pt-1">

                        <div className="flex justify-start items-center">
                            <button onClick={handleMobileView} data-drawer-target="sidebar-team-switch" data-drawer-toggle="sidebar-team-switch" aria-controls="sidebar-team-switch" type="button" className="lg:inline -ml-2 p-2 mr-3 text-gray-600 rounded z-50">
                                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12"> <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h14M1 6h14M1 11h7"/> </svg>
                            </button>
                            {isOpen && <Sidenav isOpen={isOpen} />}
                            <a href="https://www.kingsleybate.com/" className="hidden sm:flex mr-7">
                                <Logo size="small" />
                            </a>
                        </div>

                        <div className="flex justify-between items-center lg:order-2">

                            <button type="button" ref={profileButtonRef} onClick={handleProfileClick} className="flex ml-3 bg-blue-200 rounded-full w-[32px] h-[32px]">
                                <span className="sr-only">Open user menu</span>
                                <div className="flex items-center justify-center uppercase rounded-full w-[32px] h-[32px]">
                                    <div className="font-extrabold text-lg text-gray-900 dark:text-gray-900">{fl}</div>
                                </div>
                            </button>

                            <div ref={profileMenuRef} className={`${isProfileOpen ? '' : 'hidden'} shadow-md absolute right-2 top-14 rounded bg-white border border-gray-400/80 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300`}>
                                <div className='relative w-full'>
                                    <div className='absolute bg-white border-l border-t border-gray-400 dark:bg-gray-800 dark:border-gray-700 rotate-45 w-[8px] h-[8px] right-[27px] top-[-5px]'></div>
                                </div>
                                {username !== '' ? (
                                    <div className='p-3 px-4'>
                                        <div className="font-bold text-sm">{first_name} {last_name}</div>
                                        <div className="truncate text-sm">{username}</div>
                                    </div>
                                ) : (
                                    <div className='animate-pulse p-3 px-4 space-y-2'>
                                        <div className="h-4 bg-slate-300 dark:bg-slate-700 rounded w-full"></div>
                                        <div className="h-4 bg-slate-300 dark:bg-slate-700 rounded w-full"></div>
                                    </div>
                                )}
                                {/* <div className='pt-2 text-sm space-y-2'>
                                    <div className='uppercase border-b border-gray-200 dark:border-gray-700 text-xs py-2 font-bold px-4 tracking-widest'>My Profile</div>
                                    <div className='gap-2 gap-x-4 grid grid-cols-2 px-4'>
                                        <div><Link to='/control/payment-methods' className='hover:underline hover:text-blue-600 dark:hover:text-orange-300'>Payment Methods</Link></div>
                                        <div><Link to='/control/subscriptions' className='hover:underline hover:text-blue-600 dark:hover:text-orange-300'>Subscriptions</Link></div>
                                        <div><button onClick={() => { navigate(`/control/billing-history`); setIsProfileOpen(false); }} className='hover:underline hover:text-blue-600 dark:hover:text-orange-300'>Billing History</button></div>
                                        <div><button onClick={() => { navigate(`/control/contact-information`); setIsProfileOpen(false); }} className='hover:underline hover:text-blue-600 dark:hover:text-orange-300'>Contact Information</button></div>
                                        <div><Link to='/control/delegate-access' className='hover:underline hover:text-blue-600 dark:hover:text-orange-300'>Delegate Access</Link></div>
                                        <div><Link to='/control/settings' className='hover:underline hover:text-blue-600 dark:hover:text-orange-300'>Settings</Link></div>
                                    </div>
                                    <div className='gap-2 gap-x-4 grid grid-cols-2 px-4'>
                                        <div><button onClick={() => { navigate(`/control/audit-logs`); setIsProfileOpen(false); }} className='hover:underline hover:text-blue-600 dark:hover:text-orange-300'>Audit Logs</button></div>
                                        <div><Link to='/control/support' className='hover:underline hover:text-blue-600 dark:hover:text-orange-300'>Support</Link></div>
                                    </div>
                                </div> */}
                                <div className='pt-5 text-sm space-y-2'>
                                    <div className='uppercase border-b border-gray-200 dark:border-gray-700 text-xs py-2 font-bold px-4 tracking-widest'>Account</div>
                                </div>
                                <div className='text-xs'>
                                    <a href='/admin/products/list'>
                                        <div className='flex items-center text-gray-700 dark:text-gray-300 space-x-2 hover:bg-gray-100 dark:hover:bg-gray-850 dark:hover:text-white p-2 px-4 cursor-pointer'>
                                            <div><HiViewGrid /></div>
                                            <div className='text-sm'>Dashboard</div>
                                        </div>
                                    </a>
                                </div>
                                <div className='text-xs'>
                                    <div className='flex items-center text-gray-700 dark:text-gray-300 space-x-2 hover:bg-gray-100 dark:hover:bg-gray-850 dark:hover:text-white p-2 px-4 cursor-pointer rounded-b' onClick={handleLogoutClick}>
                                        <div><HiLogout /></div>
                                        <div className='text-sm'>Sign out</div>
                                    </div>
                                </div>
                            </div>

                            {/* <Dropdown dismissOnClick={false} renderTrigger={() => 
                                <button type="button" className="flex ml-3 bg-blue-200 rounded-full focus:ring-4 focus:ring-gray-300 w-[32px] h-[32px]">
                                    <span className="sr-only">Open user menu</span>
                                    <div className="flex items-center justify-center uppercase rounded-full w-[32px] h-[32px] pt-[1px]">
                                        <div className="font-extrabold text-lg text-gray-900">{fl}</div>
                                    </div>
                                </button>
                                }>
                                <Dropdown.Header>
                                    <span className="block font-bold text-sm">{first_name} {last_name}</span>
                                    <span className="block truncate text-sm">{username}</span>
                                </Dropdown.Header>
                                <Link to="/admin/products/list"><Dropdown.Item icon={HiViewGrid}>Dashboard</Dropdown.Item></Link>
                                <Dropdown.Divider />
                                <Dropdown.Item icon={HiLogout} onClick={handleLogoutClick}>Sign out</Dropdown.Item>
                            </Dropdown> */}

                        </div>
                    </div>
                </nav>

            </header>
        </>
    );
}
 
export default Navbar;
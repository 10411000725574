export function validate_name(name) {
    const REGEX = /^[a-zA-Z\-]+( [a-zA-Z\-]+)*$/;
    return REGEX.test(name);
}

export function validate_first_name(name) {
    const REGEX = /^[a-zA-Z\-]+( [a-zA-Z\-]+)*$/;
    return REGEX.test(name);
}

export function validate_last_name(name) {
    const REGEX = /^[a-zA-Z\-]+( [a-zA-Z\-]+)*$/;
    return REGEX.test(name);
}

export function validate_phone_number(phoneNumber) {
    const REGEX = /^\+?[0-9]+(?:[ -][0-9]+)*$/;
    return REGEX.test(phoneNumber);
}

export function validate_email(email) {
    const REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return REGEX.test(email);
}

export function validate_generic_text(text) {
    const REGEX = /[a-zA-Z0-9\-]+/;
    return REGEX.test(text);
}

export function validate_password(password) {
    let found_error = false;
    if ( !password.match(/[a-z]/) ) found_error = true;
    if ( !password.match(/[A-Z]/) ) found_error = true;
    if ( !password.match(/[0-9]/) ) found_error = true;
    if ( !password.match(/[-!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/) ) found_error = true;
    if ( password.length < 8 || password.length > 24 ) found_error = true;
    return !found_error;
}

export function validate_message(message) {
    const REGEX = /[a-zA-Z].*[a-zA-Z].*[a-zA-Z].*[a-zA-Z].*[a-zA-Z]/;
    return REGEX.test(message);
}

export function validate_country(message) {
    const REGEX = /^[a-zA-Z]{2}$/;
    return REGEX.test(message);
}

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { MdKeyboardArrowRight } from "react-icons/md";
import generateRandomKey from './GenerateRandomKey';
import { frontendUrl, staticUrl } from '../../config';

function CollectionsItem({ collections, colors }) {
    const [isLoading, setIsLoading] = useState(false);
    const [generatedContent, setGeneratedContent] = useState([]);
    const [totalGeneratedItems, setTotalGeneratedItems] = useState(0);
    const bottomRef = useRef();
    const observer = useRef();

    useEffect(() => {
        if (collections.length > 0 && Object.keys(colors).length > 0 && totalGeneratedItems < collections.length) {
            generateContentFromJson();
        }
    }, [collections, colors]); // Re-run when collections or colors change

    useEffect(() => {
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && totalGeneratedItems < collections.length) {
                generateContentFromJson();
            }
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        });

        if (bottomRef.current) {
            observer.current.observe(bottomRef.current);
        }

        return () => {
            if (observer.current && bottomRef.current) {
                observer.current.unobserve(bottomRef.current);
            }
        };
    }, [totalGeneratedItems, collections.length]); // Only reinitialize observer if necessary

    const generateContentFromJson = () => {
        const startIndex = totalGeneratedItems;
        const endIndex = Math.min(startIndex + 3, collections.length);
        const newContent = collections.slice(startIndex, endIndex).map((item, index) => (
            <div key={generateRandomKey()}>
                <div className="block kb-menu:flex gap-x-8 justify-center items-center" key={generateRandomKey()}>
                    <div className="w-full kb-menu:w-3/5 pb-8 kb-menu:pb-0">
                        <a href={`${frontendUrl}/collections/${item.slug}`}>
                            <img src={`${staticUrl}/img/media/collections/collection_${item.image}.jpg`} className='w-full' alt="" />
                        </a>
                    </div>
                    <div className="text-kb-400 w-full kb-menu:w-2/5">
                        <div className='space-y-5 px-4 md:px-8 kb-menu:px-0'>
                            <div className='text-2xl md:text-4xl uppercase'>{item.name}</div>
                            <div className='text-base md:text-lg'>{item.description}</div>
                            {colors[item.slug] && (
                                <div className='flex space-x-2'>
                                    <div className="flex items-center space-x-3 py-2">
                                        <div className="h-[48px] -ml-3"></div>
                                        {Array.isArray(colors[item.slug]) && colors[item.slug].slice(0, 3).map((color, index) => (
                                            <div key={generateRandomKey()} className="w-[48px] h-[48px] bg-gray-200">
                                                <img src={`${staticUrl}/img/media/colors/thumbnail_${color}.jpg`} alt="" />
                                            </div>
                                        ))}
                                        {colors[item.slug].length > 3 && (
                                            <span className="text-kb-400/70">+ More</span>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div>
                                <a href={`${frontendUrl}/collections/${item.slug}`} className='hover:text-kb-300 text-xl md:text-2xl flex items-center'>
                                    Explore {item.name} <MdKeyboardArrowRight />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {(startIndex + index + 1) < collections.length ? (
                    <div className='py-16'>
                        <div className='border-b border-gray-400'></div>
                    </div>
                ) : (
                    <div className='py-16'></div>
                )}
            </div>
        ));

        setGeneratedContent(prevContent => [...prevContent, ...newContent]);
        setTotalGeneratedItems(prevTotal => prevTotal + newContent.length);
    };

    return (
        <div>
            <div className="text-kb-400 px-4 md:px-8 kb-menu:px-0 py-7">Showing {totalGeneratedItems} of {collections.length}</div>
            {generatedContent}
            <div ref={bottomRef} style={{ height: '1px' }}></div>
            {isLoading && <p className="text-kb-400">Loading...</p>}
        </div>
    );
}

export default CollectionsItem;

import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Session from '../../../../api/session';
import Title from '../../../components/Title';
import Navbar from '../../../../layout/logged_in/Navbar';
import Sidenav from '../../../../layout/logged_in/Sidenav';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';
import generateRandomKey from '../../../components/GenerateRandomKey';
import { backendUrl } from '../../../../config';

const LandingPageAdmin = () => {

    // Menus
    const [menu, setMenu] = useState([]);

    // Main Carousel
    const [mainCarouselImages, setMainCarouselImages] = useState([]);
    const [mainCarouselId1, setMainCarouselId1] = useState('');
    const [mainCarouselId2, setMainCarouselId2] = useState('');
    const [mainCarouselId3, setMainCarouselId3] = useState('');
    const [mainCarouselId4, setMainCarouselId4] = useState('');
    const [mainCarouselId5, setMainCarouselId5] = useState('');
    const [mainCarouselId6, setMainCarouselId6] = useState('');
    const [mainCarouselId7, setMainCarouselId7] = useState('');
    const [mainCarouselId8, setMainCarouselId8] = useState('');
    const [mainCarouselSlugId1, setMainCarouselSlugId1] = useState('');
    const [mainCarouselSlugId2, setMainCarouselSlugId2] = useState('');
    const [mainCarouselSlugId3, setMainCarouselSlugId3] = useState('');
    const [mainCarouselSlugId4, setMainCarouselSlugId4] = useState('');
    const [mainCarouselSlugId5, setMainCarouselSlugId5] = useState('');
    const [mainCarouselSlugId6, setMainCarouselSlugId6] = useState('');
    const [mainCarouselSlugId7, setMainCarouselSlugId7] = useState('');
    const [mainCarouselSlugId8, setMainCarouselSlugId8] = useState('');
    const mainCarouselId1Ref = useRef(null);
    const mainCarouselId2Ref = useRef(null);
    const mainCarouselId3Ref = useRef(null);
    const mainCarouselId4Ref = useRef(null);
    const mainCarouselId5Ref = useRef(null);
    const mainCarouselId6Ref = useRef(null);
    const mainCarouselId7Ref = useRef(null);
    const mainCarouselId8Ref = useRef(null);
    const mainCarouselSlugId1Ref = useRef(null);
    const mainCarouselSlugId2Ref = useRef(null);
    const mainCarouselSlugId3Ref = useRef(null);
    const mainCarouselSlugId4Ref = useRef(null);
    const mainCarouselSlugId5Ref = useRef(null);
    const mainCarouselSlugId6Ref = useRef(null);
    const mainCarouselSlugId7Ref = useRef(null);
    const mainCarouselSlugId8Ref = useRef(null);

    // Products Carousel
    const [productActive, setProductActive] = useState(true);
    const [productCarouselImages, setProductCarouselImages] = useState([]);
    const [productCarouselSlugs, setProductCarouselSlugs] = useState([]);
    const [productCarouselImageId1, setProductCarouselImageId1] = useState('');
    const [productCarouselImageId2, setProductCarouselImageId2] = useState('');
    const [productCarouselImageId3, setProductCarouselImageId3] = useState('');
    const [productCarouselImageId4, setProductCarouselImageId4] = useState('');
    const [productCarouselImageId5, setProductCarouselImageId5] = useState('');
    const [productCarouselImageId6, setProductCarouselImageId6] = useState('');
    const [productCarouselImageId7, setProductCarouselImageId7] = useState('');
    const [productCarouselImageId8, setProductCarouselImageId8] = useState('');
    const [productCarouselImageId9, setProductCarouselImageId9] = useState('');
    const [productCarouselImageId10, setProductCarouselImageId10] = useState('');
    const [productCarouselImageId11, setProductCarouselImageId11] = useState('');
    const [productCarouselImageId12, setProductCarouselImageId12] = useState('');
    const [productCarouselImageId13, setProductCarouselImageId13] = useState('');
    const [productCarouselImageId14, setProductCarouselImageId14] = useState('');
    const [productCarouselImageId15, setProductCarouselImageId15] = useState('');

    const [productCarouselTitleId1, setProductCarouselTitleId1] = useState('');
    const [productCarouselTitleId2, setProductCarouselTitleId2] = useState('');
    const [productCarouselTitleId3, setProductCarouselTitleId3] = useState('');
    const [productCarouselTitleId4, setProductCarouselTitleId4] = useState('');
    const [productCarouselTitleId5, setProductCarouselTitleId5] = useState('');
    const [productCarouselTitleId6, setProductCarouselTitleId6] = useState('');
    const [productCarouselTitleId7, setProductCarouselTitleId7] = useState('');
    const [productCarouselTitleId8, setProductCarouselTitleId8] = useState('');
    const [productCarouselTitleId9, setProductCarouselTitleId9] = useState('');
    const [productCarouselTitleId10, setProductCarouselTitleId10] = useState('');
    const [productCarouselTitleId11, setProductCarouselTitleId11] = useState('');
    const [productCarouselTitleId12, setProductCarouselTitleId12] = useState('');
    const [productCarouselTitleId13, setProductCarouselTitleId13] = useState('');
    const [productCarouselTitleId14, setProductCarouselTitleId14] = useState('');
    const [productCarouselTitleId15, setProductCarouselTitleId15] = useState('');

    const [productCarouselSlugId1, setProductCarouselSlugId1] = useState('');
    const [productCarouselSlugId2, setProductCarouselSlugId2] = useState('');
    const [productCarouselSlugId3, setProductCarouselSlugId3] = useState('');
    const [productCarouselSlugId4, setProductCarouselSlugId4] = useState('');
    const [productCarouselSlugId5, setProductCarouselSlugId5] = useState('');
    const [productCarouselSlugId6, setProductCarouselSlugId6] = useState('');
    const [productCarouselSlugId7, setProductCarouselSlugId7] = useState('');
    const [productCarouselSlugId8, setProductCarouselSlugId8] = useState('');
    const [productCarouselSlugId9, setProductCarouselSlugId9] = useState('');
    const [productCarouselSlugId10, setProductCarouselSlugId10] = useState('');
    const [productCarouselSlugId11, setProductCarouselSlugId11] = useState('');
    const [productCarouselSlugId12, setProductCarouselSlugId12] = useState('');
    const [productCarouselSlugId13, setProductCarouselSlugId13] = useState('');
    const [productCarouselSlugId14, setProductCarouselSlugId14] = useState('');
    const [productCarouselSlugId15, setProductCarouselSlugId15] = useState('');

    const productCarouselImageId1Ref = useRef(null);
    const productCarouselImageId2Ref = useRef(null);
    const productCarouselImageId3Ref = useRef(null);
    const productCarouselImageId4Ref = useRef(null);
    const productCarouselImageId5Ref = useRef(null);
    const productCarouselImageId6Ref = useRef(null);
    const productCarouselImageId7Ref = useRef(null);
    const productCarouselImageId8Ref = useRef(null);
    const productCarouselImageId9Ref = useRef(null);
    const productCarouselImageId10Ref = useRef(null);
    const productCarouselImageId11Ref = useRef(null);
    const productCarouselImageId12Ref = useRef(null);
    const productCarouselImageId13Ref = useRef(null);
    const productCarouselImageId14Ref = useRef(null);
    const productCarouselImageId15Ref = useRef(null);

    const productCarouselTitleId1Ref = useRef(null);
    const productCarouselTitleId2Ref = useRef(null);
    const productCarouselTitleId3Ref = useRef(null);
    const productCarouselTitleId4Ref = useRef(null);
    const productCarouselTitleId5Ref = useRef(null);
    const productCarouselTitleId6Ref = useRef(null);
    const productCarouselTitleId7Ref = useRef(null);
    const productCarouselTitleId8Ref = useRef(null);
    const productCarouselTitleId9Ref = useRef(null);
    const productCarouselTitleId10Ref = useRef(null);
    const productCarouselTitleId11Ref = useRef(null);
    const productCarouselTitleId12Ref = useRef(null);
    const productCarouselTitleId13Ref = useRef(null);
    const productCarouselTitleId14Ref = useRef(null);
    const productCarouselTitleId15Ref = useRef(null);
    
    const productCarouselSlugId1Ref = useRef(null);
    const productCarouselSlugId2Ref = useRef(null);
    const productCarouselSlugId3Ref = useRef(null);
    const productCarouselSlugId4Ref = useRef(null);
    const productCarouselSlugId5Ref = useRef(null);
    const productCarouselSlugId6Ref = useRef(null);
    const productCarouselSlugId7Ref = useRef(null);
    const productCarouselSlugId8Ref = useRef(null);
    const productCarouselSlugId9Ref = useRef(null);
    const productCarouselSlugId10Ref = useRef(null);
    const productCarouselSlugId11Ref = useRef(null);
    const productCarouselSlugId12Ref = useRef(null);
    const productCarouselSlugId13Ref = useRef(null);
    const productCarouselSlugId14Ref = useRef(null);
    const productCarouselSlugId15Ref = useRef(null);

    const productActiveRef = useRef(null);

    // Collections Carousel
    const [collectionActive, setCollectionActive] = useState(true);
    const [collectionCarouselImages, setCollectionCarouselImages] = useState([]);
    const [collectionCarouselSlugs, setCollectionCarouselSlugs] = useState([]);
    const [collectionCarouselImageId1, setCollectionCarouselImageId1] = useState('');
    const [collectionCarouselImageId2, setCollectionCarouselImageId2] = useState('');
    const [collectionCarouselImageId3, setCollectionCarouselImageId3] = useState('');
    const [collectionCarouselImageId4, setCollectionCarouselImageId4] = useState('');
    const [collectionCarouselImageId5, setCollectionCarouselImageId5] = useState('');
    const [collectionCarouselImageId6, setCollectionCarouselImageId6] = useState('');
    const [collectionCarouselImageId7, setCollectionCarouselImageId7] = useState('');
    const [collectionCarouselImageId8, setCollectionCarouselImageId8] = useState('');
    const [collectionCarouselImageId9, setCollectionCarouselImageId9] = useState('');
    const [collectionCarouselImageId10, setCollectionCarouselImageId10] = useState('');
    const [collectionCarouselImageId11, setCollectionCarouselImageId11] = useState('');
    const [collectionCarouselImageId12, setCollectionCarouselImageId12] = useState('');
    const [collectionCarouselImageId13, setCollectionCarouselImageId13] = useState('');
    const [collectionCarouselImageId14, setCollectionCarouselImageId14] = useState('');
    const [collectionCarouselImageId15, setCollectionCarouselImageId15] = useState('');

    const [collectionCarouselTitleId1, setCollectionCarouselTitleId1] = useState('');
    const [collectionCarouselTitleId2, setCollectionCarouselTitleId2] = useState('');
    const [collectionCarouselTitleId3, setCollectionCarouselTitleId3] = useState('');
    const [collectionCarouselTitleId4, setCollectionCarouselTitleId4] = useState('');
    const [collectionCarouselTitleId5, setCollectionCarouselTitleId5] = useState('');
    const [collectionCarouselTitleId6, setCollectionCarouselTitleId6] = useState('');
    const [collectionCarouselTitleId7, setCollectionCarouselTitleId7] = useState('');
    const [collectionCarouselTitleId8, setCollectionCarouselTitleId8] = useState('');
    const [collectionCarouselTitleId9, setCollectionCarouselTitleId9] = useState('');
    const [collectionCarouselTitleId10, setCollectionCarouselTitleId10] = useState('');
    const [collectionCarouselTitleId11, setCollectionCarouselTitleId11] = useState('');
    const [collectionCarouselTitleId12, setCollectionCarouselTitleId12] = useState('');
    const [collectionCarouselTitleId13, setCollectionCarouselTitleId13] = useState('');
    const [collectionCarouselTitleId14, setCollectionCarouselTitleId14] = useState('');
    const [collectionCarouselTitleId15, setCollectionCarouselTitleId15] = useState('');

    const [collectionCarouselSlugId1, setCollectionCarouselSlugId1] = useState('');
    const [collectionCarouselSlugId2, setCollectionCarouselSlugId2] = useState('');
    const [collectionCarouselSlugId3, setCollectionCarouselSlugId3] = useState('');
    const [collectionCarouselSlugId4, setCollectionCarouselSlugId4] = useState('');
    const [collectionCarouselSlugId5, setCollectionCarouselSlugId5] = useState('');
    const [collectionCarouselSlugId6, setCollectionCarouselSlugId6] = useState('');
    const [collectionCarouselSlugId7, setCollectionCarouselSlugId7] = useState('');
    const [collectionCarouselSlugId8, setCollectionCarouselSlugId8] = useState('');
    const [collectionCarouselSlugId9, setCollectionCarouselSlugId9] = useState('');
    const [collectionCarouselSlugId10, setCollectionCarouselSlugId10] = useState('');
    const [collectionCarouselSlugId11, setCollectionCarouselSlugId11] = useState('');
    const [collectionCarouselSlugId12, setCollectionCarouselSlugId12] = useState('');
    const [collectionCarouselSlugId13, setCollectionCarouselSlugId13] = useState('');
    const [collectionCarouselSlugId14, setCollectionCarouselSlugId14] = useState('');
    const [collectionCarouselSlugId15, setCollectionCarouselSlugId15] = useState('');

    const collectionCarouselImageId1Ref = useRef(null);
    const collectionCarouselImageId2Ref = useRef(null);
    const collectionCarouselImageId3Ref = useRef(null);
    const collectionCarouselImageId4Ref = useRef(null);
    const collectionCarouselImageId5Ref = useRef(null);
    const collectionCarouselImageId6Ref = useRef(null);
    const collectionCarouselImageId7Ref = useRef(null);
    const collectionCarouselImageId8Ref = useRef(null);
    const collectionCarouselImageId9Ref = useRef(null);
    const collectionCarouselImageId10Ref = useRef(null);
    const collectionCarouselImageId11Ref = useRef(null);
    const collectionCarouselImageId12Ref = useRef(null);
    const collectionCarouselImageId13Ref = useRef(null);
    const collectionCarouselImageId14Ref = useRef(null);
    const collectionCarouselImageId15Ref = useRef(null);

    const collectionCarouselTitleId1Ref = useRef(null);
    const collectionCarouselTitleId2Ref = useRef(null);
    const collectionCarouselTitleId3Ref = useRef(null);
    const collectionCarouselTitleId4Ref = useRef(null);
    const collectionCarouselTitleId5Ref = useRef(null);
    const collectionCarouselTitleId6Ref = useRef(null);
    const collectionCarouselTitleId7Ref = useRef(null);
    const collectionCarouselTitleId8Ref = useRef(null);
    const collectionCarouselTitleId9Ref = useRef(null);
    const collectionCarouselTitleId10Ref = useRef(null);
    const collectionCarouselTitleId11Ref = useRef(null);
    const collectionCarouselTitleId12Ref = useRef(null);
    const collectionCarouselTitleId13Ref = useRef(null);
    const collectionCarouselTitleId14Ref = useRef(null);
    const collectionCarouselTitleId15Ref = useRef(null);

    const collectionCarouselSlugId1Ref = useRef(null);
    const collectionCarouselSlugId2Ref = useRef(null);
    const collectionCarouselSlugId3Ref = useRef(null);
    const collectionCarouselSlugId4Ref = useRef(null);
    const collectionCarouselSlugId5Ref = useRef(null);
    const collectionCarouselSlugId6Ref = useRef(null);
    const collectionCarouselSlugId7Ref = useRef(null);
    const collectionCarouselSlugId8Ref = useRef(null);
    const collectionCarouselSlugId9Ref = useRef(null);
    const collectionCarouselSlugId10Ref = useRef(null);
    const collectionCarouselSlugId11Ref = useRef(null);
    const collectionCarouselSlugId12Ref = useRef(null);
    const collectionCarouselSlugId13Ref = useRef(null);
    const collectionCarouselSlugId14Ref = useRef(null);
    const collectionCarouselSlugId15Ref = useRef(null);

    const collectionActiveRef = useRef(null);

    // Sections
    const [sectionSquareImages, setSectionSquareImages] = useState([]);
    const [sectionRectangleImages, setSectionRectangleImages] = useState([]);
    const [section1ImageLeft, setSection1ImageLeft] = useState('');
    const [section1ImageRight, setSection1ImageRight] = useState('');
    const [section1Slug, setSection1Slug] = useState('');
    const [section1Title, setSection1Title] = useState('');
    const [section1Line1, setSection1Line1] = useState('');
    const [section1Line2, setSection1Line2] = useState('');
    const [section1Line3, setSection1Line3] = useState('');
    const [section1Enabled, setSection1Enabled] = useState(true);
    const section1ImageLeftRef = useRef(null);
    const section1ImageRightRef = useRef(null);
    const section1SlugRef = useRef(null);
    const section1TitleRef = useRef(null);
    const section1Line1Ref = useRef(null);
    const section1Line2Ref = useRef(null);
    const section1Line3Ref = useRef(null);
    const section1EnabledRef = useRef(null);

    const [section2Image1, setSection2Image1] = useState('');
    const [section2Image2, setSection2Image2] = useState('');
    const [section2Image3, setSection2Image3] = useState('');
    const [section2Slug1, setSection2Slug1] = useState('');
    const [section2Slug2, setSection2Slug2] = useState('');
    const [section2Slug3, setSection2Slug3] = useState('');
    const [section2Text1, setSection2Text1] = useState('');
    const [section2Text2, setSection2Text2] = useState('');
    const [section2Text3, setSection2Text3] = useState('');
    const [section2Title, setSection2Title] = useState('');
    const [section2Line1, setSection2Line1] = useState('');
    const [section2Line2, setSection2Line2] = useState('');
    const [section2Line3, setSection2Line3] = useState('');
    const [section2Enabled, setSection2Enabled] = useState(true);
    const section2Image1Ref = useRef(null);
    const section2Image2Ref = useRef(null);
    const section2Image3Ref = useRef(null);
    const section2Slug1Ref = useRef(null);
    const section2Slug2Ref = useRef(null);
    const section2Slug3Ref = useRef(null);
    const section2Text1Ref = useRef(null);
    const section2Text2Ref = useRef(null);
    const section2Text3Ref = useRef(null);
    const section2TitleRef = useRef(null);
    const section2Line1Ref = useRef(null);
    const section2Line2Ref = useRef(null);
    const section2Line3Ref = useRef(null);
    const section2EnabledRef = useRef(null);

    const [section3ImageLeft, setSection3ImageLeft] = useState('');
    const [section3ImageRight, setSection3ImageRight] = useState('');
    const [section3SlugLeft, setSection3SlugLeft] = useState('');
    const [section3SlugRight, setSection3SlugRight] = useState('');
    const [section3TitleLeft, setSection3TitleLeft] = useState('');
    const [section3TitleRight, setSection3TitleRight] = useState('');
    const [section3DescriptionLeft, setSection3DescriptionLeft] = useState('');
    const [section3DescriptionRight, setSection3DescriptionRight] = useState('');
    const [section3Enabled, setSection3Enabled] = useState(false);
    const section3ImageLeftRef = useRef(null);
    const section3ImageRightRef = useRef(null);
    const section3SlugLeftRef = useRef(null);
    const section3SlugRightRef = useRef(null);
    const section3TitleLeftRef = useRef(null);
    const section3TitleRightRef = useRef(null);
    const section3DescriptionLeftRef = useRef(null);
    const section3DescriptionRightRef = useRef(null);
    const section3EnabledRef = useRef(null);


    // Loading
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {

        const fetchProductSections = async () => {
            try {

                const response = await axios.get(`${backendUrl}/a/landing-page`, { withCredentials: true });

                setMenu(response?.data?.menus);

                setMainCarouselImages(response?.data?.main_carousel_images);
                const _mainCarouselSettings = response?.data?.main_carousel_settings;
                setMainCarouselId1( ( _mainCarouselSettings?.main_carousel_1 ) ? _mainCarouselSettings?.main_carousel_1 : '' );
                setMainCarouselId2( ( _mainCarouselSettings?.main_carousel_2 ) ? _mainCarouselSettings?.main_carousel_2 : '' );
                setMainCarouselId3( ( _mainCarouselSettings?.main_carousel_3 ) ? _mainCarouselSettings?.main_carousel_3 : '' );
                setMainCarouselId4( ( _mainCarouselSettings?.main_carousel_4 ) ? _mainCarouselSettings?.main_carousel_4 : '' );
                setMainCarouselId5( ( _mainCarouselSettings?.main_carousel_5 ) ? _mainCarouselSettings?.main_carousel_5 : '' );
                setMainCarouselId6( ( _mainCarouselSettings?.main_carousel_6 ) ? _mainCarouselSettings?.main_carousel_6 : '' );
                setMainCarouselId7( ( _mainCarouselSettings?.main_carousel_7 ) ? _mainCarouselSettings?.main_carousel_7 : '' );
                setMainCarouselId8( ( _mainCarouselSettings?.main_carousel_8 ) ? _mainCarouselSettings?.main_carousel_8 : '' );
                setMainCarouselSlugId1( ( _mainCarouselSettings?.main_slug_1 ) ? _mainCarouselSettings?.main_slug_1 : '' );
                setMainCarouselSlugId2( ( _mainCarouselSettings?.main_slug_2 ) ? _mainCarouselSettings?.main_slug_2 : '' );
                setMainCarouselSlugId3( ( _mainCarouselSettings?.main_slug_3 ) ? _mainCarouselSettings?.main_slug_3 : '' );
                setMainCarouselSlugId4( ( _mainCarouselSettings?.main_slug_4 ) ? _mainCarouselSettings?.main_slug_4 : '' );
                setMainCarouselSlugId5( ( _mainCarouselSettings?.main_slug_5 ) ? _mainCarouselSettings?.main_slug_5 : '' );
                setMainCarouselSlugId6( ( _mainCarouselSettings?.main_slug_6 ) ? _mainCarouselSettings?.main_slug_6 : '' );
                setMainCarouselSlugId7( ( _mainCarouselSettings?.main_slug_7 ) ? _mainCarouselSettings?.main_slug_7 : '' );
                setMainCarouselSlugId8( ( _mainCarouselSettings?.main_slug_8 ) ? _mainCarouselSettings?.main_slug_8 : '' );

                setProductCarouselImages(response?.data?.product_carousel_images);
                setProductCarouselSlugs(response?.data?.product_carousel_slugs);
                const _product_carousel_settings = response?.data?.product_carousel_settings;
                console.log(_product_carousel_settings)
                setProductCarouselImageId1( ( _product_carousel_settings?.product_carousel_1 ) ? _product_carousel_settings?.product_carousel_1 : '' );
                setProductCarouselImageId2( ( _product_carousel_settings?.product_carousel_2 ) ? _product_carousel_settings?.product_carousel_2 : '' );
                setProductCarouselImageId3( ( _product_carousel_settings?.product_carousel_3 ) ? _product_carousel_settings?.product_carousel_3 : '' );
                setProductCarouselImageId4( ( _product_carousel_settings?.product_carousel_4 ) ? _product_carousel_settings?.product_carousel_4 : '' );
                setProductCarouselImageId5( ( _product_carousel_settings?.product_carousel_5 ) ? _product_carousel_settings?.product_carousel_5 : '' );
                setProductCarouselImageId6( ( _product_carousel_settings?.product_carousel_6 ) ? _product_carousel_settings?.product_carousel_6 : '' );
                setProductCarouselImageId7( ( _product_carousel_settings?.product_carousel_7 ) ? _product_carousel_settings?.product_carousel_7 : '' );
                setProductCarouselImageId8( ( _product_carousel_settings?.product_carousel_8 ) ? _product_carousel_settings?.product_carousel_8 : '' );
                setProductCarouselImageId9( ( _product_carousel_settings?.product_carousel_9 ) ? _product_carousel_settings?.product_carousel_9 : '' );
                setProductCarouselImageId10( ( _product_carousel_settings?.product_carousel_10 ) ? _product_carousel_settings?.product_carousel_10 : '' );
                setProductCarouselImageId11( ( _product_carousel_settings?.product_carousel_11 ) ? _product_carousel_settings?.product_carousel_11 : '' );
                setProductCarouselImageId12( ( _product_carousel_settings?.product_carousel_12 ) ? _product_carousel_settings?.product_carousel_12 : '' );
                setProductCarouselImageId13( ( _product_carousel_settings?.product_carousel_13 ) ? _product_carousel_settings?.product_carousel_13 : '' );
                setProductCarouselImageId14( ( _product_carousel_settings?.product_carousel_14 ) ? _product_carousel_settings?.product_carousel_14 : '' );
                setProductCarouselImageId15( ( _product_carousel_settings?.product_carousel_15 ) ? _product_carousel_settings?.product_carousel_15 : '' );

                setProductCarouselTitleId1( ( _product_carousel_settings?.product_title_1 ) ? _product_carousel_settings?.product_title_1 : '' );
                setProductCarouselTitleId2( ( _product_carousel_settings?.product_title_2 ) ? _product_carousel_settings?.product_title_2 : '' );
                setProductCarouselTitleId3( ( _product_carousel_settings?.product_title_3 ) ? _product_carousel_settings?.product_title_3 : '' );
                setProductCarouselTitleId4( ( _product_carousel_settings?.product_title_4 ) ? _product_carousel_settings?.product_title_4 : '' );
                setProductCarouselTitleId5( ( _product_carousel_settings?.product_title_5 ) ? _product_carousel_settings?.product_title_5 : '' );
                setProductCarouselTitleId6( ( _product_carousel_settings?.product_title_6 ) ? _product_carousel_settings?.product_title_6 : '' );
                setProductCarouselTitleId7( ( _product_carousel_settings?.product_title_7 ) ? _product_carousel_settings?.product_title_7 : '' );
                setProductCarouselTitleId8( ( _product_carousel_settings?.product_title_8 ) ? _product_carousel_settings?.product_title_8 : '' );
                setProductCarouselTitleId9( ( _product_carousel_settings?.product_title_9 ) ? _product_carousel_settings?.product_title_9 : '' );
                setProductCarouselTitleId10( ( _product_carousel_settings?.product_title_10 ) ? _product_carousel_settings?.product_title_10 : '' );
                setProductCarouselTitleId11( ( _product_carousel_settings?.product_title_11 ) ? _product_carousel_settings?.product_title_11 : '' );
                setProductCarouselTitleId12( ( _product_carousel_settings?.product_title_12 ) ? _product_carousel_settings?.product_title_12 : '' );
                setProductCarouselTitleId13( ( _product_carousel_settings?.product_title_13 ) ? _product_carousel_settings?.product_title_13 : '' );
                setProductCarouselTitleId14( ( _product_carousel_settings?.product_title_14 ) ? _product_carousel_settings?.product_title_14 : '' );
                setProductCarouselTitleId15( ( _product_carousel_settings?.product_title_15 ) ? _product_carousel_settings?.product_title_15 : '' );

                setProductCarouselSlugId1( ( _product_carousel_settings?.product_slug_1 ) ? _product_carousel_settings?.product_slug_1 : '' );
                setProductCarouselSlugId2( ( _product_carousel_settings?.product_slug_2 ) ? _product_carousel_settings?.product_slug_2 : '' );
                setProductCarouselSlugId3( ( _product_carousel_settings?.product_slug_3 ) ? _product_carousel_settings?.product_slug_3 : '' );
                setProductCarouselSlugId4( ( _product_carousel_settings?.product_slug_4 ) ? _product_carousel_settings?.product_slug_4 : '' );
                setProductCarouselSlugId5( ( _product_carousel_settings?.product_slug_5 ) ? _product_carousel_settings?.product_slug_5 : '' );
                setProductCarouselSlugId6( ( _product_carousel_settings?.product_slug_6 ) ? _product_carousel_settings?.product_slug_6 : '' );
                setProductCarouselSlugId7( ( _product_carousel_settings?.product_slug_7 ) ? _product_carousel_settings?.product_slug_7 : '' );
                setProductCarouselSlugId8( ( _product_carousel_settings?.product_slug_8 ) ? _product_carousel_settings?.product_slug_8 : '' );
                setProductCarouselSlugId9( ( _product_carousel_settings?.product_slug_9 ) ? _product_carousel_settings?.product_slug_9 : '' );
                setProductCarouselSlugId10( ( _product_carousel_settings?.product_slug_10 ) ? _product_carousel_settings?.product_slug_10 : '' );
                setProductCarouselSlugId11( ( _product_carousel_settings?.product_slug_11 ) ? _product_carousel_settings?.product_slug_11 : '' );
                setProductCarouselSlugId12( ( _product_carousel_settings?.product_slug_12 ) ? _product_carousel_settings?.product_slug_12 : '' );
                setProductCarouselSlugId13( ( _product_carousel_settings?.product_slug_13 ) ? _product_carousel_settings?.product_slug_13 : '' );
                setProductCarouselSlugId14( ( _product_carousel_settings?.product_slug_14 ) ? _product_carousel_settings?.product_slug_14 : '' );
                setProductCarouselSlugId15( ( _product_carousel_settings?.product_slug_15 ) ? _product_carousel_settings?.product_slug_15 : '' );
                setProductActive((_product_carousel_settings?.product_enabled) ? true : false);

                setCollectionCarouselImages(response?.data?.collection_carousel_images);
                setCollectionCarouselSlugs(response?.data?.collection_carousel_slugs);
                const _collection_carousel_settings = response?.data?.collection_carousel_settings;
                setCollectionCarouselImageId1( ( _collection_carousel_settings?.collection_carousel_1 ) ? _collection_carousel_settings?.collection_carousel_1 : '' );
                setCollectionCarouselImageId2( ( _collection_carousel_settings?.collection_carousel_2 ) ? _collection_carousel_settings?.collection_carousel_2 : '' );
                setCollectionCarouselImageId3( ( _collection_carousel_settings?.collection_carousel_3 ) ? _collection_carousel_settings?.collection_carousel_3 : '' );
                setCollectionCarouselImageId4( ( _collection_carousel_settings?.collection_carousel_4 ) ? _collection_carousel_settings?.collection_carousel_4 : '' );
                setCollectionCarouselImageId5( ( _collection_carousel_settings?.collection_carousel_5 ) ? _collection_carousel_settings?.collection_carousel_5 : '' );
                setCollectionCarouselImageId6( ( _collection_carousel_settings?.collection_carousel_6 ) ? _collection_carousel_settings?.collection_carousel_6 : '' );
                setCollectionCarouselImageId7( ( _collection_carousel_settings?.collection_carousel_7 ) ? _collection_carousel_settings?.collection_carousel_7 : '' );
                setCollectionCarouselImageId8( ( _collection_carousel_settings?.collection_carousel_8 ) ? _collection_carousel_settings?.collection_carousel_8 : '' );
                setCollectionCarouselImageId9( ( _collection_carousel_settings?.collection_carousel_9 ) ? _collection_carousel_settings?.collection_carousel_9 : '' );
                setCollectionCarouselImageId10( ( _collection_carousel_settings?.collection_carousel_10 ) ? _collection_carousel_settings?.collection_carousel_10 : '' );
                setCollectionCarouselImageId11( ( _collection_carousel_settings?.collection_carousel_11 ) ? _collection_carousel_settings?.collection_carousel_11 : '' );
                setCollectionCarouselImageId12( ( _collection_carousel_settings?.collection_carousel_12 ) ? _collection_carousel_settings?.collection_carousel_12 : '' );
                setCollectionCarouselImageId13( ( _collection_carousel_settings?.collection_carousel_13 ) ? _collection_carousel_settings?.collection_carousel_13 : '' );
                setCollectionCarouselImageId14( ( _collection_carousel_settings?.collection_carousel_14 ) ? _collection_carousel_settings?.collection_carousel_14 : '' );
                setCollectionCarouselImageId15( ( _collection_carousel_settings?.collection_carousel_15 ) ? _collection_carousel_settings?.collection_carousel_15 : '' );

                setCollectionCarouselTitleId1( ( _collection_carousel_settings?.collection_title_1 ) ? _collection_carousel_settings?.collection_title_1 : '' );
                setCollectionCarouselTitleId2( ( _collection_carousel_settings?.collection_title_2 ) ? _collection_carousel_settings?.collection_title_2 : '' );
                setCollectionCarouselTitleId3( ( _collection_carousel_settings?.collection_title_3 ) ? _collection_carousel_settings?.collection_title_3 : '' );
                setCollectionCarouselTitleId4( ( _collection_carousel_settings?.collection_title_4 ) ? _collection_carousel_settings?.collection_title_4 : '' );
                setCollectionCarouselTitleId5( ( _collection_carousel_settings?.collection_title_5 ) ? _collection_carousel_settings?.collection_title_5 : '' );
                setCollectionCarouselTitleId6( ( _collection_carousel_settings?.collection_title_6 ) ? _collection_carousel_settings?.collection_title_6 : '' );
                setCollectionCarouselTitleId7( ( _collection_carousel_settings?.collection_title_7 ) ? _collection_carousel_settings?.collection_title_7 : '' );
                setCollectionCarouselTitleId8( ( _collection_carousel_settings?.collection_title_8 ) ? _collection_carousel_settings?.collection_title_8 : '' );
                setCollectionCarouselTitleId9( ( _collection_carousel_settings?.collection_title_9 ) ? _collection_carousel_settings?.collection_title_9 : '' );
                setCollectionCarouselTitleId10( ( _collection_carousel_settings?.collection_title_10 ) ? _collection_carousel_settings?.collection_title_10 : '' );
                setCollectionCarouselTitleId11( ( _collection_carousel_settings?.collection_title_11 ) ? _collection_carousel_settings?.collection_title_11 : '' );
                setCollectionCarouselTitleId12( ( _collection_carousel_settings?.collection_title_12 ) ? _collection_carousel_settings?.collection_title_12 : '' );
                setCollectionCarouselTitleId13( ( _collection_carousel_settings?.collection_title_13 ) ? _collection_carousel_settings?.collection_title_13 : '' );
                setCollectionCarouselTitleId14( ( _collection_carousel_settings?.collection_title_14 ) ? _collection_carousel_settings?.collection_title_14 : '' );
                setCollectionCarouselTitleId15( ( _collection_carousel_settings?.collection_title_15 ) ? _collection_carousel_settings?.collection_title_15 : '' );

                setCollectionCarouselSlugId1( ( _collection_carousel_settings?.collection_slug_1 ) ? _collection_carousel_settings?.collection_slug_1 : '' );
                setCollectionCarouselSlugId2( ( _collection_carousel_settings?.collection_slug_2 ) ? _collection_carousel_settings?.collection_slug_2 : '' );
                setCollectionCarouselSlugId3( ( _collection_carousel_settings?.collection_slug_3 ) ? _collection_carousel_settings?.collection_slug_3 : '' );
                setCollectionCarouselSlugId4( ( _collection_carousel_settings?.collection_slug_4 ) ? _collection_carousel_settings?.collection_slug_4 : '' );
                setCollectionCarouselSlugId5( ( _collection_carousel_settings?.collection_slug_5 ) ? _collection_carousel_settings?.collection_slug_5 : '' );
                setCollectionCarouselSlugId6( ( _collection_carousel_settings?.collection_slug_6 ) ? _collection_carousel_settings?.collection_slug_6 : '' );
                setCollectionCarouselSlugId7( ( _collection_carousel_settings?.collection_slug_7 ) ? _collection_carousel_settings?.collection_slug_7 : '' );
                setCollectionCarouselSlugId8( ( _collection_carousel_settings?.collection_slug_8 ) ? _collection_carousel_settings?.collection_slug_8 : '' );
                setCollectionCarouselSlugId9( ( _collection_carousel_settings?.collection_slug_9 ) ? _collection_carousel_settings?.collection_slug_9 : '' );
                setCollectionCarouselSlugId10( ( _collection_carousel_settings?.collection_slug_10 ) ? _collection_carousel_settings?.collection_slug_10 : '' );
                setCollectionCarouselSlugId11( ( _collection_carousel_settings?.collection_slug_11 ) ? _collection_carousel_settings?.collection_slug_11 : '' );
                setCollectionCarouselSlugId12( ( _collection_carousel_settings?.collection_slug_12 ) ? _collection_carousel_settings?.collection_slug_12 : '' );
                setCollectionCarouselSlugId13( ( _collection_carousel_settings?.collection_slug_13 ) ? _collection_carousel_settings?.collection_slug_13 : '' );
                setCollectionCarouselSlugId14( ( _collection_carousel_settings?.collection_slug_14 ) ? _collection_carousel_settings?.collection_slug_14 : '' );
                setCollectionCarouselSlugId15( ( _collection_carousel_settings?.collection_slug_15 ) ? _collection_carousel_settings?.collection_slug_15 : '' );
                setCollectionActive((_collection_carousel_settings?.collection_enabled) ? true : false);

                const _sections_settings = response?.data?.sections;
                setSectionRectangleImages( ( response?.data?.section_rectangle_images ) ? response?.data?.section_rectangle_images : [] );
                setSectionSquareImages( ( response?.data?.section_square_images ) ? response?.data?.section_square_images : [] );
                setSection1ImageLeft( ( _sections_settings?.section_1_image_1 ) ? _sections_settings?.section_1_image_1 : '' );
                setSection1ImageRight( ( _sections_settings?.section_1_image_2 ) ? _sections_settings?.section_1_image_2 : '' );
                setSection1Slug( ( _sections_settings?.section_1_slug ) ? _sections_settings?.section_1_slug : '' );
                setSection1Title( ( _sections_settings?.section_1_title ) ? _sections_settings?.section_1_title : '' );
                setSection1Line1( ( _sections_settings?.section_1_line_1 ) ? _sections_settings?.section_1_line_1 : '' );
                setSection1Line2( ( _sections_settings?.section_1_line_2 ) ? _sections_settings?.section_1_line_2 : '' );
                setSection1Line3( ( _sections_settings?.section_1_line_3 ) ? _sections_settings?.section_1_line_3 : '' );
                setSection1Enabled( ( _sections_settings?.section_1_enabled ) ? true : false);

                setSection2Image1( ( _sections_settings?.section_2_image_1 ) ? _sections_settings?.section_2_image_1 : '' );
                setSection2Image2( ( _sections_settings?.section_2_image_2 ) ? _sections_settings?.section_2_image_2 : '' );
                setSection2Image3( ( _sections_settings?.section_2_image_3 ) ? _sections_settings?.section_2_image_3 : '' );
                setSection2Slug1( ( _sections_settings?.section_2_slug_1 ) ? _sections_settings?.section_2_slug_1 : '' );
                setSection2Slug2( ( _sections_settings?.section_2_slug_2 ) ? _sections_settings?.section_2_slug_2 : '' );
                setSection2Slug3( ( _sections_settings?.section_2_slug_3 ) ? _sections_settings?.section_2_slug_3 : '' );
                setSection2Text1( ( _sections_settings?.section_2_text_1 ) ? _sections_settings?.section_2_text_1 : '' );
                setSection2Text2( ( _sections_settings?.section_2_text_2 ) ? _sections_settings?.section_2_text_2 : '' );
                setSection2Text3( ( _sections_settings?.section_2_text_3 ) ? _sections_settings?.section_2_text_3 : '' );
                setSection2Title( ( _sections_settings?.section_2_title ) ? _sections_settings?.section_2_title : '' );
                setSection2Line1( ( _sections_settings?.section_2_line_1 ) ? _sections_settings?.section_2_line_1 : '' );
                setSection2Line2( ( _sections_settings?.section_2_line_2 ) ? _sections_settings?.section_2_line_2 : '' );
                setSection2Line3( ( _sections_settings?.section_2_line_3 ) ? _sections_settings?.section_2_line_3 : '' );
                setSection2Enabled( ( _sections_settings?.section_2_enabled ) ? true : false);

                setSection3ImageLeft( ( _sections_settings?.section_3_image_1 ) ? _sections_settings?.section_3_image_1 : '' );
                setSection3ImageRight( ( _sections_settings?.section_3_image_2 ) ? _sections_settings?.section_3_image_2 : '' );
                setSection3SlugLeft( ( _sections_settings?.section_3_slug_1 ) ? _sections_settings?.section_3_slug_1 : '' );
                setSection3SlugRight( ( _sections_settings?.section_3_slug_2 ) ? _sections_settings?.section_3_slug_2 : '' );
                setSection3TitleLeft( ( _sections_settings?.section_3_title_1 ) ? _sections_settings?.section_3_title_1 : '' );
                setSection3TitleRight( ( _sections_settings?.section_3_title_2 ) ? _sections_settings?.section_3_title_2 : '' );
                setSection3DescriptionLeft( ( _sections_settings?.section_3_description_1 ) ? _sections_settings?.section_3_description_1 : '' );
                setSection3DescriptionRight( ( _sections_settings?.section_3_description_2 ) ? _sections_settings?.section_3_description_2 : '' );
                setSection3Enabled( ( _sections_settings?.section_3_enabled ) ? true : false);


                setIsLoading(false);

            } catch (error) {
                console.log(error);
                setIsLoading(false);

            }
        };
    
        fetchProductSections();

    }, []);

    // Modal
    const [isModalOpen, setIsModalOpen] = useState(false);



    // Refs - Generic Information Page
    const isNewRef = useRef(null);
    const isActiveRef = useRef(null);


    const openModal = (type, value, index, listIndex, listValue) => {

        setIsModalOpen(true);

    };

    const closeModal = () => {
        setIsModalOpen(false);
    };


    const syncBackend = () => {

            const _data = {
                main_carousel: {
                    main_carousel_1: (mainCarouselId1Ref?.current?.value) ? mainCarouselId1Ref?.current?.value : null,
                    main_carousel_2: (mainCarouselId2Ref?.current?.value) ? mainCarouselId2Ref?.current?.value : null,
                    main_carousel_3: (mainCarouselId3Ref?.current?.value) ? mainCarouselId3Ref?.current?.value : null,
                    main_carousel_4: (mainCarouselId4Ref?.current?.value) ? mainCarouselId4Ref?.current?.value : null,
                    main_carousel_5: (mainCarouselId5Ref?.current?.value) ? mainCarouselId5Ref?.current?.value : null,
                    main_carousel_6: (mainCarouselId6Ref?.current?.value) ? mainCarouselId6Ref?.current?.value : null,
                    main_carousel_7: (mainCarouselId7Ref?.current?.value) ? mainCarouselId7Ref?.current?.value : null,
                    main_carousel_8: (mainCarouselId8Ref?.current?.value) ? mainCarouselId8Ref?.current?.value : null,
                    main_slug_1: (mainCarouselSlugId1Ref?.current?.value) ? mainCarouselSlugId1Ref?.current?.value : null,
                    main_slug_2: (mainCarouselSlugId2Ref?.current?.value) ? mainCarouselSlugId2Ref?.current?.value : null,
                    main_slug_3: (mainCarouselSlugId3Ref?.current?.value) ? mainCarouselSlugId3Ref?.current?.value : null,
                    main_slug_4: (mainCarouselSlugId4Ref?.current?.value) ? mainCarouselSlugId4Ref?.current?.value : null,
                    main_slug_5: (mainCarouselSlugId5Ref?.current?.value) ? mainCarouselSlugId5Ref?.current?.value : null,
                    main_slug_6: (mainCarouselSlugId6Ref?.current?.value) ? mainCarouselSlugId6Ref?.current?.value : null,
                    main_slug_7: (mainCarouselSlugId7Ref?.current?.value) ? mainCarouselSlugId7Ref?.current?.value : null,
                    main_slug_8: (mainCarouselSlugId8Ref?.current?.value) ? mainCarouselSlugId8Ref?.current?.value : null,
                    main_carousel_mobile: null
                },
                product_carousel: {
                    product_enabled: (productActiveRef?.current?.checked) ? 1 : 0,
                    product_carousel_1: (productCarouselImageId1Ref?.current?.value) ? productCarouselImageId1Ref?.current?.value : null,
                    product_carousel_2: (productCarouselImageId2Ref?.current?.value) ? productCarouselImageId2Ref?.current?.value : null,
                    product_carousel_3: (productCarouselImageId3Ref?.current?.value) ? productCarouselImageId3Ref?.current?.value : null,
                    product_carousel_4: (productCarouselImageId4Ref?.current?.value) ? productCarouselImageId4Ref?.current?.value : null,
                    product_carousel_5: (productCarouselImageId5Ref?.current?.value) ? productCarouselImageId5Ref?.current?.value : null,
                    product_carousel_6: (productCarouselImageId6Ref?.current?.value) ? productCarouselImageId6Ref?.current?.value : null,
                    product_carousel_7: (productCarouselImageId7Ref?.current?.value) ? productCarouselImageId7Ref?.current?.value : null,
                    product_carousel_8: (productCarouselImageId8Ref?.current?.value) ? productCarouselImageId8Ref?.current?.value : null,
                    product_carousel_9: (productCarouselImageId9Ref?.current?.value) ? productCarouselImageId9Ref?.current?.value : null,
                    product_carousel_10: (productCarouselImageId10Ref?.current?.value) ? productCarouselImageId10Ref?.current?.value : null,
                    product_carousel_11: (productCarouselImageId11Ref?.current?.value) ? productCarouselImageId11Ref?.current?.value : null,
                    product_carousel_12: (productCarouselImageId12Ref?.current?.value) ? productCarouselImageId12Ref?.current?.value : null,
                    product_carousel_13: (productCarouselImageId13Ref?.current?.value) ? productCarouselImageId13Ref?.current?.value : null,
                    product_carousel_14: (productCarouselImageId14Ref?.current?.value) ? productCarouselImageId14Ref?.current?.value : null,
                    product_carousel_15: (productCarouselImageId15Ref?.current?.value) ? productCarouselImageId15Ref?.current?.value : null,
                    product_title_1: (productCarouselTitleId1Ref?.current?.value) ? productCarouselTitleId1Ref?.current?.value : null,
                    product_title_2: (productCarouselTitleId2Ref?.current?.value) ? productCarouselTitleId2Ref?.current?.value : null,
                    product_title_3: (productCarouselTitleId3Ref?.current?.value) ? productCarouselTitleId3Ref?.current?.value : null,
                    product_title_4: (productCarouselTitleId4Ref?.current?.value) ? productCarouselTitleId4Ref?.current?.value : null,
                    product_title_5: (productCarouselTitleId5Ref?.current?.value) ? productCarouselTitleId5Ref?.current?.value : null,
                    product_title_6: (productCarouselTitleId6Ref?.current?.value) ? productCarouselTitleId6Ref?.current?.value : null,
                    product_title_7: (productCarouselTitleId7Ref?.current?.value) ? productCarouselTitleId7Ref?.current?.value : null,
                    product_title_8: (productCarouselTitleId8Ref?.current?.value) ? productCarouselTitleId8Ref?.current?.value : null,
                    product_title_9: (productCarouselTitleId9Ref?.current?.value) ? productCarouselTitleId9Ref?.current?.value : null,
                    product_title_10: (productCarouselTitleId10Ref?.current?.value) ? productCarouselTitleId10Ref?.current?.value : null,
                    product_title_11: (productCarouselTitleId11Ref?.current?.value) ? productCarouselTitleId11Ref?.current?.value : null,
                    product_title_12: (productCarouselTitleId12Ref?.current?.value) ? productCarouselTitleId12Ref?.current?.value : null,
                    product_title_13: (productCarouselTitleId13Ref?.current?.value) ? productCarouselTitleId13Ref?.current?.value : null,
                    product_title_14: (productCarouselTitleId14Ref?.current?.value) ? productCarouselTitleId14Ref?.current?.value : null,
                    product_title_15: (productCarouselTitleId15Ref?.current?.value) ? productCarouselTitleId15Ref?.current?.value : null,
                    product_slug_1: (productCarouselSlugId1Ref?.current?.value) ? productCarouselSlugId1Ref?.current?.value : null,
                    product_slug_2: (productCarouselSlugId2Ref?.current?.value) ? productCarouselSlugId2Ref?.current?.value : null,
                    product_slug_3: (productCarouselSlugId3Ref?.current?.value) ? productCarouselSlugId3Ref?.current?.value : null,
                    product_slug_4: (productCarouselSlugId4Ref?.current?.value) ? productCarouselSlugId4Ref?.current?.value : null,
                    product_slug_5: (productCarouselSlugId5Ref?.current?.value) ? productCarouselSlugId5Ref?.current?.value : null,
                    product_slug_6: (productCarouselSlugId6Ref?.current?.value) ? productCarouselSlugId6Ref?.current?.value : null,
                    product_slug_7: (productCarouselSlugId7Ref?.current?.value) ? productCarouselSlugId7Ref?.current?.value : null,
                    product_slug_8: (productCarouselSlugId8Ref?.current?.value) ? productCarouselSlugId8Ref?.current?.value : null,
                    product_slug_9: (productCarouselSlugId9Ref?.current?.value) ? productCarouselSlugId9Ref?.current?.value : null,
                    product_slug_10: (productCarouselSlugId10Ref?.current?.value) ? productCarouselSlugId10Ref?.current?.value : null,
                    product_slug_11: (productCarouselSlugId11Ref?.current?.value) ? productCarouselSlugId11Ref?.current?.value : null,
                    product_slug_12: (productCarouselSlugId12Ref?.current?.value) ? productCarouselSlugId12Ref?.current?.value : null,
                    product_slug_13: (productCarouselSlugId13Ref?.current?.value) ? productCarouselSlugId13Ref?.current?.value : null,
                    product_slug_14: (productCarouselSlugId14Ref?.current?.value) ? productCarouselSlugId14Ref?.current?.value : null,
                    product_slug_15: (productCarouselSlugId15Ref?.current?.value) ? productCarouselSlugId15Ref?.current?.value : null
                },
                collection_carousel: {
                    collection_enabled: (collectionActiveRef?.current?.checked) ? 1 : 0,
                    collection_carousel_1: (collectionCarouselImageId1Ref?.current?.value) ? collectionCarouselImageId1Ref?.current?.value : null,
                    collection_carousel_2: (collectionCarouselImageId2Ref?.current?.value) ? collectionCarouselImageId2Ref?.current?.value : null,
                    collection_carousel_3: (collectionCarouselImageId3Ref?.current?.value) ? collectionCarouselImageId3Ref?.current?.value : null,
                    collection_carousel_4: (collectionCarouselImageId4Ref?.current?.value) ? collectionCarouselImageId4Ref?.current?.value : null,
                    collection_carousel_5: (collectionCarouselImageId5Ref?.current?.value) ? collectionCarouselImageId5Ref?.current?.value : null,
                    collection_carousel_6: (collectionCarouselImageId6Ref?.current?.value) ? collectionCarouselImageId6Ref?.current?.value : null,
                    collection_carousel_7: (collectionCarouselImageId7Ref?.current?.value) ? collectionCarouselImageId7Ref?.current?.value : null,
                    collection_carousel_8: (collectionCarouselImageId8Ref?.current?.value) ? collectionCarouselImageId8Ref?.current?.value : null,
                    collection_carousel_9: (collectionCarouselImageId9Ref?.current?.value) ? collectionCarouselImageId9Ref?.current?.value : null,
                    collection_carousel_10: (collectionCarouselImageId10Ref?.current?.value) ? collectionCarouselImageId10Ref?.current?.value : null,
                    collection_carousel_11: (collectionCarouselImageId11Ref?.current?.value) ? collectionCarouselImageId11Ref?.current?.value : null,
                    collection_carousel_12: (collectionCarouselImageId12Ref?.current?.value) ? collectionCarouselImageId12Ref?.current?.value : null,
                    collection_carousel_13: (collectionCarouselImageId13Ref?.current?.value) ? collectionCarouselImageId13Ref?.current?.value : null,
                    collection_carousel_14: (collectionCarouselImageId14Ref?.current?.value) ? collectionCarouselImageId14Ref?.current?.value : null,
                    collection_carousel_15: (collectionCarouselImageId15Ref?.current?.value) ? collectionCarouselImageId15Ref?.current?.value : null,
                    collection_title_1: (collectionCarouselTitleId1Ref?.current?.value) ? collectionCarouselTitleId1Ref?.current?.value : null,
                    collection_title_2: (collectionCarouselTitleId2Ref?.current?.value) ? collectionCarouselTitleId2Ref?.current?.value : null,
                    collection_title_3: (collectionCarouselTitleId3Ref?.current?.value) ? collectionCarouselTitleId3Ref?.current?.value : null,
                    collection_title_4: (collectionCarouselTitleId4Ref?.current?.value) ? collectionCarouselTitleId4Ref?.current?.value : null,
                    collection_title_5: (collectionCarouselTitleId5Ref?.current?.value) ? collectionCarouselTitleId5Ref?.current?.value : null,
                    collection_title_6: (collectionCarouselTitleId6Ref?.current?.value) ? collectionCarouselTitleId6Ref?.current?.value : null,
                    collection_title_7: (collectionCarouselTitleId7Ref?.current?.value) ? collectionCarouselTitleId7Ref?.current?.value : null,
                    collection_title_8: (collectionCarouselTitleId8Ref?.current?.value) ? collectionCarouselTitleId8Ref?.current?.value : null,
                    collection_title_9: (collectionCarouselTitleId9Ref?.current?.value) ? collectionCarouselTitleId9Ref?.current?.value : null,
                    collection_title_10: (collectionCarouselTitleId10Ref?.current?.value) ? collectionCarouselTitleId10Ref?.current?.value : null,
                    collection_title_11: (collectionCarouselTitleId11Ref?.current?.value) ? collectionCarouselTitleId11Ref?.current?.value : null,
                    collection_title_12: (collectionCarouselTitleId12Ref?.current?.value) ? collectionCarouselTitleId12Ref?.current?.value : null,
                    collection_title_13: (collectionCarouselTitleId13Ref?.current?.value) ? collectionCarouselTitleId13Ref?.current?.value : null,
                    collection_title_14: (collectionCarouselTitleId14Ref?.current?.value) ? collectionCarouselTitleId14Ref?.current?.value : null,
                    collection_title_15: (collectionCarouselTitleId15Ref?.current?.value) ? collectionCarouselTitleId15Ref?.current?.value : null,
                    collection_slug_1: (collectionCarouselSlugId1Ref?.current?.value) ? collectionCarouselSlugId1Ref?.current?.value : null,
                    collection_slug_2: (collectionCarouselSlugId2Ref?.current?.value) ? collectionCarouselSlugId2Ref?.current?.value : null,
                    collection_slug_3: (collectionCarouselSlugId3Ref?.current?.value) ? collectionCarouselSlugId3Ref?.current?.value : null,
                    collection_slug_4: (collectionCarouselSlugId4Ref?.current?.value) ? collectionCarouselSlugId4Ref?.current?.value : null,
                    collection_slug_5: (collectionCarouselSlugId5Ref?.current?.value) ? collectionCarouselSlugId5Ref?.current?.value : null,
                    collection_slug_6: (collectionCarouselSlugId6Ref?.current?.value) ? collectionCarouselSlugId6Ref?.current?.value : null,
                    collection_slug_7: (collectionCarouselSlugId7Ref?.current?.value) ? collectionCarouselSlugId7Ref?.current?.value : null,
                    collection_slug_8: (collectionCarouselSlugId8Ref?.current?.value) ? collectionCarouselSlugId8Ref?.current?.value : null,
                    collection_slug_9: (collectionCarouselSlugId9Ref?.current?.value) ? collectionCarouselSlugId9Ref?.current?.value : null,
                    collection_slug_10: (collectionCarouselSlugId10Ref?.current?.value) ? collectionCarouselSlugId10Ref?.current?.value : null,
                    collection_slug_11: (collectionCarouselSlugId11Ref?.current?.value) ? collectionCarouselSlugId11Ref?.current?.value : null,
                    collection_slug_12: (collectionCarouselSlugId12Ref?.current?.value) ? collectionCarouselSlugId12Ref?.current?.value : null,
                    collection_slug_13: (collectionCarouselSlugId13Ref?.current?.value) ? collectionCarouselSlugId13Ref?.current?.value : null,
                    collection_slug_14: (collectionCarouselSlugId14Ref?.current?.value) ? collectionCarouselSlugId14Ref?.current?.value : null,
                    collection_slug_15: (collectionCarouselSlugId15Ref?.current?.value) ? collectionCarouselSlugId15Ref?.current?.value : null
                },
                sections: {
                    section_1_enabled: (section1EnabledRef?.current?.checked) ? 1 : 0,
                    section_2_enabled: (section2EnabledRef?.current?.checked) ? 1 : 0,
                    section_3_enabled: (section3EnabledRef?.current?.checked) ? 1 : 0,
                    section_1_image_1: (section1ImageLeftRef?.current?.value) ? section1ImageLeftRef.current?.value : null,
                    section_1_image_2: (section1ImageRightRef?.current?.value) ? section1ImageRightRef.current?.value : null,
                    section_1_slug: (section1SlugRef?.current?.value) ? section1SlugRef.current?.value : null,
                    section_1_title: (section1TitleRef?.current?.value) ? section1TitleRef.current?.value : null,
                    section_1_line_1: (section1Line1Ref?.current?.value) ? section1Line1Ref.current?.value : null,
                    section_1_line_2: (section1Line2Ref?.current?.value) ? section1Line2Ref.current?.value : null,
                    section_1_line_3: (section1Line3Ref?.current?.value) ? section1Line3Ref.current?.value : null,
                    section_2_image_1: (section2Image1Ref?.current?.value) ? section2Image1Ref?.current?.value : null,
                    section_2_image_2: (section2Image2Ref?.current?.value) ? section2Image2Ref?.current?.value : null,
                    section_2_image_3: (section2Image3Ref?.current?.value) ? section2Image3Ref?.current?.value : null,
                    section_2_text_1: (section2Text1Ref?.current?.value) ? section2Text1Ref?.current?.value : null,
                    section_2_text_2: (section2Text2Ref?.current?.value) ? section2Text2Ref?.current?.value : null,
                    section_2_text_3: (section2Text3Ref?.current?.value) ? section2Text3Ref?.current?.value : null,
                    section_2_slug_1: (section2Slug1Ref?.current?.value) ? section2Slug1Ref?.current?.value : null,
                    section_2_slug_2: (section2Slug2Ref?.current?.value) ? section2Slug2Ref?.current?.value : null,
                    section_2_slug_3: (section2Slug3Ref?.current?.value) ? section2Slug3Ref?.current?.value : null,
                    section_2_title: (section2TitleRef?.current?.value) ? section2TitleRef?.current?.value : null,
                    section_2_line_1: (section2Line1Ref?.current?.value) ? section2Line1Ref?.current?.value : null,
                    section_2_line_2: (section2Line2Ref?.current?.value) ? section2Line2Ref?.current?.value : null,
                    section_2_line_3: (section2Line3Ref?.current?.value) ? section2Line3Ref?.current?.value : null,
                    section_3_image_1: (section3ImageLeftRef?.current?.value) ? section3ImageLeftRef?.current?.value : null,
                    section_3_image_2: (section3ImageRightRef?.current?.value) ? section3ImageRightRef?.current?.value : null,
                    section_3_slug_1: (section3SlugLeftRef?.current?.value) ? section3SlugLeftRef?.current?.value : null,
                    section_3_slug_2: (section3SlugRightRef?.current?.value) ? section3SlugRightRef?.current?.value : null,
                    section_3_title_1: (section3TitleLeftRef?.current?.value) ? section3TitleLeftRef?.current?.value : null,
                    section_3_description_1: (section3DescriptionLeftRef?.current?.value) ? section3DescriptionLeftRef?.current?.value : null,
                    section_3_title_2: (section3TitleRightRef?.current?.value) ? section3TitleRightRef?.current?.value : null,
                    section_3_description_2: (section3DescriptionRightRef?.current?.value) ? section3DescriptionRightRef?.current?.value : null,
                }
            };

            const updateProductSections = async () => {
                try {
                    await axios.post(`${backendUrl}/a/landing-page`, _data, { withCredentials: true });
                    
                    setIsLoading(false);
                    window.showToast('WCL20415');
                } catch (error) {
                    console.log(error);
                    setIsLoading(false);
                    window.showToast('WCL20424');
                }
            };
        
            updateProductSections();   

    };



    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <Session />
            <Title set="Landing Page Admin" />
            <div className="App">
                
                <div className="antialiased">
                    <Navbar />
                    <Sidenav />
                    <main className="p-4 lg:pl-[270px] h-screen max-w-screen-2xl pt-20 z-0 mx-auto">
                        <div className='flex'>
                            <div className='hidden lg:block lg:fixed top-[95px] w-[175px] p-4 space-y-3 border border-dashed border-gray-200 bg-gray-50'>
                                <div className='font-bold'>PAGES</div>
                                <div><a href={`/admin/pages/landing-page`} className='hover:underline'>Landing Page</a></div>
                                <div className='pt-5'><Button size='sm' text='UPDATE PAGE' onClick={() => { syncBackend(); }} /></div>
                            </div>
                            <div className='lg:ml-[195px] w-full'>
                                <div className='block lg:hidden p-4 border border-dashed border-gray-200 bg-gray-50 space-x-0'>
                                    <div className='font-bold'>PAGES</div>
                                    <div><a href={`/admin/pages/landing-page`} className='hover:underline'>Landing Page</a></div>
                                </div>
                                <div className='lg:flex justify-between items-center'>
                                    <div className=''>
                                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light py-7 uppercase'>PAGES</h2>
                                    </div>
                                    <div className='mb-10 lg:mb-0 justify-center lg:justify-start flex gap-x-3'>
                                        <Button size='sm' text='UPDATE PAGE' onClick={() => { syncBackend(); }} />
                                    </div>
                                </div>
                                <div className='text-xl bg-gray-200 p-4 flex justify-between items-center'>
                                    <div className='flex space-x-2'>
                                        <span className='font-bold'>Landing Page</span>
                                    </div>
                                </div>
                                <div className='lg:flex justify-between items-center mb-10 w-full bg-gray-50 border border-gray-200'>
                                    <div className='justify-center lg:justify-start lg:flex lg:space-x-3 space-y-3 lg:space-y-0 p-4 w-full'>


                                    </div>
                                </div>
                                <div className='space-y-3'>
                                    <div className='w-full'>

                                        <>
                                            {/* Main Carousel */}
                                            <div className='p-4 bg-gray-50 w-full space-y-5 border border-gray-200 mb-5'>
                                                <h3 className='font-bold'>Main Carousel</h3>

                                                <div className='grid grid-cols-1 md:grid-cols-2 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold whitespace-nowrap'>Image 1</div>
                                                        <div className='w-full'>
                                                            <select ref={mainCarouselId1Ref} value={mainCarouselId1} onChange={(e) => setMainCarouselId1(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Image</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {mainCarouselImages.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold whitespace-nowrap'>Slug 1</div>
                                                        <div className='w-full'>
                                                            <select ref={mainCarouselSlugId1Ref} value={mainCarouselSlugId1} onChange={(e) => setMainCarouselSlugId1(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Slug</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {collectionCarouselSlugs.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='grid grid-cols-1 md:grid-cols-2 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold whitespace-nowrap'>Image 2</div>
                                                        <div className='w-full'>
                                                            <select ref={mainCarouselId2Ref} value={mainCarouselId2} onChange={(e) => setMainCarouselId2(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Image</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {mainCarouselImages.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold whitespace-nowrap'>Slug 2</div>
                                                        <div className='w-full'>
                                                            <select ref={mainCarouselSlugId2Ref} value={mainCarouselSlugId2} onChange={(e) => setMainCarouselSlugId2(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Slug</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {collectionCarouselSlugs.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='grid grid-cols-1 md:grid-cols-2 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold whitespace-nowrap'>Image 3</div>
                                                        <div className='w-full'>
                                                            <select ref={mainCarouselId3Ref} value={mainCarouselId3} onChange={(e) => setMainCarouselId3(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Image</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {mainCarouselImages.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold whitespace-nowrap'>Slug 3</div>
                                                        <div className='w-full'>
                                                            <select ref={mainCarouselSlugId3Ref} value={mainCarouselSlugId3} onChange={(e) => setMainCarouselSlugId3(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Slug</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {collectionCarouselSlugs.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='grid grid-cols-1 md:grid-cols-2 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold whitespace-nowrap'>Image 4</div>
                                                        <div className='w-full'>
                                                            <select ref={mainCarouselId4Ref} value={mainCarouselId4} onChange={(e) => setMainCarouselId4(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Image</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {mainCarouselImages.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold whitespace-nowrap'>Slug 4</div>
                                                        <div className='w-full'>
                                                            <select ref={mainCarouselSlugId4Ref} value={mainCarouselSlugId4} onChange={(e) => setMainCarouselSlugId4(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Slug</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {collectionCarouselSlugs.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='grid grid-cols-1 md:grid-cols-2 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold whitespace-nowrap'>Image 5</div>
                                                        <div className='w-full'>
                                                            <select ref={mainCarouselId5Ref} value={mainCarouselId5} onChange={(e) => setMainCarouselId5(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Image</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {mainCarouselImages.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold whitespace-nowrap'>Slug 5</div>
                                                        <div className='w-full'>
                                                            <select ref={mainCarouselSlugId5Ref} value={mainCarouselSlugId5} onChange={(e) => setMainCarouselSlugId5(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Slug</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {collectionCarouselSlugs.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='grid grid-cols-1 md:grid-cols-2 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold whitespace-nowrap'>Image 6</div>
                                                        <div className='w-full'>
                                                            <select ref={mainCarouselId6Ref} value={mainCarouselId6} onChange={(e) => setMainCarouselId6(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Image</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {mainCarouselImages.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold whitespace-nowrap'>Slug 6</div>
                                                        <div className='w-full'>
                                                            <select ref={mainCarouselSlugId6Ref} value={mainCarouselSlugId6} onChange={(e) => setMainCarouselSlugId6(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Slug</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {collectionCarouselSlugs.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='grid grid-cols-1 md:grid-cols-2 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold whitespace-nowrap'>Image 7</div>
                                                        <div className='w-full'>
                                                            <select ref={mainCarouselId7Ref} value={mainCarouselId7} onChange={(e) => setMainCarouselId7(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Image</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {mainCarouselImages.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold whitespace-nowrap'>Slug 7</div>
                                                        <div className='w-full'>
                                                            <select ref={mainCarouselSlugId7Ref} value={mainCarouselSlugId7} onChange={(e) => setMainCarouselSlugId7(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Slug</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {collectionCarouselSlugs.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='grid grid-cols-1 md:grid-cols-2 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold whitespace-nowrap'>Image 8</div>
                                                        <div className='w-full'>
                                                            <select ref={mainCarouselId8Ref} value={mainCarouselId8} onChange={(e) => setMainCarouselId8(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Image</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {mainCarouselImages.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold whitespace-nowrap'>Slug 8</div>
                                                        <div className='w-full'>
                                                            <select ref={mainCarouselSlugId8Ref} value={mainCarouselSlugId8} onChange={(e) => setMainCarouselSlugId8(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Slug</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {collectionCarouselSlugs.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            {/* Products Carousel */}
                                            <div className='p-4 bg-gray-50 w-full space-y-5 border border-gray-200 mb-5'>
                                                <div className='flex justify-between'>
                                                    <h3 className='font-bold'>Product Carousel</h3>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold'>Active</div>
                                                        <div>
                                                            <input ref={productActiveRef} type="checkbox" defaultChecked={productActive} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='space-y-5'>

                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 1</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselImageId1Ref} value={productCarouselImageId1} onChange={(e) => setProductCarouselImageId1(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {productCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 1</div>
                                                            <div className='w-full'>
                                                                <input ref={productCarouselTitleId1Ref} defaultValue={productCarouselTitleId1} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 1</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselSlugId1Ref} value={productCarouselSlugId1} onChange={(e) => setProductCarouselSlugId1(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {menu.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 2</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselImageId2Ref} value={productCarouselImageId2} onChange={(e) => setProductCarouselImageId2(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {productCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 2</div>
                                                            <div className='w-full'>
                                                                <input ref={productCarouselTitleId2Ref} defaultValue={productCarouselTitleId2} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 2</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselSlugId2Ref} value={productCarouselSlugId2} onChange={(e) => setProductCarouselSlugId2(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {menu.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 3</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselImageId3Ref} value={productCarouselImageId3} onChange={(e) => setProductCarouselImageId3(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {productCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 3</div>
                                                            <div className='w-full'>
                                                                <input ref={productCarouselTitleId3Ref} defaultValue={productCarouselTitleId3} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 3</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselSlugId3Ref} value={productCarouselSlugId3} onChange={(e) => setProductCarouselSlugId3(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {menu.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 4</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselImageId4Ref} value={productCarouselImageId4} onChange={(e) => setProductCarouselImageId4(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {productCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 4</div>
                                                            <div className='w-full'>
                                                                <input ref={productCarouselTitleId4Ref} defaultValue={productCarouselTitleId4} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 4</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselSlugId4Ref} value={productCarouselSlugId4} onChange={(e) => setProductCarouselSlugId4(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {menu.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 5</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselImageId5Ref} value={productCarouselImageId5} onChange={(e) => setProductCarouselImageId5(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {productCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 5</div>
                                                            <div className='w-full'>
                                                                <input ref={productCarouselTitleId5Ref} defaultValue={productCarouselTitleId5} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 5</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselSlugId5Ref} value={productCarouselSlugId5} onChange={(e) => setProductCarouselSlugId5(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {menu.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 6</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselImageId6Ref} value={productCarouselImageId6} onChange={(e) => setProductCarouselImageId6(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {productCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 6</div>
                                                            <div className='w-full'>
                                                                <input ref={productCarouselTitleId6Ref} defaultValue={productCarouselTitleId6} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 6</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselSlugId6Ref} value={productCarouselSlugId6} onChange={(e) => setProductCarouselSlugId6(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {menu.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 7</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselImageId7Ref} value={productCarouselImageId7} onChange={(e) => setProductCarouselImageId7(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {productCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 7</div>
                                                            <div className='w-full'>
                                                                <input ref={productCarouselTitleId7Ref} defaultValue={productCarouselTitleId7} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 7</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselSlugId7Ref} value={productCarouselSlugId7} onChange={(e) => setProductCarouselSlugId7(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {menu.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 8</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselImageId8Ref} value={productCarouselImageId8} onChange={(e) => setProductCarouselImageId8(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {productCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 8</div>
                                                            <div className='w-full'>
                                                                <input ref={productCarouselTitleId8Ref} defaultValue={productCarouselTitleId8} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 8</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselSlugId8Ref} value={productCarouselSlugId8} onChange={(e) => setProductCarouselSlugId8(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {menu.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 9</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselImageId9Ref} value={productCarouselImageId9} onChange={(e) => setProductCarouselImageId9(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {productCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 9</div>
                                                            <div className='w-full'>
                                                                <input ref={productCarouselTitleId9Ref} defaultValue={productCarouselTitleId9} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 9</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselSlugId9Ref} value={productCarouselSlugId9} onChange={(e) => setProductCarouselSlugId9(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {menu.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 10</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselImageId10Ref} value={productCarouselImageId10} onChange={(e) => setProductCarouselImageId10(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {productCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 10</div>
                                                            <div className='w-full'>
                                                                <input ref={productCarouselTitleId10Ref} defaultValue={productCarouselTitleId10} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 10</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselSlugId10Ref} value={productCarouselSlugId10} onChange={(e) => setProductCarouselSlugId10(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {menu.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 11</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselImageId11Ref} value={productCarouselImageId11} onChange={(e) => setProductCarouselImageId11(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {productCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 11</div>
                                                            <div className='w-full'>
                                                                <input ref={productCarouselTitleId11Ref} defaultValue={productCarouselTitleId11} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 11</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselSlugId11Ref} value={productCarouselSlugId11} onChange={(e) => setProductCarouselSlugId11(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {menu.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 12</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselImageId12Ref} value={productCarouselImageId12} onChange={(e) => setProductCarouselImageId12(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {productCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 12</div>
                                                            <div className='w-full'>
                                                                <input ref={productCarouselTitleId12Ref} defaultValue={productCarouselTitleId12} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 12</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselSlugId12Ref} value={productCarouselSlugId12} onChange={(e) => setProductCarouselSlugId12(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {menu.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 13</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselImageId13Ref} value={productCarouselImageId13} onChange={(e) => setProductCarouselImageId13(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {productCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 13</div>
                                                            <div className='w-full'>
                                                                <input ref={productCarouselTitleId13Ref} defaultValue={productCarouselTitleId13} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 13</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselSlugId13Ref} value={productCarouselSlugId13} onChange={(e) => setProductCarouselSlugId13(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {menu.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 14</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselImageId14Ref} value={productCarouselImageId14} onChange={(e) => setProductCarouselImageId14(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {productCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 14</div>
                                                            <div className='w-full'>
                                                                <input ref={productCarouselTitleId14Ref} defaultValue={productCarouselTitleId14} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 14</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselSlugId14Ref} value={productCarouselSlugId14} onChange={(e) => setProductCarouselSlugId14(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {menu.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 15</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselImageId15Ref} value={productCarouselImageId15} onChange={(e) => setProductCarouselImageId15(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {productCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 15</div>
                                                            <div className='w-full'>
                                                                <input ref={productCarouselTitleId15Ref} defaultValue={productCarouselTitleId15} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 15</div>
                                                            <div className='w-full'>
                                                                <select ref={productCarouselSlugId15Ref} value={productCarouselSlugId15} onChange={(e) => setProductCarouselSlugId15(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {menu.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            {/* Collections Carousel */}
                                            <div className='p-4 bg-gray-50 w-full space-y-5 border border-gray-200 mb-5'>
                                                <div className='flex justify-between'>
                                                    <h3 className='font-bold'>Collection Carousel</h3>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold'>Active</div>
                                                        <div>
                                                            <input ref={collectionActiveRef} type="checkbox" defaultChecked={collectionActive} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='space-y-5'>

                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 1</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselImageId1Ref} value={collectionCarouselImageId1} onChange={(e) => setCollectionCarouselImageId1(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 1</div>
                                                            <div className='w-full'>
                                                                <input ref={collectionCarouselTitleId1Ref} defaultValue={collectionCarouselTitleId1} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 1</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselSlugId1Ref} value={collectionCarouselSlugId1} onChange={(e) => setCollectionCarouselSlugId1(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselSlugs.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 2</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselImageId2Ref} value={collectionCarouselImageId2} onChange={(e) => setCollectionCarouselImageId2(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 2</div>
                                                            <div className='w-full'>
                                                                <input ref={collectionCarouselTitleId2Ref} defaultValue={collectionCarouselTitleId2} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 2</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselSlugId2Ref} value={collectionCarouselSlugId2} onChange={(e) => setCollectionCarouselSlugId2(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselSlugs.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 3</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselImageId3Ref} value={collectionCarouselImageId3} onChange={(e) => setCollectionCarouselImageId3(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 3</div>
                                                            <div className='w-full'>
                                                                <input ref={collectionCarouselTitleId3Ref} defaultValue={collectionCarouselTitleId3} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 3</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselSlugId3Ref} value={collectionCarouselSlugId3} onChange={(e) => setCollectionCarouselSlugId3(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselSlugs.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 4</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselImageId4Ref} value={collectionCarouselImageId4} onChange={(e) => setCollectionCarouselImageId4(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 4</div>
                                                            <div className='w-full'>
                                                                <input ref={collectionCarouselTitleId4Ref} defaultValue={collectionCarouselTitleId4} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 4</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselSlugId4Ref} value={collectionCarouselSlugId4} onChange={(e) => setCollectionCarouselSlugId4(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselSlugs.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 5</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselImageId5Ref} value={collectionCarouselImageId5} onChange={(e) => setCollectionCarouselImageId5(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 5</div>
                                                            <div className='w-full'>
                                                                <input ref={collectionCarouselTitleId5Ref} defaultValue={collectionCarouselTitleId5} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 5</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselSlugId5Ref} value={collectionCarouselSlugId5} onChange={(e) => setCollectionCarouselSlugId5(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselSlugs.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 6</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselImageId6Ref} value={collectionCarouselImageId6} onChange={(e) => setCollectionCarouselImageId6(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 6</div>
                                                            <div className='w-full'>
                                                                <input ref={collectionCarouselTitleId6Ref} defaultValue={collectionCarouselTitleId6} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 6</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselSlugId6Ref} value={collectionCarouselSlugId6} onChange={(e) => setCollectionCarouselSlugId6(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselSlugs.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 7</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselImageId7Ref} value={collectionCarouselImageId7} onChange={(e) => setCollectionCarouselImageId7(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 7</div>
                                                            <div className='w-full'>
                                                                <input ref={collectionCarouselTitleId7Ref} defaultValue={collectionCarouselTitleId7} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 7</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselSlugId7Ref} value={collectionCarouselSlugId7} onChange={(e) => setCollectionCarouselSlugId7(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselSlugs.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 8</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselImageId8Ref} value={collectionCarouselImageId8} onChange={(e) => setCollectionCarouselImageId8(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 8</div>
                                                            <div className='w-full'>
                                                                <input ref={collectionCarouselTitleId8Ref} defaultValue={collectionCarouselTitleId8} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 8</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselSlugId8Ref} value={collectionCarouselSlugId8} onChange={(e) => setCollectionCarouselSlugId8(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselSlugs.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 9</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselImageId9Ref} value={collectionCarouselImageId9} onChange={(e) => setCollectionCarouselImageId9(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 9</div>
                                                            <div className='w-full'>
                                                                <input ref={collectionCarouselTitleId9Ref} defaultValue={collectionCarouselTitleId9} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 9</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselSlugId9Ref} value={collectionCarouselSlugId9} onChange={(e) => setCollectionCarouselSlugId9(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselSlugs.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 10</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselImageId10Ref} value={collectionCarouselImageId10} onChange={(e) => setCollectionCarouselImageId10(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 10</div>
                                                            <div className='w-full'>
                                                                <input ref={collectionCarouselTitleId10Ref} defaultValue={collectionCarouselTitleId10} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 10</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselSlugId10Ref} value={collectionCarouselSlugId10} onChange={(e) => setCollectionCarouselSlugId10(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselSlugs.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 11</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselImageId11Ref} value={collectionCarouselImageId11} onChange={(e) => setCollectionCarouselImageId11(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 11</div>
                                                            <div className='w-full'>
                                                                <input ref={collectionCarouselTitleId11Ref} defaultValue={collectionCarouselTitleId11} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 11</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselSlugId11Ref} value={collectionCarouselSlugId11} onChange={(e) => setCollectionCarouselSlugId11(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselSlugs.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 12</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselImageId12Ref} value={collectionCarouselImageId12} onChange={(e) => setCollectionCarouselImageId12(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 12</div>
                                                            <div className='w-full'>
                                                                <input ref={collectionCarouselTitleId12Ref} defaultValue={collectionCarouselTitleId12} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 12</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselSlugId12Ref} value={collectionCarouselSlugId12} onChange={(e) => setCollectionCarouselSlugId12(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselSlugs.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 13</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselImageId13Ref} value={collectionCarouselImageId13} onChange={(e) => setCollectionCarouselImageId13(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 13</div>
                                                            <div className='w-full'>
                                                                <input ref={collectionCarouselTitleId13Ref} defaultValue={collectionCarouselTitleId13} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 13</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselSlugId13Ref} value={collectionCarouselSlugId13} onChange={(e) => setCollectionCarouselSlugId13(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselSlugs.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 14</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselImageId14Ref} value={collectionCarouselImageId14} onChange={(e) => setCollectionCarouselImageId14(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 14</div>
                                                            <div className='w-full'>
                                                                <input ref={collectionCarouselTitleId14Ref} defaultValue={collectionCarouselTitleId14} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 14</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselSlugId14Ref} value={collectionCarouselSlugId14} onChange={(e) => setCollectionCarouselSlugId14(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselSlugs.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    
                                                    <div className='grid grid-cols-1 md:grid-cols-3 md:gap-5 space-y-5 lg:space-y-0 p-4 border border-gray-200'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Image 15</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselImageId15Ref} value={collectionCarouselImageId15} onChange={(e) => setCollectionCarouselImageId15(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Image</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselImages.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Title 15</div>
                                                            <div className='w-full'>
                                                                <input ref={collectionCarouselTitleId15Ref} defaultValue={collectionCarouselTitleId15} type="text" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Slug 15</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionCarouselSlugId15Ref} value={collectionCarouselSlugId15} onChange={(e) => setCollectionCarouselSlugId15(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Slug</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collectionCarouselSlugs.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            {/* Section 1 collections */}
                                            <div className='p-4 bg-gray-50 w-full space-y-5 border border-gray-200 mb-5'>
                                                <div className='flex justify-between'>
                                                    <h3 className='font-bold'>Section 1</h3>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold'>Active</div>
                                                        <div>
                                                            <input ref={section1EnabledRef} type="checkbox" defaultChecked={section1Enabled} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='grid grid-cols-2 gap-5 p-4 border border-gray-200'>
                                                    <div className='flex space-x-4 items-center'>
                                                        <div className='font-bold whitespace-nowrap'>Image Left</div>
                                                        <div className='w-full'>
                                                            <select ref={section1ImageLeftRef} value={section1ImageLeft} onChange={(e) => setSection1ImageLeft(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Image</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {sectionSquareImages.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='flex space-x-4 items-center'>
                                                        <div className='font-bold whitespace-nowrap'>Image Right</div>
                                                        <div className='w-full'>
                                                        <select ref={section1ImageRightRef} value={section1ImageRight} onChange={(e) => setSection1ImageRight(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Image</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {sectionRectangleImages.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='p-4 border border-gray-200'>
                                                    <div className='font-bold whitespace-nowrap'>Collection Slug</div>
                                                    <div className='w-full'>
                                                        <select ref={section1SlugRef} value={section1Slug} onChange={(e) => setSection1Slug(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                            <option value=''>No Slug</option>
                                                            <option disabled className='text-gray-300'>-------------------------------------</option>
                                                            {collectionCarouselSlugs.map((item, index) => (
                                                                <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className='p-4 border border-gray-200 space-y-4'>
                                                    <div className='w-full'>
                                                        <div className='font-bold'>Title</div>
                                                        <div><input ref={section1TitleRef} defaultValue={section1Title} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full' /></div>
                                                    </div>
                                                    <div className=''>
                                                        <div className='font-bold'>Line 1</div>
                                                        <div><input ref={section1Line1Ref} defaultValue={section1Line1} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full' /></div>
                                                    </div>
                                                    <div className=''>
                                                        <div className='font-bold'>Line 2</div>
                                                        <div><input ref={section1Line2Ref} defaultValue={section1Line2} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full' /></div>
                                                    </div>
                                                    <div className=''>
                                                        <div className='font-bold'>Line 3</div>
                                                        <div><input ref={section1Line3Ref} defaultValue={section1Line3} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full' /></div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Section 2 menus */}
                                            <div className='p-4 bg-gray-50 w-full space-y-5 border border-gray-200 mb-5'>
                                                <div className='flex justify-between'>
                                                    <h3 className='font-bold'>Section 2</h3>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold'>Active</div>
                                                        <div>
                                                            <input ref={section2EnabledRef} type="checkbox" defaultChecked={section2Enabled} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='grid grid-cols-3 gap-5 p-4 border border-gray-200'>
                                                    <div className='flex space-x-4 items-center'>
                                                        <div className='font-bold whitespace-nowrap'>Image 1</div>
                                                        <div className='w-full'>
                                                            <select ref={section2Image1Ref} value={section2Image1} onChange={(e) => setSection2Image1(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Image</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {sectionSquareImages.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='flex space-x-4 items-center'>
                                                        <div className='font-bold whitespace-nowrap'>Image 2</div>
                                                        <div className='w-full'>
                                                            <select ref={section2Image2Ref} value={section2Image2} onChange={(e) => setSection2Image2(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Image</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {sectionSquareImages.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='flex space-x-4 items-center'>
                                                        <div className='font-bold whitespace-nowrap'>Image 3</div>
                                                        <div className='w-full'>
                                                            <select ref={section2Image3Ref} value={section2Image3} onChange={(e) => setSection2Image3(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Image</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {sectionSquareImages.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='grid grid-cols-3 gap-5 p-4 border border-gray-200'>
                                                    <div className='flex space-x-4 items-center'>
                                                        <div className='font-bold whitespace-nowrap'>Slug 1</div>
                                                        <div className='w-full'>
                                                            <select ref={section2Slug1Ref} value={section2Slug1} onChange={(e) => setSection2Slug1(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Slug</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {menu.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='flex space-x-4 items-center'>
                                                        <div className='font-bold whitespace-nowrap'>Slug 2</div>
                                                        <div className='w-full'>
                                                            <select ref={section2Slug2Ref} value={section2Slug2} onChange={(e) => setSection2Slug2(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Slug</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {menu.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='flex space-x-4 items-center'>
                                                        <div className='font-bold whitespace-nowrap'>Slug 3</div>
                                                        <div className='w-full'>
                                                            <select ref={section2Slug3Ref} value={section2Slug3} onChange={(e) => setSection2Slug3(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Slug</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {menu.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='grid grid-cols-3 gap-5 p-4 border border-gray-200'>
                                                    <div className='flex space-x-4 items-center'>
                                                        <div className='font-bold whitespace-nowrap'>Text 1</div>
                                                        <div className='w-full'>
                                                            <input ref={section2Text1Ref} defaultValue={section2Text1} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full' />
                                                        </div>
                                                    </div>
                                                    <div className='flex space-x-4 items-center'>
                                                        <div className='font-bold whitespace-nowrap'>Text 2</div>
                                                        <div className='w-full'>
                                                            <input ref={section2Text2Ref} defaultValue={section2Text2} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full' />
                                                        </div>
                                                    </div>
                                                    <div className='flex space-x-4 items-center'>
                                                        <div className='font-bold whitespace-nowrap'>Text 3</div>
                                                        <div className='w-full'>
                                                            <input ref={section2Text3Ref} defaultValue={section2Text3} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full' />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='p-4 border border-gray-200 space-y-4'>
                                                    <div className='w-full'>
                                                        <div className='font-bold'>Title</div>
                                                        <div><input ref={section2TitleRef} defaultValue={section2Title} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full' /></div>
                                                    </div>
                                                    <div className=''>
                                                        <div className='font-bold'>Line 1</div>
                                                        <div><input ref={section2Line1Ref} defaultValue={section2Line1} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full' /></div>
                                                    </div>
                                                    <div className=''>
                                                        <div className='font-bold'>Line 2</div>
                                                        <div><input ref={section2Line2Ref} defaultValue={section2Line2} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full' /></div>
                                                    </div>
                                                    <div className=''>
                                                        <div className='font-bold'>Line 3</div>
                                                        <div><input ref={section2Line3Ref} defaultValue={section2Line3} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full' /></div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Section 3 collections */}
                                            <div className='p-4 bg-gray-50 w-full space-y-5 border border-gray-200 mb-5'>
                                                <div className='flex justify-between'>
                                                    <h3 className='font-bold'>Section 3</h3>
                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold'>Active</div>
                                                        <div>
                                                            <input ref={section3EnabledRef} type="checkbox" defaultChecked={section3Enabled} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='grid grid-cols-2 gap-5 p-4 border border-gray-200'>
                                                    <div className='flex space-x-4 items-center'>
                                                        <div className='font-bold whitespace-nowrap'>Image Left</div>
                                                        <div className='w-full'>
                                                            <select ref={section3ImageLeftRef} value={section3ImageLeft} onChange={(e) => setSection3ImageLeft(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Image</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {sectionSquareImages.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='flex space-x-4 items-center'>
                                                        <div className='font-bold whitespace-nowrap'>Image Right</div>
                                                        <div className='w-full'>
                                                            <select ref={section3ImageRightRef} value={section3ImageRight} onChange={(e) => setSection3ImageRight(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Image</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {sectionSquareImages.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='grid grid-cols-2 gap-5 p-4 border border-gray-200'>
                                                    <div className='flex space-x-4 items-center'>
                                                        <div className='font-bold whitespace-nowrap'>Collection Slug Left</div>
                                                        <div className='w-full'>
                                                            <select ref={section3SlugLeftRef} value={section3SlugLeft} onChange={(e) => setSection3SlugLeft(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Slug</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {collectionCarouselSlugs.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='flex space-x-4 items-center'>
                                                        <div className='font-bold whitespace-nowrap'>Collection Slug Right</div>
                                                        <div className='w-full'>
                                                            <select ref={section3SlugRightRef} value={section3SlugRight} onChange={(e) => setSection3SlugRight(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                <option value=''>No Slug</option>
                                                                <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                {collectionCarouselSlugs.map((item, index) => (
                                                                    <option key={generateRandomKey()} value={item.slug}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='space-y-4 p-4 border border-gray-200'>
                                                    <div className='w-full'>
                                                        <div className='font-bold'>Title Left</div>
                                                        <div><input ref={section3TitleLeftRef} defaultValue={section3TitleLeft} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full' /></div>
                                                    </div>
                                                    <div className='w-full'>
                                                        <div className='font-bold'>Description Left</div>
                                                        <div><input ref={section3DescriptionLeftRef} defaultValue={section3DescriptionLeft} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full' /></div>
                                                    </div>
                                                </div>

                                                <div className='space-y-4 p-4 border border-gray-200'>
                                                    <div className='w-full'>
                                                        <div className='font-bold'>Title Right</div>
                                                        <div><input ref={section3TitleRightRef} defaultValue={section3TitleRight} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full' /></div>
                                                    </div>
                                                    <div className='w-full'>
                                                        <div className='font-bold'>Description Right</div>
                                                        <div><input ref={section3DescriptionRightRef} defaultValue={section3DescriptionRight} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full' /></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>

            </div>

        </>
    );
}
 
export default LandingPageAdmin;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BsDashLg, BsPlusLg } from "react-icons/bs";
import VerticalCarousel from '../../../sections/sliders/VerticalCarousel';
import HorizontalCarousel from '../../../sections/sliders/HorizontalCarousel';
import MobileCarousel from '../../../sections/sliders/MobileCarousel';
import Layout from "../../../layout/logged_out/Layout";
import axios from 'axios';
import Modal from '../../components/Modal';
import generateRandomKey from '../../components/GenerateRandomKey';
import Loading from '../../components/Loading';
import NotFound from '../../../views/pages/error/404';
import { frontendUrl, backendUrl, staticUrl } from '../../../config';
import PrintTearSheet from './components/PrintTearSheetv2';
import { MdKeyboardArrowRight } from "react-icons/md";
import ProductsCarousel from '../../../sections/sliders/ProductsCarousel';
import { useMediaQuery } from 'react-responsive';
import TextParser from './components/TextParser';

const Product = () => {

    const isMobile = useMediaQuery({ maxWidth: 767 });
    const { '*': slugId } = useParams();
    const [slug, setSlug] = useState(slugId.split('/')[0]);
    const [isLoading, setIsLoading] = useState(true);

    const [productData, setProductData] = useState(null);
    const [mainImage, setMainImage] = useState(null);
    const [imageDescription, setImageDescription] = useState('');
    const [sections, setSections] = useState([]);
    
    const [colorId, setColorId] = useState('');
    const [colorName, setColorName] = useState('');
    const [colorCode, setColorCode] = useState('');
    const [colorMaterial, setColorMaterial] = useState('');
    const [colorContents, setColorContents] = useState('');
    const [colorPerformance, setColorPerformance] = useState('');
    const [colorSuitable, setColorSuitable] = useState('');
    const [colorNote, setColorNote] = useState('');
    const [colorInfo, setColorInfo] = useState('');
    const [fabricInfo, setFabricInfo] = useState('');
    const [colorCareId, setColorCareId] = useState(null);
    const [careSection, setCareSection] = useState(null);

    const [cushion1Data, setCushion1Data] = useState('');
    const [cushion2Data, setCushion2Data] = useState('');
    const [cushion3Data, setCushion3Data] = useState('');

    const [collectionImage, setCollectionImage] = useState('');
    const [collectionName, setCollectionName] = useState('');
    const [collectionDescription, setCollectionDescription] = useState('');
    const [collectionSlug, setCollectionSlug] = useState('');
    const [products, setProducts] = useState([]);
    const [colors, setColors] = useState([]);

    // Modal
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (data) => {
        setColorId(data.relation_id);
        setColorName(colorInfo[data.relation_id].name);
        setColorCode(colorInfo[data.relation_id].code);
        setColorMaterial(colorInfo[data.relation_id].material);
        setColorContents(colorInfo[data.relation_id].contents);
        setColorPerformance(colorInfo[data.relation_id].performance);
        setColorSuitable(colorInfo[data.relation_id].suitable);
        setColorNote(colorInfo[data.relation_id].note);
        setColorCareId(colorInfo[data.relation_id].care);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        try {

            setIsLoading(true);

            // Clear local storage on page load
            localStorage.removeItem('expanded_Protective Covers');
            localStorage.removeItem('expanded_Cushions');
            localStorage.removeItem('expanded_Related Items');
            localStorage.removeItem('expanded_Dimensions');
            localStorage.removeItem('expanded_Care');
            localStorage.removeItem('expanded_Tear Sheet');

            axios.get(`${backendUrl}/w/product-page/${slug}`)
            .then((response) => {

                setProductData(response.data.product);
                setColorInfo(response.data.product.color_information);
                setFabricInfo(response.data.product.fabric_information);

                setCollectionImage(response.data.collection.relation_id);
                setCollectionName(response.data.collection.name);
                setCollectionDescription(response.data.collection.description);
                setCollectionSlug(response.data.collection.slug);
                setProducts(response.data.products);
                setColors(response.data.colors);

                setCushion1Data((response.data.product.cushion_data && response.data.product.cushion_data[0]) ? <div>Cushion <span className='font-bold'>{response.data.product.cushion_data[0].name}</span></div> : null);
                setCushion2Data((response.data.product.cushion_data && response.data.product.cushion_data[1]) ? <div>Cushion <span className='font-bold'>{response.data.product.cushion_data[1].name}</span></div> : null);
                setCushion3Data((response.data.product.cushion_data && response.data.product.cushion_data[2]) ? <div>Cushion <span className='font-bold'>{response.data.product.cushion_data[2].name}</span></div> : null);

                let updatedSections = [];

                // Colors
                if ( response.data.product.colors && response.data.product.colors.data && response.data.product.colors.data.length > 0 ) {
                    updatedSections = [...updatedSections, { title: "Colors", initiallyExpanded: parseInt(response.data.product.colors.is_expanded), data: response.data.product.colors.data }];
                }

                // Details
                if ( response.data.product.details && response.data.product.details.data && response.data.product.details.data.length > 0 ) {
                    updatedSections = [...updatedSections, { title: "Details", initiallyExpanded: parseInt(response.data.product.details.is_expanded), data: response.data.product.details.data }];
                }

                // Cushions
                if ( response.data.product.cushions && response.data.product.cushions.data && response.data.product.cushions.data.length > 0 ) {
                    updatedSections = [...updatedSections, { title: "Cushions", initiallyExpanded: parseInt(response.data.product.cushions.is_expanded), data: response.data.product.cushions.data }];
                }

                // Protective Covers
                if ( response.data.product.covers && response.data.product.covers.data && response.data.product.covers.data.length > 0 ) {
                    updatedSections = [...updatedSections, { title: "Protective Covers", initiallyExpanded: parseInt(response.data.product.covers.is_expanded), data: response.data.product.covers.data }];
                }

                // Related Items
                if ( response.data.product.related && response.data.product.related.data && response.data.product.related.data.length > 0 ) {
                    updatedSections = [...updatedSections, { title: "Related Items", initiallyExpanded: parseInt(response.data.product.related.is_expanded), data: response.data.product.related.data }];
                }

                // Dimensions
                if ( response.data.product.dimensions && response.data.product.dimensions.data && response.data.product.dimensions.data.length > 0 ) {
                    
                    const foundData = response.data.product.dimensions.data[0].table.some((data, index) => {
                        return /[a-zA-Z0-9]/.test(data);
                    });

                    if (foundData) {
                        updatedSections = [...updatedSections, { title: "Dimensions", initiallyExpanded: parseInt(response.data.product.dimensions.is_expanded), data: response.data.product.dimensions.data }];
                    }
                    
                }

                // Care
                if ( response.data.product.care && response.data.product.care.data && response.data.product.care.data.length > 0 ) {
                    updatedSections = [...updatedSections, { title: "Care", initiallyExpanded: parseInt(response.data.product.care.is_expanded), data: response.data.product.care.data }];
                }
                
                // Tear Sheet
                // if ( response.data.product.tearsheet && response.data.product.tearsheet.data && response.data.product.tearsheet.data.length > 0 ) {
                //     updatedSections = [...updatedSections, { title: "Tear Sheet", initiallyExpanded: parseInt(response.data.product.tearsheet.is_expanded) }];
                // }
                updatedSections = [...updatedSections, { title: "Tear Sheet", initiallyExpanded: 0 }];

                if (response.data.product.image_ids.length > 0) {
                    setMainImage(`${staticUrl}/img/media/products/product_${response.data.product.image_ids[0]}.jpg`);
                    setImageDescription(response.data.product.image_descriptions[0]);
                } else {
                    setMainImage(`${staticUrl}/img/media/noimage.jpg`);
                }

                setSections(updatedSections);
                setIsLoading(false);

            }).catch((error) => {
                console.log('Error: Cannot fetch product information.');
                console.log(error);
                setIsLoading(false);
            });
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }, []);


    // Function to handle image click event
    const handleImageClick = (imageUrl, imageDesc) => {
        setMainImage(imageUrl);
        setImageDescription(imageDesc);
    };

    let tearSheetColors = [];
    let tearSheetDetails = [];
    let tearSheetDimensions = [];

    const tearSheetArrays = {
        Dimensions: tearSheetDimensions,
        Details: tearSheetDetails,
        Colors: tearSheetColors,
    };

    // Sections
    const ExpandableSection = ({ title, initiallyExpanded, data }) => {
        const [isExpanded, setIsExpanded] = useState(() => {
            // Load the expanded state from local storage
            const storedExpandedState = localStorage.getItem(`expanded_${title}`);
            return storedExpandedState ? JSON.parse(storedExpandedState) : initiallyExpanded;
        });

        const toggleExpand = () => {
            setIsExpanded(!isExpanded);
            // Save the expanded state to local storage
            localStorage.setItem(`expanded_${title}`, JSON.stringify(!isExpanded));
        };
        
        // Create an array to store JSX content
        let contentArray = [];
        
        if ( isExpanded ) {
            if (title && data) {
                data.forEach((item, index) => {

                    if (item.title) {
                        contentArray.push(
                            <div className='text-kb-300 uppercase font-bold' key={generateRandomKey()}>{item.title}</div>
                        );
                    }
            
                    if (item.list) {
                        contentArray.push(
                            <ul className='list-disc pl-5 leading-8' style={{ listStyle: 'disc' }} key={generateRandomKey()}>
                                {item.list.map((listItem, i) => {
                                    // Parse the current listItem to identify links
                                    const parsedElements = parseTextWithLinksAndBold(listItem);
                    
                                    // Render the parsed elements with the first part (before :) in bold if it's not a URL
                                    return (
                                        <li key={generateRandomKey()}>
                                            {parsedElements.map((element, index) => (
                                                typeof element === 'object' && element.type === 'a' ?
                                                    element :
                                                    <span key={generateRandomKey()}>{element}</span>
                                            ))}
                                        </li>
                                    );
                                })}
                            </ul>
                        );
                    }
                    
            
                    if (item.fabrics) {
                        contentArray.push(
                            <div className='grid grid-cols-2 md:grid-cols-5 kb-md:grid-cols-4 kb-lg:grid-cols-5 kb-xl:grid-cols-6 gap-7' key={generateRandomKey()}>
                                {item.fabrics.map((image, index) => (
                                    
                                    fabricInfo[image.relation_id]?.name && (
                                        <div key={generateRandomKey()}>
                                            <a href={`${frontendUrl}/fabrics/${fabricInfo[image.relation_id]?.slug}`} key={generateRandomKey()}>
                                                <div>
                                                    <div className='space-y-2'>
                                                        <div className='min-h-[32px] max-h-[300px] aspect-square cursor-pointer'>
                                                            <img src={`${staticUrl}/img/media/fabrics/fabric_${image.relation_id}.jpg`} className="transform transition duration-500 hover:scale-[1.3]" alt={`Fabric ${fabricInfo[image?.relation_id]?.name}`} />
                                                        </div>
                                                        <div className='text-sm text-center'>
                                                            {fabricInfo[image?.relation_id]?.name?.split('[break]').map((line, index) => (
                                                                <div className='text-center' key={index}>{line}</div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    )

                                ))}
                            </div>
                        );
                    }

                    if (item.colors) {
                        contentArray.push(
                            <div className='grid grid-cols-2 md:grid-cols-5 kb-md:grid-cols-4 kb-lg:grid-cols-5 kb-xl:grid-cols-6 gap-7' key={generateRandomKey()}>
                                {item.colors.map((image) => (
                                    colorInfo[image.relation_id] && colorInfo[image.relation_id].name && (
                                        <div key={generateRandomKey()}>
                                            <div className='space-y-2' key={generateRandomKey()}>
                                                <div className='min-h-[32px] max-h-[300px] aspect-square cursor-pointer' onClick={(e) => { e.preventDefault(); openModal(image) }}>
                                                    <img src={`${staticUrl}/img/media/colors/color_${image.relation_id}.jpg`} className="transform transition duration-500 hover:scale-[1.3]" alt={`Color ${colorInfo[image.relation_id].name}`} />
                                                </div>
                                                <div className='text-sm text-center'>
                                                {colorInfo[image.relation_id].name.split('[break]').map((line, index) => (
                                                    <div className='text-center' key={index}>{line}</div>
                                                ))}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                ))}
                            </div>
                        );
                    }

                    if (item.text) {
                        //const parsedElements = parseTextWithLinksAndBold(item.text);
                        contentArray.push(
                            <div key={generateRandomKey()}>{TextParser(item.text)}</div>
                        );
                    }

                    if (item.table) {
                        contentArray.push(
                            <div key={generateRandomKey()}>
                                {item.table
                                    .filter(row => row.type === 'item' && row.right !== 0 && row.right !== null && /[a-zA-Z0-9]+/.test(row.right))
                                    .map((row, index, filteredRows) => (
                                        <div key={generateRandomKey()} className={`grid grid-cols-2 hover:bg-kb-300/5 ${index < filteredRows.length - 1 ? 'border-b border-kb-300/20' : ''}`}>
                                            <div className='p-2 pl-4 font-medium'>{row.left}</div>
                                            <div className='p-2 pl-4'>{row.right}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        );                        
                    }

                    if (item.care) {
                        contentArray.push(
                            <div className='space-y-2' key={generateRandomKey()}>
                                {item.care.map((row, index) => (
                                    <div key={generateRandomKey()}>
                                        { row.name && (
                                            <div className='px-3'><a href={`${staticUrl}/documents/care/care_${row.relation_id}.pdf`} target='_blank'>{row.name} (link to pdf)</a></div>
                                        ) }
                                    </div>
                                ))
                                }
                            </div>
                        );
                    }

                    if ( index === data.length - 1 ) {
                        contentArray.push(<div key={generateRandomKey()}></div>);
                    }
                    
                });
            } else {
                if ( title === 'Tear Sheet' ) {
                    contentArray.push(
                        <PrintTearSheet key={generateRandomKey()} mainImage={mainImage} tearSheetDimensions={tearSheetDimensions} tearSheetDetails={tearSheetDetails} tearSheetColors={tearSheetColors} collection={productData.group_name ? productData.group_name : ''} name={productData.name ? productData.name : ''} code={productData.code ? productData.code : ''} cushion1={cushion1Data} cushion2={cushion2Data} cushion3={cushion3Data} className='text-kb-400 hover:text-kb-300 pb-4' />
                    );
                }
            }
        }

        // Tear Sheet Content
        if ( title === 'Dimensions' || title === 'Details' || title === 'Colors' ) {
            
            if ( data && tearSheetArrays[title].length === 0 ) {

                data.forEach((item, index) => {

                    if (item.title) {
                        tearSheetArrays[title].push(
                            <div className='text-kb-300 uppercase font-bold' key={generateRandomKey()}>{item.title}</div>
                        );
                    }
            
                    if (item.list) {
                        tearSheetArrays[title].push(
                            <ul className='list-disc pl-5 !text-xs' style={{ listStyle: 'disc' }} key={generateRandomKey()}>
                                {item.list.map((listItem, i) => {
                                    // Parse the current listItem to identify links
                                    const parsedElements = parseTextWithLinksAndBold(listItem);
                    
                                    // Render the parsed elements with the first part (before :) in bold if it's not a URL
                                    return (
                                        <li key={generateRandomKey()}>
                                            {parsedElements.map((element, index) => (
                                                typeof element === 'object' && element.type === 'a' ?
                                                    element :
                                                    <span key={generateRandomKey()}>{element}</span>
                                            ))}
                                        </li>
                                    );
                                })}
                            </ul>
                        );
                    }
            
                    if (item.fabrics) {
                        tearSheetArrays[title].push(
                            <div className='grid grid-cols-4 gap-4' key={generateRandomKey()}>
                                {item.fabrics.map((image, index) => (
                                    <a href={`${frontendUrl}/fabrics/${fabricInfo[image.relation_id].slug}`} key={generateRandomKey()}>
                                        <div key={generateRandomKey()}>
                                            <div className='space-y-2'>
                                                <div className='min-h-[32px] max-h-[300px] aspect-square cursor-pointer'>
                                                    <img src={`${staticUrl}/img/media/fabrics/fabric_${image.relation_id}.jpg`} alt={`Fabric ${fabricInfo[image.relation_id].name}`} />
                                                </div>
                                                <div className='text-xs text-center'>
                                                    {fabricInfo[image.relation_id].name.split('[break]').map((line, index) => (
                                                        <div className='text-xs text-center' key={index}>{line}</div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        );
                    }

                    if (item.colors) {
                        tearSheetArrays[title].push(
                            <div className='grid grid-cols-4 gap-4' key={generateRandomKey()}>
                                {item.colors.map((image) => (
                                    colorInfo[image.relation_id] && colorInfo[image.relation_id].name && (
                                        <div key={generateRandomKey()}>
                                            <div key={generateRandomKey()}>
                                                <div className='min-h-[32px] max-h-[300px] aspect-square cursor-pointer' onClick={(e) => { e.preventDefault(); openModal(image) }}>
                                                    <img src={`${staticUrl}/img/media/colors/color_${image.relation_id}.jpg`} alt={`Color ${colorInfo[image.relation_id].name}`} />
                                                </div>
                                                {colorInfo[image.relation_id].name.split('[break]').map((line, index) => (
                                                    <div className='text-xs text-center' key={index}>{line}</div>
                                                ))}
                                            </div>
                                        </div>
                                    )
                                ))}
                            </div>
                        );
                    }                    

                    if (item.text) {
                        //const parsedElements = parseTextWithLinksAndBold(item.text);
                        tearSheetArrays[title].push(
                            <div key={generateRandomKey()}>{TextParser(item.text)}</div>
                        );
                    }

                    if (item.table) {
                        tearSheetArrays[title].push(
                            <div key={generateRandomKey()}>
                                {item.table
                                    .filter(row => row.type === 'item' && row.right !== 0 && row.right !== null && /[a-zA-Z0-9]+/.test(row.right))
                                    .map((row, index, filteredRows) => (
                                        <div key={generateRandomKey()} className={`grid grid-cols-2 hover:bg-kb-300/5 ${index < filteredRows.length - 1 ? 'border-b border-kb-300/20' : ''}`}>
                                            <div className='pl-4 font-medium'>{row.left}</div>
                                            <div className='pl-4'>{row.right}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        );                        
                    }

                    if (item.care) {
                        tearSheetArrays[title].push(
                            <div className='space-y-2' key={generateRandomKey()}>
                                {item.care.map((row, index) => (
                                    <div key={generateRandomKey()}>
                                        { row.name && (
                                            <div className='px-3'><a href={`${staticUrl}/documents/care/care_${row.relation_id}.pdf`} target='_blank'>{row.name} (link to pdf)</a></div>
                                        ) }
                                    </div>
                                ))
                                }
                            </div>
                        );
                    }
                    
                });
            }
        }

        // Render the content array
        if ( title === 'Colors' ) {
            return (
                <div className={`border-b border-kb-400 py-4`}>
                    <div className='space-y-5'>
                        {contentArray}
                    </div>
                </div>
            );   
        } else {
            return (
                <div className={`border-b ${isExpanded ? 'border-kb-400' : 'border-kb-400/75'} pt-4`}>
                    <div className={`flex justify-between items-center pb-4 hover:text-kb-300 ${isExpanded ? 'text-kb-300 font-semibold' : ''}`} onClick={toggleExpand} style={{ cursor: 'pointer' }}>
                        <div className={`uppercase tracking-widest select-none`}>{title}</div>
                        {isExpanded ? <BsDashLg /> : <BsPlusLg />}
                    </div>
                    <div className='space-y-5'>
                        {contentArray}
                    </div>
                </div>
            );   
        }

    };
    
    function parseTextWithLinksAndBold(text) {
        const linkRegex = /\[label="(.*?)"\s*url="(.*?)"\]/g;
        const boldRegex = /\[bold="(.*?)"\]/g;
    
        // First, handle links
        const linkedText = text.split(linkRegex).map((part, index) => {
            if (index % 3 === 1) {
                // Odd indices correspond to the label inside []
                const url = text.split(linkRegex)[index + 1];
                return { label: part, url: url };
            }
            return part;
        });
    
        // Then, handle bold text
        const result = linkedText.map((part) => {
            if (typeof part === 'string') {
                return part.split(boldRegex).map((part, index) => {
                    if (index % 2 === 1) {
                        // Odd indices correspond to the bold text inside []
                        return <span key={index} className="font-bold">{part}</span>;
                    }
                    return part;
                });
            } else if (typeof part === 'object' && part.label) {
                // Render the link with the label text only
                return <a key={generateRandomKey()} href={part.url} className='hover:text-kb-300 underline underline-offset-4'>{part.label}</a>;
            }
            return part;
        }).flat().filter(element => typeof element !== 'string' || !element.startsWith('http')); // Filter out plain text URLs
    
        // Add space after each link
        const finalResult = result.map((element, index, array) => {
            if (index < array.length - 1 && typeof element === 'object' && element.type === 'a') {
                return [element, '']; // Include space after link
            }
            return element;
        }).flat();
    
        return finalResult;
    }
    
    if ( isLoading ) {
        return <Loading />;
    }
      
    return (
        <Layout title='Products'>
            <main className='mt-[81px] kb-menu:mt-[97px] text-lg tracking-wider'>
                <div className='max-w-[1800px] kb-xl:max-w-none kb-xl:w-full mx-auto py-8 px-4 md:px-8 xl:px-16'>

                    { !productData && (
                        <NotFound />
                    ) }

                    { productData && (
                        <div>
                            <div className='uppercase text-2xl md:text-3xl kb-md:text-4xl text-kb-400 pt-6 kb-menu:py-6 kb-menu:pt-12 kb-md:hidden text-center kb-md:text-left pb-4'>{ productData.group_name ? productData.group_name : '' }</div>
                            <div className='kb-md:hidden text-kb-400 text-center text-sm pb-4'>
                                <div className='uppercase text-4xl pb-6 hidden kb-md:block'>{ productData.group_name ? productData.group_name : '' }</div>
                                <div><span className='font-bold uppercase'>{ productData.code ? productData.code : '' }</span> { productData.name ? productData.name : '' }</div>
                                {cushion1Data}
                                {cushion2Data}
                                {cushion3Data}
                            </div>
                            <div className='grid grid-cols-1 kb-md:grid-cols-2 gap-12 kb-lg:gap-24 kb-xl:gap-32 py-4 kb-md:py-6 kb-xl:py-12'>
                                <div className='lg:sticky lg:top-[140px] 3xl:top-[180px] lg:max-h-[1000px]'>
                                    <div className='flex justify-center items-center'>

                                        { productData.image_ids.length > 0 ? (
                                            <div className='hidden kb-cs:block kb-cs:-ml-9'>{ productData.image_ids.length > 1 ? <VerticalCarousel images={productData.image_ids} descriptions={productData.image_descriptions} onImageClick={handleImageClick} /> : <div className="w-[180px] kb-lg:w-[220px] p-4"><img src={`${staticUrl}/img/media/products/thumbnail_${productData.image_ids[0]}.jpg`} className="" style={{ boxShadow: '0 0 18px rgba(112, 128, 144, 0.25)' }} /></div> }</div>
                                        ) : (
                                            <div></div>
                                        ) }
                                        
                                        <div className='space-y-6 h-full kb-lg:pl-9'>
                                            <div className='w-full h-full relative' style={{ boxShadow: '0 0 18px rgba(112, 128, 144, 0.25)' }}>
                                                <div className='w-full flex items-center justify-center' >
                                                    <img src={mainImage} className='aspect-square md:aspect-square md:w-[450px] lg:w-[650px] kb-xl:w-[850px]' alt='' />
                                                </div>
                                            </div>
                                            <div className='text-sm kb-xl:text-lg text-center text-kb-400 w-full max-w-[750px] mx-auto'>
                                                { imageDescription }
                                            </div>
                                        </div>
                                    </div>

                                    { productData.image_ids.length > 1 ? (
                                        <>
                                            <div className='hidden lg:block kb-cs:hidden mt-8'>{ productData.image_ids.length > 1 ? <HorizontalCarousel images={productData.image_ids} descriptions={productData.image_descriptions} onImageClick={handleImageClick} /> : <div className="w-[180px] kb-lg:w-[220px] p-4"><img src={`${staticUrl}/img/media/products/thumbnail_${productData.image_ids[0]}.jpg`} className="" style={{ boxShadow: '0 0 18px rgba(112, 128, 144, 0.25)' }} /></div> }</div>
                                            <div className='lg:hidden mt-16'>
                                                <MobileCarousel images={productData.image_ids} descriptions={productData.image_descriptions} onImageClick={handleImageClick} />
                                            </div>                                    
                                        </>
                                    ) : (
                                        <div className='py-8'></div>
                                    ) }

                                </div>
                                <div className='text-kb-400 space-y-6 text-base xl:text-lg'>
                                    <div className='hidden kb-md:block'>
                                        <div className='uppercase text-4xl pb-6 hidden kb-md:block'>{ productData.group_name ? productData.group_name : '' }</div>
                                        <div className='uppercase text-2xl md:text-3xl kb-md:text-4xl pb-6 kb-md:hidden text-center'>Product Details</div>
                                        <div><span className='font-bold uppercase'>{ productData.code ? productData.code : '' }</span> { productData.name ? productData.name : '' }</div>
                                        {cushion1Data}
                                        {cushion2Data}
                                        {cushion3Data}
                                    </div>
                                    <div className='border-t border-kb-400 hidden kb-md:block'></div>
                                    <div className='space-y-4'>
                                        <div>
                                            {sections.map((section, index) => (
                                                <ExpandableSection key={generateRandomKey()} title={section.title} initiallyExpanded={section.initiallyExpanded} data={section.data} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <Modal isOpen={isModalOpen} onClose={closeModal} backdrop dismissable className='p-0 space-y-6' button width='w-[80%] md:max-w-[600px] lg:max-w-[1000px] xl:max-w-[1000px] 2xl:max-w-[1000px]'>
                        <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-x-10 space-y-5 text-kb-400'>
                            <div className="space-y-4">
                                <div><img src={`${staticUrl}/img/media/colors/color_${colorId}.jpg`} className="mx-auto max-h-[550px]" alt={`Color ${colorName}`} /></div>
                                <div className='text-[12px] leading-4'>
                                    Colors may vary based on your device and settings. Refer to actual sample before selecting.
                                </div>
                            </div>
                            <div className='space-y-3 max-w-[500px] flex flex-col justify-between'>
                                <div className='space-y-8'>
                                    <div className='space-y-5'>
                                        <div className='uppercase text-2xl md:text-4xl'>{colorName.replace(/\[break\]/g, ' ')}</div>
                                        <div className='flex space-x-3 text-xl'>
                                            { colorMaterial && <div className='uppercase'>{colorMaterial}</div> }
                                            { colorMaterial && <div>|</div> }
                                            { colorCode && <div className='uppercase'>{colorCode}</div> }
                                        </div>
                                    </div>
                                    <div className='space-y-3'>
                                        { colorContents && 
                                            <div className='grid grid-cols-5 gap-2'>
                                                <div className='font-bold text-xs md:text-sm col-span-2'>Contents</div>
                                                <div className='text-xs md:text-sm col-span-3'>{colorContents}</div>
                                            </div>
                                        }
                                        { colorPerformance && 
                                            <div className='grid grid-cols-5 gap-2'>
                                                <div className='font-bold text-xs md:text-sm col-span-2'>Performance</div>
                                                <div className='text-xs md:text-sm col-span-3'>{colorPerformance}</div>
                                            </div>                                   
                                        }
                                        { colorSuitable &&
                                            <div className='grid grid-cols-5 gap-2'>
                                                <div className='font-bold text-xs md:text-sm col-span-2'>Suitable for</div>
                                                <div className='text-xs md:text-sm col-span-3'>{colorSuitable}</div>
                                            </div>                                   
                                        }
                                        { colorCareId && 
                                            <div className='grid grid-cols-5 gap-2'>
                                                <div className='font-bold text-xs md:text-sm col-span-2'>Care Instructions</div>
                                                <div className='text-xs md:text-sm col-span-3'><a href={`${staticUrl}/documents/care/care_${colorCareId}.pdf`} target='_blank'>(link to pdf)</a></div>
                                            </div>   
                                        }
                                    </div>
                                </div>
                                { colorNote && 
                                    <div className='flex space-x-3 self-end'>
                                        <div className='font-bold text-xs md:text-sm'>Note:</div>
                                        <div className='text-xs md:text-sm'>{colorNote}</div>
                                    </div>
                                }
                            </div>
                        </div>
                    </Modal>
                
                </div>

                { collectionImage && (
                    <div className='grid xl:grid-cols-2 gap-2 pb-24'>
                        <div>
                            <a href={`${frontendUrl}/collections/${collectionSlug}`}>
                                <img src={`${staticUrl}/img/media/collections/collection_${collectionImage}.jpg`} className="w-full" alt="" />
                            </a>
                        </div>
                        <div className='bg-gray-100 flex items-center'>
                            <div className='space-y-8'>
                                <div className="block kb-menu:flex gap-x-8 justify-center items-center">
                                    <div className="text-kb-400 w-full space-y-6 p-4 md:p-8 kb-menu:p-8 2xl:p-12">
                                        <div className='text-2xl md:text-4xl uppercase'>{collectionName}</div>
                                        <div className='text-base md:text-lg'>{collectionDescription}</div>
                                        <div><a href={`${frontendUrl}/collections/${collectionSlug}`} className='hover:text-kb-300 text-xl md:text-2xl flex items-center'>Explore {collectionName} <MdKeyboardArrowRight /></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) }

                { products.length > 0 ? (
                    <div className='mx-auto pb-8 xl:pb-16'>
                        <div className='flex tracking-widest justify-center text-kb-400 text-2xl md:text-4xl uppercase font-light pb-8 xl:pb-16'>Explore the Collection</div>
                        <ProductsCarousel products={products} colors={colors} />
                    </div>
                ) : (
                    null
                ) }

            </main>
        </Layout>
    );
}
 
export default Product;

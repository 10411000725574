import React, { useState, useEffect } from 'react';
import Layout from '../../../layout/logged_out/Layout';
import { MdKeyboardArrowUp } from "react-icons/md";
import { backendUrl } from '../../../config';
import axios from 'axios';
import Loading from '../../components/Loading';
import TextParser from './components/TextParser';

const FAQ = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [faqItems, setFaqItems] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState(null);

    const handleQuestionClick = (id) => {
        setSelectedQuestion(id);
        scrollToQuestion(id);
    };

    const scrollToQuestion = (id) => {
        const questionElement = document.getElementById(`question-${id}`);
        if (questionElement) {
            const scrollOffset = questionElement.offsetTop - 130;
            window.scrollTo({ top: scrollOffset, behavior: "smooth" });
        }
    };

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const windowHeight = window.innerHeight;

        let currentQuestion = null;

        // Check if we are at the top of the page
        if (scrollPosition === 0) {
            setSelectedQuestion(faqItems[0]?.id);
            return;
        }

        faqItems?.forEach((item) => {
            const questionElement = document.getElementById(`question-${item.id}`);
            if (questionElement) {
                const { top } = questionElement.getBoundingClientRect();
                if (top <= 130 && top > -windowHeight / 2) {
                    currentQuestion = item.id;
                }
            }
        });

        setSelectedQuestion(currentQuestion);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Call handleScroll once when the component mounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [faqItems]);

    const leftPaneHeight = `calc(100vh - 160px)`; // Calculate the height dynamically

    // Scroll to top
    const [showScrollButton, setShowScrollButton] = useState(false);

    useEffect(() => {
        const getFAQ = async () => {
            const response = await axios.get(`${backendUrl}/w/faq`);
            setFaqItems(response?.data?.faq);
        };

        getFAQ();

        const handleScroll = () => {
            if (window.pageYOffset > 0) {
                setShowScrollButton(true);
            } else {
                setShowScrollButton(false);
            }
        };

        setIsLoading(false);

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleSelectChange = (event) => {
        const selectedId = event.target.value;
        setSelectedQuestion(selectedId);
        scrollToQuestion(selectedId);
    };

    if (isLoading) {
        return <Loading />;
    }

    const ddWidth = 'calc(100% - 30px)';

    return (
        <Layout title='Frequently Asked Questions'>
            <div className="flex justify-center items-start pl-0 pr-4 kb-menu:p-4 pb-0 max-w-screen-2xl mx-auto">
                <div className="flex w-full kb-menu:px-0 kb-menu:space-x-10">
                    <div className="hidden md:block w-1/3 border-l border-r border-gray-200">
                        <h2 className="text-base font-semibold sticky border-b border-gray-200 bg-white z-10 pt-10 px-4 pb-4 text-kb-300" style={{ top: '4.6rem' }}><span className='kb-menu:hidden text-lg'>FAQ</span><span className='hidden kb-menu:block text-lg'>Frequently Asked Questions</span></h2>
                        <div className="sticky top-40 overflow-y-auto kb-faq" style={{ maxHeight: leftPaneHeight }}>
                            <ul className="text-sm">
                                {faqItems.map((item) => (
                                    <li key={item.id}
                                        className={`px-2 cursor-pointer transition duration-300 ease-in-out 
                                        ${selectedQuestion === item.id ? 'bg-gray-100 bg-opacity-50 px-4 py-1 text-kb-300 font-semibold leading-8 text-lg'
                                            : 'px-4 py-1 hover:text-kb-300 leading-8 text-lg text-kb-400'}`}
                                        onClick={() => handleQuestionClick(item.id)} >
                                        {item.question}
                                    </li>
                                ))}
                            </ul>
                            <div className='h-[20px]'></div>
                        </div>
                    </div>
                    <div className="md:w-2/3">
                        <div className='fixed top-[80px] md:hidden bg-white w-full h-[90px] flex items-center px-4'>
                            <div className='w-full'>
                                <label htmlFor="selectQuestion" className="sr-only">Select a question</label>
                                <select id="selectQuestion" className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-kb-300 focus:border-kb-300" onChange={handleSelectChange}>
                                    <option value="">Select a question</option>
                                    {faqItems.map((item) => (
                                        <option key={item.id} value={item.id}>{item.question}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='pl-4'>
                            <div className='h-[180px] md:h-[140px]'></div>
                            <h1 className="text-3xl md:text-5xl xl:text-6xl text-kb-400 pb-10 tracking-wide font-light">Frequently Asked Questions</h1>
                            {faqItems.map((item) => (
                                <div key={item.id} id={`question-${item.id}`} className="pb-20 text-kb-400">
                                    <h2 className="block text-kb-300 font-bold text-xl uppercase pb-8">{item.question}</h2>
                                    {TextParser(item.answer)}
                                    <div className='border-b border-gray-200 py-10'></div>
                                </div>
                            ))}
                        </div>
                        <div className='md:h-[600px]'></div>
                    </div>
                </div>
            </div>
            {showScrollButton && (
                <button onClick={scrollToTop} className="fixed z-50 bottom-8 right-8 p-2 shadow-md text-white text-lg bg-kb-300 hover:bg-kb-600 rounded">
                    <MdKeyboardArrowUp />
                </button>
            )}
        </Layout>
    );
};

export default FAQ;

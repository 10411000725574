import axios from "axios";
import { useEffect, useState } from "react";
import { Outlet, Route, Routes, useParams, useNavigate } from "react-router-dom";
import generateRandomKey from "../../../components/GenerateRandomKey";
import { backendUrl, staticUrl } from "../../../../config";
import Products from "./Products";

const Collections = () => {

    const { '*': menuId } = useParams();
    const navigate = useNavigate();
    const [content, setContent] = useState([]);
    const [collectionName, setCollectionName] = useState('');

    const fetchContent = async () => {
        try {
            // setIsloading(true);
            const response = await axios.get(`${backendUrl}/w/image-library/collections`, { withCredentials: true });
            setContent(response?.data?.collections);
            console.log(response?.data?.collections)
            // setIsloading(false);

        } catch (error) {
            console.log(error);
            // setIsloading(false);
        }
    };

    useEffect(() => {
        fetchContent();
    }, []);

    const handleClick = (target, name) => {
        setCollectionName(name);
        navigate(`/image-library/collections/${target}`)
    };

    return (
        <>
            <div className="uppercase text-4xl pb-6">Collections</div>
            <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-10 gap-4">
                { content?.length > 0 && content?.map((collection) => (
                    <div key={generateRandomKey()} className="text-center space-y-2 hover:cursor-pointer hover:text-kb-300" onClick={() => { handleClick(collection.id, collection.name) }}>
                        <img src={`${staticUrl}/img/media/collections/carousel/collection_${collection.carousel_id}.jpg`} alt="" className="border border-gray-200" />
                        <div className="text-sm">{collection.name}</div>
                    </div>
                )) }
            </div>

            {/* <div className="uppercase text-4xl pb-6 hidden kb-md:block">Charlotte</div> */}
        </>
    );

};

export default Collections;
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Session from '../../../../api/session';
import Title from '../../../components/Title';
import Navbar from '../../../../layout/logged_in/Navbar';
import Sidenav from '../../../../layout/logged_in/Sidenav';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';
import generateRandomKey from '../../../components/GenerateRandomKey';
import { backendUrl } from '../../../../config';
import DateRangeSelector from '../../../components/DateRangeSelector';

const DataLibrary = () => {

    // Date Picker
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleDateRangeChange = (dateRange) => {
        setStartDate(dateRange.startDate);
        setEndDate(dateRange.endDate);
        setModalTradeEditStartDate(dateRange.startDate);
        setModalTradeEditEndDate(dateRange.endDate);
        console.log("Date Range:", dateRange);
    };


    const { libraryId } = useParams();

    const stateOptions = [
        { value: 'ALABAMA', label: 'Alabama' },
        { value: 'ALASKA', label: 'Alaska' },
        { value: 'ARIZONA', label: 'Arizona' },
        { value: 'ARKANSAS', label: 'Arkansas' },
        { value: 'CALIFORNIA', label: 'California' },
        { value: 'COLORADO', label: 'Colorado' },
        { value: 'CONNECTICUT', label: 'Connecticut' },
        { value: 'DELAWARE', label: 'Delaware' },
        { value: 'DISTRICT OF COLUMBIA', label: 'District of Columbia' },
        { value: 'FLORIDA', label: 'Florida' },
        { value: 'GEORGIA', label: 'Georgia' },
        { value: 'HAWAII', label: 'Hawaii' },
        { value: 'IDAHO', label: 'Idaho' },
        { value: 'ILLINOIS', label: 'Illinois' },
        { value: 'INDIANA', label: 'Indiana' },
        { value: 'IOWA', label: 'Iowa' },
        { value: 'KANSAS', label: 'Kansas' },
        { value: 'KENTUCKY', label: 'Kentucky' },
        { value: 'LOUISIANA', label: 'Louisiana' },
        { value: 'MAINE', label: 'Maine' },
        { value: 'MARYLAND', label: 'Maryland' },
        { value: 'MASSACHUSETTS', label: 'Massachusetts' },
        { value: 'MICHIGAN', label: 'Michigan' },
        { value: 'MINNESOTA', label: 'Minnesota' },
        { value: 'MISSISSIPPI', label: 'Mississippi' },
        { value: 'MISSOURI', label: 'Missouri' },
        { value: 'MONTANA', label: 'Montana' },
        { value: 'NEBRASKA', label: 'Nebraska' },
        { value: 'NEVADA', label: 'Nevada' },
        { value: 'NEW HAMPSHIRE', label: 'New Hampshire' },
        { value: 'NEW JERSEY', label: 'New Jersey' },
        { value: 'NEW MEXICO', label: 'New Mexico' },
        { value: 'NEW YORK', label: 'New York' },
        { value: 'NORTH CAROLINA', label: 'North Carolina' },
        { value: 'NORTH DAKOTA', label: 'North Dakota' },
        { value: 'OHIO', label: 'Ohio' },
        { value: 'OKLAHOMA', label: 'Oklahoma' },
        { value: 'OREGON', label: 'Oregon' },
        { value: 'PENNSYLVANIA', label: 'Pennsylvania' },
        { value: 'RHODE ISLAND', label: 'Rhode Island' },
        { value: 'SOUTH CAROLINA', label: 'South Carolina' },
        { value: 'SOUTH DAKOTA', label: 'South Dakota' },
        { value: 'TENNESSEE', label: 'Tennessee' },
        { value: 'TEXAS', label: 'Texas' },
        { value: 'UTAH', label: 'Utah' },
        { value: 'VERMONT', label: 'Vermont' },
        { value: 'VIRGINIA', label: 'Virginia' },
        { value: 'WASHINGTON', label: 'Washington' },
        { value: 'WEST VIRGINIA', label: 'West Virginia' },
        { value: 'WISCONSIN', label: 'Wisconsin' },
        { value: 'WYOMING', label: 'Wyoming' },
    ];

    const translate = {
        dealers: 'Dealer Finder',
        cushions: 'Cushions',
        covers: 'Protective Covers',
        faq: 'FAQ',
        trade: 'Trade Shows',
        mailing: 'Mailing List',
        users: 'Users'
    };

    // Data
    const [data, setData] = useState([]);

    // Modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalName, setModalName] = useState(false);
    const [modalType, setModalType] = useState('');
    const [modalIndex, setModalIndex] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [modalState, setModalState] = useState('');

    const [modalDealerEditName, setModalDealerEditName] = useState('');
    const [modalDealerEditAddress, setModalDealerEditAddress] = useState('');
    const [modalDealerEditCity, setModalDealerEditCity] = useState('');
    const [modalDealerEditState, setModalDealerEditState] = useState('');
    const [modalDealerEditZip, setModalDealerEditZip] = useState('');
    const [modalDealerEditCountry, setModalDealerEditCountry] = useState('');
    const [modalDealerEditUrl, setModalDealerEditUrl] = useState('');
    const [modalDealerEditEmail, setModalDealerEmail] = useState('');
    const [modalDealerEditPhone, setModalDealerEditPhone] = useState('');

    const [modalCushionEditName, setModalCushionEditName] = useState('');
    const [modalCushionEditInfo, setModalCushionEditInfo] = useState('');
    const [modalCushionEditFrame, setModalCushionEditFrame] = useState('');
    const [modalCushionEditStyleSeat, setModalCushionEditStyleSeat] = useState('');
    const [modalCushionEditStyleBack, setModalCushionEditStyleBack] = useState('');
    const [modalCushionEditWelt, setModalCushionEditWelt] = useState('');
    const [modalCushionEditTies, setModalCushionEditTies] = useState('');
    const [modalCushionEditCOMYDG, setModalCushionEditCOMYDG] = useState('');

    const [modalCoverEditName, setModalCoverEditName] = useState('');
    const [modalCoverEditFrame, setModalCoverEditFrame] = useState('');
    const [modalCoverEditDescription, setModalCoverEditDescription] = useState('');

    const [modalTradeEditName, setModalTradeEditName] = useState('');
    const [modalTradeEditDate, setModalTradeEditDate] = useState('');
    const [modalTradeEditAddress_1, setModalTradeEditAddress_1] = useState('');
    const [modalTradeEditAddress_2, setModalTradeEditAddress_2] = useState('');
    const [modalTradeEditAddress_3, setModalTradeEditAddress_3] = useState('');
    const [modalTradeEditAddress_4, setModalTradeEditAddress_4] = useState('');
    const [modalTradeEditStartDate, setModalTradeEditStartDate] = useState('');
    const [modalTradeEditEndDate, setModalTradeEditEndDate] = useState('');

    const [modalApproveName, setModalApproveName] = useState('');

    const openModal = (type, value, index, data) => {
        setModalType(type);
        setIsModalOpen(true);
        setModalName(value);

        if ( data ) {
            if ( libraryId === 'dealers' ) {
                setModalDealerEditName(data['name']);
                setModalDealerEditAddress(data['address']);
                setModalDealerEditCity(data['city']);
                setModalDealerEditState(data['state']);
                setModalDealerEditZip(data['zip']);
                setModalDealerEditCountry(data['country']);
                setModalDealerEditUrl(data['url']);
                setModalDealerEmail(data['email']);
                setModalDealerEditPhone(data['phone']);
            } else if ( libraryId === 'cushions' ) {
                setModalCushionEditName(data['name']);
                setModalCushionEditInfo(data['info']);
                setModalCushionEditFrame(data['frame']);
                setModalCushionEditStyleSeat(data['styleseat']);
                setModalCushionEditStyleBack(data['styleback']);
                setModalCushionEditWelt(data['welt']);
                setModalCushionEditTies(data['ties']);
                setModalCushionEditCOMYDG(data['comydg']);
            } else if ( libraryId === 'covers' ) {
                setModalCoverEditName(data['name']);
                setModalCoverEditFrame(data['frame']);
                setModalCoverEditDescription(data['description']);
            } else if ( libraryId === 'trade' ) {
                setModalTradeEditName(data['name']);
                setModalTradeEditAddress_1(data['address_1']);
                setModalTradeEditAddress_2(data['address_2']);
                setModalTradeEditAddress_3(data['address_3']);
                setModalTradeEditAddress_4(data['address_4']);
                setModalTradeEditStartDate('');
                setModalTradeEditEndDate('');
            } else if ( libraryId === 'users' ) {
                setModalApproveName(value);
            }
        }

        setModalIndex(index);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    // Refs New
    const typeRef = useRef(null);
    const dealerNameRef = useRef(null);
    const dealerAddressRef = useRef(null);
    const dealerCityRef = useRef(null);
    const dealerStateRef = useRef(null);
    const dealerZipRef = useRef(null);
    const dealerCountryRef = useRef(null);
    const dealerURLRef = useRef(null);
    const dealerEmailRef = useRef(null);
    const dealerPhoneRef = useRef(null);
    const cushionNameRef = useRef(null);
    const cushionInfoRef = useRef(null);
    const cushionFrameRef = useRef(null);
    const cushionStyleSeatRef = useRef(null);
    const cushionStyleBackRef = useRef(null);
    const cushionWeltRef = useRef(null);
    const cushionTiesRef = useRef(null);
    const cushionCOMYDGRef = useRef(null);
    const coverNameRef = useRef(null);
    const coverFrameRef = useRef(null);
    const coverDescriptionRef = useRef(null);
    const tradeNameRef = useRef(null);
    const tradeDateRef = useRef(null);
    const tradeAddress1Ref = useRef(null);
    const tradeAddress2Ref = useRef(null);
    const tradeAddress3Ref = useRef(null);
    const tradeAddress4Ref = useRef(null);
    const showroomRef = useRef(null);


    // Refs Edit
    const nameEditRef = useRef(null);

    const dealerNameEditRef = useRef(null);
    const dealerAddressEditRef = useRef(null);
    const dealerCityEditRef = useRef(null);
    const dealerStateEditRef = useRef(null);
    const dealerZipEditRef = useRef(null);
    const dealerCountryEditRef = useRef(null);
    const dealerURLEditRef = useRef(null);
    const dealerEmailEditRef = useRef(null);
    const dealerPhoneEditRef = useRef(null);
    const cushionNameEditRef = useRef(null);
    const cushionInfoEditRef = useRef(null);
    const cushionFrameEditRef = useRef(null);
    const cushionStyleSeatEditRef = useRef(null);
    const cushionStyleBackEditRef = useRef(null);
    const cushionWeltEditRef = useRef(null);
    const cushionTiesEditRef = useRef(null);
    const cushionCOMYDGEditRef = useRef(null);
    const coverNameEditRef = useRef(null);
    const coverFrameEditRef = useRef(null);
    const coverDescriptionEditRef = useRef(null);
    const tradeNameEditRef = useRef(null);
    const tradeDateEditRef = useRef(null);
    const tradeAddress1EditRef = useRef(null);
    const tradeAddress2EditRef = useRef(null);
    const tradeAddress3EditRef = useRef(null);
    const tradeAddress4EditRef = useRef(null);


    // Submut
    const [isSumbitDisabled, setIsSumbitDisabled] = useState(false);

    // Loading
    const [isLoading, setIsLoading] = useState(true);

    // Show library does not exist
    const [isError, setIsError] = useState(false);
   
    const fetchData = async () => {
        try {

            if ( !translate[libraryId] ) return setIsError(true);

            const response = await axios.get(`${backendUrl}/a/data/${libraryId}`, { withCredentials: true });

            if (response.data.dealers !== null) {
                if  ( libraryId === 'dealers' ) {
                    setData(response.data.dealers);
                } else if  ( libraryId === 'cushions' ) {
                    setData(response.data.cushions);
                } else if  ( libraryId === 'covers' ) {
                    setData(response.data.covers);
                } else if  ( libraryId === 'trade' ) {
                    setData(response.data.trade);
                } else if  ( libraryId === 'mailing' ) {
                    setData(response.data.mailing);
                } else if  ( libraryId === 'users' ) {
                    setData(response.data.users);
                }
            }

            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            setIsError(true);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    const approveUser = () => {

        const _approve = async () => {
            try {
                await axios.post(`${backendUrl}/a/data/${libraryId}/${modalIndex}`, {}, { withCredentials: true });
                setIsModalOpen(false);
                window.showToast('WCL19916');
                fetchData();
            } catch (error) {
                console.log(error);
                window.showToast('WCL19917');
            }
        };
    
        _approve();

    };

    const deleteUser = () => {

        const _delete = async () => {
            try {
                await axios.get(`${backendUrl}/a/data/delete/${libraryId}/${modalIndex}`, { withCredentials: true });
                setIsModalOpen(false);
                window.showToast('WCL19916');
                fetchData();
            } catch (error) {
                console.log(error);
                window.showToast('WCL19917');
            }
        };
    
        _delete();

    };


    const exportEmails = () => {

        const _export = async () => {
            try {
                const response = await axios.get(`${backendUrl}/a/export-emails`, { withCredentials: true, responseType: 'blob' });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'emails.csv');
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                console.log(error);
                window.showToast('WCL20465');
            }
        };
    
        _export();

    };


    const deleteTrade = () => {

        const _deleteTrade = async () => {
            try {
                await axios.get(`${backendUrl}/a/data/delete/${libraryId}/${modalIndex}`, { withCredentials: true });
                setIsModalOpen(false);
                window.showToast('WCL20456');
                fetchData();
            } catch (error) {
                console.log(error);
                window.showToast('WCL20457');
            }
        };
    
        _deleteTrade();

    };

    const editTrade = async () => {

        if (!tradeNameEditRef.current.value || !tradeNameEditRef.current.value.match(/[a-zA-Z0-9]+/)) {
            return window.showToast('WCL20443');
        }

        setIsSumbitDisabled(true);
          console.log(startDate)
        const data = {
            name: tradeNameEditRef.current.value,
            data_2: (tradeAddress1EditRef.current.value) ? tradeAddress1EditRef.current.value : null,
            data_3: (tradeAddress2EditRef.current.value) ? tradeAddress2EditRef.current.value : null,
            data_4: (tradeAddress3EditRef.current.value) ? tradeAddress3EditRef.current.value : null,
            data_5: (tradeAddress4EditRef.current.value) ? tradeAddress4EditRef.current.value : null,
            start_date: modalTradeEditStartDate ? modalTradeEditStartDate : null,
            end_date: modalTradeEditEndDate ? modalTradeEditEndDate : null
        };

        try {
            await axios.post(`${backendUrl}/a/data/${libraryId}/${modalIndex}`, data, {
                withCredentials: true,
            });

            fetchData();
            closeModal();

            window.showToast('WCL20454');

            setTimeout(() => {
                setIsSumbitDisabled(false);
            }, 5000);

        } catch (error) {
            console.error(error);
            window.showToast('WCL20455');

            setTimeout(() => {
                setIsSumbitDisabled(false);
            }, 5000);

        }

    };

    const addTrade = async () => {

        if (!tradeNameRef.current.value || !tradeNameRef.current.value.match(/[a-zA-Z0-9]+/)) {
            return window.showToast('WCL20453');
        }

        setIsSumbitDisabled(true);
          
        const data = {
            name: tradeNameRef.current.value,
            data_2: (tradeAddress1Ref.current.value) ? tradeAddress1Ref.current.value : null,
            data_3: (tradeAddress2Ref.current.value) ? tradeAddress2Ref.current.value : null,
            data_4: (tradeAddress3Ref.current.value) ? tradeAddress3Ref.current.value : null,
            data_5: (tradeAddress4Ref.current.value) ? tradeAddress4Ref.current.value : null,
            start_date: startDate ? startDate : null,
            end_date: endDate ? endDate : null
        };

        try {
            await axios.post(`${backendUrl}/a/data/${libraryId}`, data, {
                withCredentials: true,
            });

            fetchData();
            closeModal();

            window.showToast('WCL20451');

            setTimeout(() => {
                setIsSumbitDisabled(false);
            }, 5000);

        } catch (error) {
            console.error(error);
            window.showToast('WCL20452');

            setTimeout(() => {
                setIsSumbitDisabled(false);
            }, 5000);

        }

    };

    const deleteCover = () => {

        const _deleteCover = async () => {
            try {
                await axios.get(`${backendUrl}/a/data/delete/${libraryId}/${modalIndex}`, { withCredentials: true });
                setIsModalOpen(false);
                window.showToast('WCL20441');
                fetchData();
            } catch (error) {
                console.log(error);
                window.showToast('WCL20442');
            }
        };
    
        _deleteCover();

    };

    const editCover = async () => {

        if (!coverNameEditRef.current.value || !coverNameEditRef.current.value.match(/[a-zA-Z0-9]+/)) {
            return window.showToast('WCL20443');
        }

        setIsSumbitDisabled(true);
          
        const data = {
            name: coverNameEditRef.current.value,
            frame: coverFrameEditRef.current.value,
            description: coverDescriptionEditRef.current.value
        };

        try {
            await axios.post(`${backendUrl}/a/data/${libraryId}/${modalIndex}`, data, {
                withCredentials: true,
            });

            fetchData();
            closeModal();

            window.showToast('WCL20446');

            setTimeout(() => {
                setIsSumbitDisabled(false);
            }, 5000);

        } catch (error) {
            console.error(error);
            window.showToast('WCL20447');

            setTimeout(() => {
                setIsSumbitDisabled(false);
            }, 5000);

        }

    };

    const addCover = async () => {

        if (!coverNameRef.current.value || !coverNameRef.current.value.match(/[a-zA-Z0-9]+/)) {
            return window.showToast('WCL20443');
        }

        setIsSumbitDisabled(true);
          
        const data = {
            name: coverNameRef.current.value,
            frame: coverFrameRef.current.value,
            description: coverDescriptionRef.current.value
        };

        try {
            await axios.post(`${backendUrl}/a/data/${libraryId}`, data, {
                withCredentials: true,
            });

            fetchData();
            closeModal();

            window.showToast('WCL20444');

            setTimeout(() => {
                setIsSumbitDisabled(false);
            }, 5000);

        } catch (error) {
            console.error(error);
            window.showToast('WCL20445');

            setTimeout(() => {
                setIsSumbitDisabled(false);
            }, 5000);

        }

    };

    const deleteCushion = () => {

        const _deleteCushion = async () => {
            try {
                await axios.get(`${backendUrl}/a/data/delete/${libraryId}/${modalIndex}`, { withCredentials: true });
                setIsModalOpen(false);
                window.showToast('WCL20441');
                fetchData();
            } catch (error) {
                console.log(error);
                window.showToast('WCL20442');
            }
        };
    
        _deleteCushion();

    };

    const editCushion = async () => {

        if (!cushionNameEditRef.current.value || !cushionNameEditRef.current.value.match(/[a-zA-Z0-9]+/)) {
            return window.showToast('WCL20436');
        }

        setIsSumbitDisabled(true);
          
        const data = {
            name: cushionNameEditRef.current.value,
            info: cushionInfoEditRef.current.value,
            frame: cushionFrameEditRef.current.value,
            styleseat: cushionStyleSeatEditRef.current.value,
            styleback: cushionStyleBackEditRef.current.value,
            welt: cushionWeltEditRef.current.value,
            ties: cushionTiesEditRef.current.value,
            comydg: cushionCOMYDGEditRef.current.value,
        };

        try {
            await axios.post(`${backendUrl}/a/data/${libraryId}/${modalIndex}`, data, {
                withCredentials: true,
            });

            fetchData();
            closeModal();

            window.showToast('WCL20439');

            setTimeout(() => {
                setIsSumbitDisabled(false);
            }, 5000);

        } catch (error) {
            console.error(error);
            window.showToast('WCL20440');

            setTimeout(() => {
                setIsSumbitDisabled(false);
            }, 5000);

        }

    };

    const addCushion = async () => {

        if (!cushionNameRef.current.value || !cushionNameRef.current.value.match(/[a-zA-Z0-9]+/)) {
            return window.showToast('WCL20436');
        }

        setIsSumbitDisabled(true);
          
        const data = {
            name: cushionNameRef.current.value,
            info: cushionInfoRef.current.value,
            frame: cushionFrameRef.current.value,
            styleseat: cushionStyleSeatRef.current.value,
            styleback: cushionStyleBackRef.current.value,
            welt: cushionWeltRef.current.value,
            ties: cushionTiesRef.current.value,
            comydg: cushionCOMYDGRef.current.value,
        };

        try {
            await axios.post(`${backendUrl}/a/data/${libraryId}`, data, {
                withCredentials: true,
            });

            fetchData();
            closeModal();

            window.showToast('WCL20437');

            setTimeout(() => {
                setIsSumbitDisabled(false);
            }, 5000);

        } catch (error) {
            console.error(error);
            window.showToast('WCL20438');

            setTimeout(() => {
                setIsSumbitDisabled(false);
            }, 5000);

        }

    };

    const deleteDealer = () => {

        const _deleteDealer = async () => {
            try {
                await axios.get(`${backendUrl}/a/data/delete/${libraryId}/${modalIndex}`, { withCredentials: true });
                setIsModalOpen(false);
                window.showToast('WCL20434');
                fetchData();
            } catch (error) {
                console.log(error);
                window.showToast('WCL20435');
            }
        };
    
        _deleteDealer();

    };

    const addDealer = async () => {

        if (!dealerNameRef.current.value || !dealerNameRef.current.value.match(/[a-zA-Z0-9]+/)) {
            return window.showToast('WCL20431');
        }

        setIsSumbitDisabled(true);
          
        const data = {
            name: dealerNameRef.current.value,
            address: dealerAddressRef.current.value,
            city: dealerCityRef.current.value,
            state: dealerStateRef.current.value,
            zip: dealerZipRef.current.value,
            country: dealerCountryRef.current.value,
            url: dealerURLRef.current.value,
            email: dealerEmailRef.current.value,
            phone: dealerPhoneRef.current.value,
            showroom: showroomRef.current.checked ? 1 : 0
        };

        try {
            await axios.post(`${backendUrl}/a/data/${libraryId}`, data, {
                withCredentials: true,
            });

            fetchData();
            closeModal();

            window.showToast('WCL20429');

            setTimeout(() => {
                setIsSumbitDisabled(false);
            }, 5000);

        } catch (error) {
            console.error(error);
            window.showToast('WCL20430');

            setTimeout(() => {
                setIsSumbitDisabled(false);
            }, 5000);

        }

    };

    const editDealer = async () => {

        if (!dealerNameEditRef.current.value || !dealerNameEditRef.current.value.match(/[a-zA-Z0-9]+/)) {
            return window.showToast('WCL20431');
        }

        setIsSumbitDisabled(true);
          
        const data = {
            name: dealerNameEditRef.current.value,
            address: dealerAddressEditRef.current.value,
            city: dealerCityEditRef.current.value,
            state: dealerStateEditRef.current.value,
            zip: dealerZipEditRef.current.value,
            country: dealerCountryEditRef.current.value,
            url: dealerURLEditRef.current.value,
            email: dealerEmailEditRef.current.value,
            phone: dealerPhoneEditRef.current.value
        };

        try {
            await axios.post(`${backendUrl}/a/data/${libraryId}/${modalIndex}`, data, {
                withCredentials: true,
            });

            fetchData();
            closeModal();

            window.showToast('WCL20432');

            setTimeout(() => {
                setIsSumbitDisabled(false);
            }, 5000);

        } catch (error) {
            console.error(error);
            window.showToast('WCL20433');

            setTimeout(() => {
                setIsSumbitDisabled(false);
            }, 5000);

        }

    };


    let contentArray = [];

    if (data.length === 0) {
        contentArray.push(
            <div key={generateRandomKey()} className='flex w-full justify-center p-4 border border-dashed border-gray-200'>
                Nothing to display.
            </div>
        );
    }

    contentArray = data
        .filter((info) => {
            if (!searchValue) {
                return true; // Show all images if search value is empty
            }
            return info.name.toLowerCase().includes(searchValue.toLowerCase()); // Apply filter if search value is not empty
        })
        .map((info, index) => {

            const imageDate = new Date(info.date);
            const formattedDate = imageDate.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });

            let fields = {};

            if ( libraryId === 'dealers' ) {
                fields = {
                    name: (info.name) ? info.name : '',
                    address: (info.address) ? info.address : '',
                    city: (info.city) ? info.city : '',
                    state: (info.state) ? info.state : '',
                    zip: (info.zip_code) ? info.zip_code : '',
                    country: (info.country) ? info.country : '',
                    url: (info.url) ? info.url : '',
                    email: (info.email) ? info.email : '',
                    phone: (info.phone) ? info.phone : '',
                };
            } else if ( libraryId === 'cushions' ) {
                fields = {
                    name: (info.name) ? info.name : '',
                    info: (info.info) ? info.info : '',
                    frame: (info.frame) ? info.frame : '',
                    styleseat: (info.style_seat) ? info.style_seat : '',
                    styleback: (info.style_back) ? info.style_back : '',
                    welt: (info.welt) ? info.welt : 0,
                    ties: (info.ties) ? info.ties : 0,
                    comydg: (info.com_ydg) ? info.com_ydg : 0,
                };
            } else if ( libraryId === 'covers' ) {
                fields = {
                    name: (info.name) ? info.name : '',
                    frame: (info.frame) ? info.frame : '',
                    description: (info.description) ? info.description : ''
                };
            } else if ( libraryId === 'trade' ) {
                fields = {
                    name: (info.name) ? info.name : '',
                    date: (info.data_1) ? info.data_1 : '',
                    address_1: (info.data_2) ? info.data_2 : '',
                    address_2: (info.data_3) ? info.data_3 : '',
                    address_3: (info.data_4) ? info.data_4 : '',
                    address_4: (info.data_5) ? info.data_5 : '',
                    start_date: (info.start_date) ? info.start_date : '',
                    end_date: (info.end_date) ? info.end_date : ''
                };
            }

            return (
                <div key={generateRandomKey()}>
                    <div className='flex space-x-3 justify-between w-full border border-gray-200 p-2 px-4 bg-gray-50'>
                        <div className='flex space-x-3'>
                            <div>
                                <div className='flex space-x-3 items-center'>
                                    <div className='text-gray-400 w-[20px] text-right'>{index + 1}</div>
                                </div>
                            </div>
                            <div className=''>
                                <div className='font-bold'>{info.name}</div>
                                <div className='text-sm'>{formattedDate}</div>
                            </div>
                        </div>
                        <div className='flex space-x-5'>
                            { libraryId === 'dealers' && (
                                <>
                                    <div><a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('editDealer', info.name, info.id, fields) }}>Edit</a></div>
                                    <div><a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('deleteDealer', info.name, info.id) }}>Delete</a></div>
                                </>
                            )}
                            { libraryId === 'cushions' && (
                                <>
                                    <div><a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('editCushion', info.name, info.id, fields) }}>Edit</a></div>
                                    <div><a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('deleteCushion', info.name, info.id) }}>Delete</a></div>
                                </>
                            )}
                            { libraryId === 'covers' && (
                                <>
                                    <div><a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('editCover', info.name, info.id, fields) }}>Edit</a></div>
                                    <div><a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('deleteCover', info.name, info.id) }}>Delete</a></div>
                                </>
                            )}
                            { libraryId === 'trade' && (
                                <>
                                    <div><a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('editTrade', info.name, info.id, fields) }}>Edit</a></div>
                                    <div><a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('deleteTrade', info.name, info.id) }}>Delete</a></div>
                                </>
                            )}
                            { libraryId === 'users' && (
                                <>
                                    { info.type === 'pending' && (
                                        <div><a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('approveUser', info.name, info.id) }}>Approve</a></div>
                                    ) }
                                    <div><a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('deleteUser', info.name, info.id) }}>Delete</a></div>
                                </>
                            )}                            
                        </div>
                    </div>
                </div>
            );
        });

    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Add total pages state
    const paginatedContentArray = contentArray.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const generatePageNumbers = () => {
        const pageNumbers = [];
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, currentPage + 2);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    useEffect(() => {
        setTotalPages(Math.ceil(contentArray.length / itemsPerPage)); // Update total pages when data or search value changes
    }, [data, searchValue]);

    if (isLoading) {
        return <Loading />;
    }

    if (isError) {
        return (
            <>

                <Session />
                <Title set="Data Library" />
                <div className="App">
                    
                    <div className="antialiased">
                        <Navbar />
                        <Sidenav />
                        <main className="p-4 lg:pl-[270px] h-screen max-w-screen-2xl pt-20 z-0 mx-auto">
                            <div className='flex w-full justify-center p-4 border border-dashed border-gray-200'>
                                Library does not exist.
                            </div>
                        </main>
                    </div>

                </div>
            
            </>
        );
    }

    return (
        <>
            <Session />
            <Title set="Data Library" />
            <div className="App">
                
                <div className="antialiased">
                    <Navbar />
                    <Sidenav />
                    <main className="p-4 lg:pl-[270px] h-screen max-w-screen-2xl pt-20 z-0 mx-auto">
                        <div className='flex'>
                            <div className='hidden lg:block lg:fixed top-[95px] w-[175px] p-4 space-y-3 border border-dashed border-gray-200 bg-gray-50'>
                                <div className='font-bold'>LIBRARIES</div>
                                <div><a href={`/admin/data/dealers`} className='hover:underline'>Dealer Finder</a></div>
                                <div><a href={`/admin/data/cushions`} className='hover:underline'>Cushions</a></div>
                                <div><a href={`/admin/data/covers`} className='hover:underline'>Protective Covers</a></div>
                                <div><a href={`/admin/data/trade`} className='hover:underline'>Trade Shows</a></div>
                                <div><a href={`/admin/data/mailing`} className='hover:underline'>Mailing List</a></div>
                                <div><a href={`/admin/data/users`} className='hover:underline'>Users</a></div>
                            </div>
                            <div className='lg:ml-[195px] w-full'>
                                <div className='block lg:hidden p-4 border border-dashed border-gray-200 bg-gray-50 space-x-0'>
                                    <div className='font-bold'>LIBRARIES</div>
                                    <div><a href={`/admin/data/dealers`} className='hover:underline'>Dealer Finder</a></div>
                                    <div><a href={`/admin/data/cushions`} className='hover:underline'>Cushions</a></div>
                                    <div><a href={`/admin/data/covers`} className='hover:underline'>Protective Covers</a></div>
                                    <div><a href={`/admin/data/trade`} className='hover:underline'>Trade Shows</a></div>
                                    <div><a href={`/admin/data/mailing`} className='hover:underline'>Mailing List</a></div>
                                    <div><a href={`/admin/data/users`} className='hover:underline'>Users</a></div>
                                </div>
                                <div className='lg:flex justify-between items-center'>
                                    <div className=''>
                                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light py-7 uppercase'>DATA LIBRARY</h2>
                                    </div>
                                    <div className='mb-10 lg:mb-0 justify-center lg:justify-start flex gap-x-3'>
                                        { libraryId === 'dealers' && (
                                            <Button size='sm' text='ADD DEALER' onClick={() => { openModal('addDealer') }} />
                                        )}
                                        { libraryId === 'cushions' && (
                                            <Button size='sm' text='ADD CUSHION' onClick={() => { openModal('addCushion') }} />
                                        )}
                                        { libraryId === 'covers' && (
                                            <Button size='sm' text='ADD COVER' onClick={() => { openModal('addCover') }} />
                                        )}
                                        { libraryId === 'trade' && (
                                            <Button size='sm' text='ADD TRADE SHOW' onClick={() => { openModal('addTrade') }} />
                                        )}
                                        { libraryId === 'mailing' && (
                                            <Button size='sm' text='EXPORT EMAILS' onClick={() => { exportEmails() }} />
                                        )}
                                    </div>
                                </div>
                                <div className='text-xl bg-gray-200 p-4 flex items-center'>
                                    <div>
                                        <span className='font-bold'>{translate[libraryId]}</span> Library
                                    </div>
                                </div>
                                <div className='lg:flex justify-between items-center mb-10 w-full bg-gray-50 border border-gray-200'>
                                    <div className='justify-center lg:justify-start lg:flex lg:space-x-3 space-y-3 lg:space-y-0 p-4 items-center'>
                                        <div className='font-bold'>Search</div>
                                        <input type="text" value={searchValue} onChange={(e) =>  { setSearchValue(e.target.value); setCurrentPage(1); }} placeholder="Search by name" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                    </div>
                                </div>
                                <div className='space-y-3'>

                                    <div className="flex my-4">
                                        <p><span className='font-bold'>Showing</span> {Math.min((currentPage - 1) * itemsPerPage + 1, contentArray.length)}-{Math.min(currentPage * itemsPerPage, contentArray.length)} of {contentArray.length}</p>
                                    </div>

                                    {paginatedContentArray.length === 0 ? (
                                        <div className='flex w-full justify-center p-4 border border-dashed border-gray-200'>
                                            Nothing to display.
                                        </div>
                                    ) : (
                                        paginatedContentArray
                                    )}
                                    <div className="flex justify-center space-x-4">
                                        <Button size='sm' text='Prev' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                                        {generatePageNumbers().map((pageNumber) => (
                                            <Button key={pageNumber} size='sm' text={pageNumber} onClick={() => handlePageChange(pageNumber)} disabled={pageNumber === currentPage} />
                                        ))}
                                        <Button size='sm' text='Next' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages || paginatedContentArray.length === 0} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </main>
                </div>

            </div>

            <Modal isOpen={isModalOpen} onClose={closeModal} className='p-0 space-y-6' button width='w-[90%] md:w-[80%] lg:w-[60%] xl:w-[50%] 2xl:w-[40%]'>

                {modalType === 'approveUser' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Approve User</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Are you sure you want to approve this user?</div>
                                <input type="text" defaultValue={modalName} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" disabled />
                            </div>

                            <Button size='sm' text='APPROVE' onClick={() => approveUser()} />

                        </div>
                    </>
                )}

                {modalType === 'deleteUser' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete User</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Are you sure you want to delete this user?</div>
                                <input type="text" defaultValue={modalName} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" disabled />
                            </div>

                            <Button size='sm' text='DELETE' onClick={() => deleteUser()} />

                        </div>
                    </>
                )}

                {modalType === 'deleteTrade' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete Trade Show</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Are you sure you want to delete this event?</div>
                                <input type="text" defaultValue={modalName} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" disabled />
                            </div>

                            <Button size='sm' text='DELETE' onClick={() => deleteTrade()} />

                        </div>
                    </>
                )}

                {modalType === 'editTrade' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Edit Trade Show</h2>
                        <div className='space-y-6'>
                            <div className='space-y-6 w-full'>
                                <div className=''>
                                    <div className='font-bold'>Name</div>
                                    <input ref={tradeNameEditRef} type="text" defaultValue={modalTradeEditName} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Date / Range</div>
                                    {console.log(modalTradeEditStartDate)}
                                    <DateRangeSelector onDateRangeChange={handleDateRangeChange} initialStartDate={modalTradeEditStartDate} initialEndDate={modalTradeEditEndDate} />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Address 1</div>
                                    <input ref={tradeAddress1EditRef} type="text" defaultValue={modalTradeEditAddress_1} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Address 2</div>
                                    <input ref={tradeAddress2EditRef} type="text" defaultValue={modalTradeEditAddress_2} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Address 3</div>
                                    <input ref={tradeAddress3EditRef} type="text" defaultValue={modalTradeEditAddress_3} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Address 4</div>
                                    <input ref={tradeAddress4EditRef} type="text" defaultValue={modalTradeEditAddress_4} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                            </div>
                            <div className='pt-10'>
                                <Button size='sm' text='UPDATE TRADE SHOW' onClick={() => editTrade()} disabled={isSumbitDisabled} />
                            </div>
                        </div>
                    </>
                )}

                {modalType === 'addTrade' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Add Trade Show</h2>
                        <div className='space-y-6'>
                            <div className='space-y-6 w-full'>
                                <div className=''>
                                    <div className='font-bold'>Name</div>
                                    <input ref={tradeNameRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Date / Range</div>
                                    <DateRangeSelector onDateRangeChange={handleDateRangeChange} />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Address 1</div>
                                    <input ref={tradeAddress1Ref} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Address 2</div>
                                    <input ref={tradeAddress2Ref} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Address 3</div>
                                    <input ref={tradeAddress3Ref} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Address 4</div>
                                    <input ref={tradeAddress4Ref} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                            </div>
                            <div className='pt-10'>
                                <Button size='sm' text='ADD TRADE SHOW' onClick={() => addTrade()} disabled={isSumbitDisabled} />
                            </div>
                        </div>
                    </>
                )}

                {modalType === 'deleteCover' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete Cover</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Are you sure you want to delete this cover?</div>
                                <input type="text" defaultValue={modalName} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" disabled />
                            </div>

                            <Button size='sm' text='DELETE' onClick={() => deleteCover()} />

                        </div>
                    </>
                )}

                {modalType === 'editCover' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Edit Cover</h2>
                        <div className='space-y-6'>
                            <div className='space-y-6 w-full'>
                                <div className=''>
                                    <div className='font-bold'>Name</div>
                                    <input ref={coverNameEditRef} type="text" defaultValue={modalCoverEditName} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Frame</div>
                                    <input ref={coverFrameEditRef} type="text" defaultValue={modalCoverEditFrame} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Description</div>
                                    <input ref={coverDescriptionEditRef} type="text" defaultValue={modalCoverEditDescription} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                            </div>
                            <div className='pt-10'>
                                <Button size='sm' text='UPDATE COVER' onClick={() => editCover()} disabled={isSumbitDisabled} />
                            </div>
                        </div>
                    </>
                )}

                {modalType === 'addCover' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Add Cover</h2>
                        <div className='space-y-6'>
                            <div className='space-y-6 w-full'>
                                <div className=''>
                                    <div className='font-bold'>Name</div>
                                    <input ref={coverNameRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Frame</div>
                                    <input ref={coverFrameRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Description</div>
                                    <input ref={coverDescriptionRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                            </div>
                            <div className='pt-10'>
                                <Button size='sm' text='ADD COVER' onClick={() => addCover()} disabled={isSumbitDisabled} />
                            </div>
                        </div>
                    </>
                )}

                {modalType === 'deleteCushion' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete Cushion</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Are you sure you want to delete this cushion?</div>
                                <input type="text" defaultValue={modalName} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" disabled />
                            </div>

                            <Button size='sm' text='DELETE' onClick={() => deleteCushion()} />

                        </div>
                    </>
                )}

                {modalType === 'editCushion' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Edit Cushion</h2>
                        <div className='space-y-6'>
                            <div className='space-y-6 w-full'>
                                <div className=''>
                                    <div className='font-bold'>Name</div>
                                    <input ref={cushionNameEditRef} type="text" defaultValue={modalCushionEditName} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Info</div>
                                    <input ref={cushionInfoEditRef} type="text" defaultValue={modalCushionEditInfo} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className='grid grid-cols-1 lg:grid-cols-3 lg:gap-5'>
                                    <div className=''>
                                        <div className='font-bold'>Frame</div>
                                        <input ref={cushionFrameEditRef} type="text" defaultValue={modalCushionEditFrame} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                    <div className=''>
                                        <div className='font-bold'>Style Seat</div>
                                        <input ref={cushionStyleSeatEditRef} type="text" defaultValue={modalCushionEditStyleSeat} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                    <div className=''>
                                        <div className='font-bold'>Style Back</div>
                                        <input ref={cushionStyleBackEditRef} type="text" defaultValue={modalCushionEditStyleBack} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 lg:grid-cols-3 lg:gap-5'>
                                    <div className=''>
                                        <div className='font-bold'>Welt</div>
                                        <select defaultValue={modalCushionEditWelt} ref={cushionWeltEditRef} className='bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                            <option value='1'>yes</option>
                                            <option value='0'>no</option>
                                        </select>
                                        {/* <input ref={cushionWeltEditRef} type="number" defaultValue={modalCushionEditWelt} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" /> */}
                                    </div>
                                    <div className=''>
                                        <div className='font-bold'>Ties</div>
                                        <select defaultValue={modalCushionEditTies} ref={cushionTiesEditRef} className='bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                            <option value='1'>yes</option>
                                            <option value='0'>no</option>
                                        </select>
                                        {/* <input ref={cushionTiesEditRef} type="number" defaultValue={modalCushionEditTies} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" /> */}
                                    </div>
                                    <div className=''>
                                        <div className='font-bold'>COM YDG</div>
                                        <input ref={cushionCOMYDGEditRef} type="number" defaultValue={modalCushionEditCOMYDG} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                </div>
                            </div>
                            <div className='pt-10'>
                                <Button size='sm' text='UPDATE CUSHION' onClick={() => editCushion()} disabled={isSumbitDisabled} />
                            </div>
                        </div>
                    </>
                )}

                {modalType === 'addCushion' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Add Cushion</h2>
                        <div className='space-y-6'>
                            <div className='space-y-6 w-full'>
                                <div className=''>
                                    <div className='font-bold'>Name</div>
                                    <input ref={cushionNameRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Info</div>
                                    <input ref={cushionInfoRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className='grid grid-cols-1 lg:grid-cols-3 lg:gap-5'>
                                    <div className=''>
                                        <div className='font-bold'>Frame</div>
                                        <input ref={cushionFrameRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                    <div className=''>
                                        <div className='font-bold'>Style Seat</div>
                                        <input ref={cushionStyleSeatRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                    <div className=''>
                                        <div className='font-bold'>Style Back</div>
                                        <input ref={cushionStyleBackRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 lg:grid-cols-3 lg:gap-5'>
                                    <div className=''>
                                        <div className='font-bold'>Welt</div>
                                        <input ref={cushionWeltRef} type="number" defaultValue='0' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                    <div className=''>
                                        <div className='font-bold'>Ties</div>
                                        <input ref={cushionTiesRef} type="number" defaultValue='0' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                    <div className=''>
                                        <div className='font-bold'>COM YDG</div>
                                        <input ref={cushionCOMYDGRef} type="number" defaultValue='0' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                </div>
                            </div>
                            <div className='pt-10'>
                                <Button size='sm' text='ADD CUSHION' onClick={() => addCushion()} disabled={isSumbitDisabled} />
                            </div>
                        </div>
                    </>
                )}

                {modalType === 'editDealer' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Edit Dealer</h2>
                        <div className='space-y-6'>
                            <div className='space-y-6 w-full'>
                                <div className=''>
                                    <div className='font-bold'>Name</div>
                                    <input ref={dealerNameEditRef} type="text" defaultValue={modalDealerEditName} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Address</div>
                                    <input ref={dealerAddressEditRef} type="text" defaultValue={modalDealerEditAddress} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-5'>
                                    <div className=''>
                                        <div className='font-bold'>City</div>
                                        <input ref={dealerCityEditRef} type="text" defaultValue={modalDealerEditCity} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                    <div className=''>
                                        <div className='font-bold'>State</div>
                                        <select ref={dealerStateEditRef} value={modalDealerEditState} onChange={(e) => setModalDealerEditState(e.target.value)} className='bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                            <option value=''>No State</option>
                                            <option disabled className='text-gray-300'>-------------------------------------</option>
                                            {stateOptions.map((item, index) => (
                                                <option key={generateRandomKey()} value={item.value}>{item.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-5'>
                                    <div className=''>
                                        <div className='font-bold'>Zip Code</div>
                                        <input ref={dealerZipEditRef} type="text" defaultValue={modalDealerEditZip} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                    <div className=''>
                                        <div className='font-bold'>Country</div>
                                        <input ref={dealerCountryEditRef} type="text" defaultValue={modalDealerEditCountry} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 lg:grid-cols-3 lg:gap-5'>
                                    <div className=''>
                                        <div className='font-bold'>URL</div>
                                        <input ref={dealerURLEditRef} type="text" defaultValue={modalDealerEditUrl} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                    <div className=''>
                                        <div className='font-bold'>Email</div>
                                        <input ref={dealerEmailEditRef} type="text" defaultValue={modalDealerEditEmail} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                    <div className=''>
                                        <div className='font-bold'>Phone</div>
                                        <input ref={dealerPhoneEditRef} type="text" defaultValue={modalDealerEditPhone} placeholder='123-456-7890' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                </div>
                            </div>
                            <div className='pt-10'>
                                <Button size='sm' text='UPDATE DEALER' onClick={() => editDealer()} disabled={isSumbitDisabled} />
                            </div>
                        </div>
                    </>
                )}

                {modalType === 'addDealer' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Add Dealer</h2>
                        <div className='space-y-6'>
                            <div className='space-y-6 w-full'>
                                <div className=''>
                                    <div className='font-bold'>Name</div>
                                    <input ref={dealerNameRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Address</div>
                                    <input ref={dealerAddressRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-5'>
                                    <div className=''>
                                        <div className='font-bold'>City</div>
                                        <input ref={dealerCityRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                    <div className=''>
                                        <div className='font-bold'>State</div>
                                        <select ref={dealerStateRef} value={modalState} onChange={(e) => setModalState(e.target.value)} className='bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                            <option value=''>No State</option>
                                            <option disabled className='text-gray-300'>-------------------------------------</option>
                                            {stateOptions.map((item, index) => (
                                                <option key={generateRandomKey()} value={item.value}>{item.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-5'>
                                    <div className=''>
                                        <div className='font-bold'>Zip Code</div>
                                        <input ref={dealerZipRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                    <div className=''>
                                        <div className='font-bold'>Country</div>
                                        <input ref={dealerCountryRef} type="text" defaultValue='United States' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 lg:grid-cols-3 lg:gap-5'>
                                    <div className=''>
                                        <div className='font-bold'>URL</div>
                                        <input ref={dealerURLRef} type="text" defaultValue='https://' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                    <div className=''>
                                        <div className='font-bold'>Email</div>
                                        <input ref={dealerEmailRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                    <div className=''>
                                        <div className='font-bold'>Phone</div>
                                        <input ref={dealerPhoneRef} type="text" defaultValue='' placeholder='123-456-7890' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                </div>
                                <div className=''>
                                    <div className='flex items-center space-x-3'>
                                        <div className='font-bold'>Showroom?</div>
                                        <div>
                                            <input ref={showroomRef} type="checkbox" defaultChecked='' className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-10'>
                                <Button size='sm' text='ADD DEALER' onClick={() => addDealer()} disabled={isSumbitDisabled} />
                            </div>
                        </div>
                    </>
                )}

                {modalType === 'deleteDealer' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete Dealer</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Are you sure you want to delete this dealer?</div>
                                <input type="text" defaultValue={modalName} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" disabled />
                            </div>

                            <Button size='sm' text='DELETE' onClick={() => deleteDealer()} />

                        </div>
                    </>
                )}

            </Modal>

        </>
    );
}

export default DataLibrary;

import { useState, useEffect } from 'react';
import Slider from "react-slick";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";
import generateRandomKey from '../../views/components/GenerateRandomKey';
import { staticUrl } from "../../config";

const ProductsCarousel = ({ products, colors }) => {
    const [isMounted, setIsMounted] = useState(false);

    let slidesToShow = 1;

    if ( products.length > 3 ) {
        slidesToShow = 3;
    } else if ( products.length > 2 ) {
        slidesToShow = 2;
    } else if ( products.length > 1 ) {
        slidesToShow = 1;
    }
 
    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
        };
    }, []);

    const NextArrow = (props) => {
        const { onClick } = props;
        return (
            <button className="text-kb-400/30 hover:text-kb-400 text-2xl xl:text-5xl focus:outline-none absolute -top-12 bottom-0 -right-10 xl:-right-16 z-10 mt-auto mb-auto" onClick={onClick}>
                <HiChevronRight />
            </button>
        );
    };
      
    const PrevArrow = (props) => {
        const { onClick } = props;
        return (
            <button className="text-kb-400/30 hover:text-kb-400 text-2xl xl:text-5xl focus:outline-none absolute -top-12 bottom-0 -left-10 xl:-left-16 z-10 mt-auto mb-auto" onClick={onClick}>
                <HiChevronLeft />
            </button>
        );
    };

    const settings = {
        dots: false,
        fade: false,
        infinite: true,
        draggable: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 8000,
        waitForAnimate: true,
        className: "relative", // Add relative positioning to the parent container
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    let maxWidth = '1600px';

    if ( slidesToShow === 1 ) {
        maxWidth = '540px';
    } else if ( slidesToShow === 2 ) {
        maxWidth = '1080px';
    } else if ( slidesToShow >= 3 ) {
        maxWidth = '1640px';
    }

    return (
        <>
            { slidesToShow === 1 ? (
                <div className={`max-w-[${maxWidth}] px-12 mx-auto`}>
                    <div className='px-5'>
                        <div className='h-[20px]'></div>
                        <a href={`/products/${products[0]?.slug}`}>
                            <div className="bg-white relative" style={{ boxShadow: '0 0 25px rgba(112, 128, 144, 0.30)' }}>
                                { products[0]?.is_new ? (
                                    <div className="absolute top-6 right-6 bg-kb-400 text-white py-1 px-3 rounded items-center text-sm uppercase font-medium">New</div>
                                ) : (
                                    <></>
                                ) }

                                { products[0]?.relation_id ? (
                                    <img src={`${staticUrl}/img/media/products/product_${products[0]?.relation_id}.jpg`} alt="" />
                                ) : (
                                    <img src={`${staticUrl}/img/noimage.jpg`} alt="" />
                                ) }

                                { colors && (
                                    <div className="flex items-center space-x-3 p-6 pt-5">
                                        <div className="h-[32px] -ml-3"></div>
                                        { colors[`${products[0]?.product_id}`].slice(0, 3).map((color, index) => (
                                            <div key={index} className="w-[32px] h-[32px] bg-gray-200">
                                                <img src={`${staticUrl}/img/media/colors/thumbnail_${color}.jpg`} alt="" />
                                            </div>
                                        )) }
                                        
                                        { colors[`${products[0]?.product_id}`].length > 3 && (
                                            <span className="text-kb-400/70">+ More</span>
                                        ) }
                                    </div>
                                )}
                            </div>
                        </a>
                        <div className="flex w-full justify-center py-6">
                            <div className="text-center">
                                <div className="text-kb-400">
                                    {products[0]?.product_name}
                                </div>
                                <div className="text-kb-300 font-bold uppercase">
                                    {products[0]?.collection_name}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (

                <div className={`max-w-[${maxWidth}] px-12 mx-auto`}>
                    {isMounted && (
                        <Slider {...settings}>
                            {products?.map((product, index) => (
                                <div key={generateRandomKey()} className='px-5'>
                                    <div className='h-[20px]'></div>
                                    <a href={`/products/${product.slug}`}>
                                        <div className="bg-white relative" style={{ boxShadow: '0 0 25px rgba(112, 128, 144, 0.30)' }}>
                                            { product.is_new ? (
                                                <div className="absolute top-6 right-6 bg-kb-400 text-white py-1 px-3 rounded items-center text-sm uppercase font-medium">New</div>
                                            ) : (
                                                <></>
                                            ) }
                                
                                            { product.relation_id ? (
                                                <img src={`${staticUrl}/img/media/products/product_${product.relation_id}.jpg`} alt="" />
                                            ) : (
                                                <img src={`${staticUrl}/img/noimage.jpg`} alt="" />
                                            ) }
                                
                                            { colors && (
                                                <div className="flex items-center space-x-3 p-6 pt-5">
                                                    <div className="h-[32px] -ml-3"></div>
                                                    { colors[`${product.product_id}`].slice(0, 3).map((color, index) => (
                                                        <div key={index} className="w-[32px] h-[32px] bg-gray-200">
                                                            <img src={`${staticUrl}/img/media/colors/thumbnail_${color}.jpg`} alt="" />
                                                        </div>
                                                    )) }
                                                    
                                                    { colors[`${product.product_id}`].length > 3 && (
                                                        <span className="text-kb-400/70">+ More</span>
                                                    ) }
                                                </div>
                                            )}
                                        </div>
                                    </a>
                                    <div className="flex w-full justify-center py-6">
                                        <div className="text-center">
                                            <div className="text-kb-400">
                                                {product.product_name}
                                            </div>
                                            <div className="text-kb-300 font-bold uppercase">
                                                {product.collection_name}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    )}
                </div>
    
            ) }
        </>
    );
}

export default ProductsCarousel;

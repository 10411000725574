import Layout from "../../../layout/logged_out/Layout";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import generateRandomKey from "../../components/GenerateRandomKey";
import Loading from '../../components/Loading';
import { backendUrl, staticUrl } from "../../../config";

const TradeShows = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [resultsArray, setResultsArray] = useState([]);
    const [image, setImage] = useState('');
    
    useEffect(() => {

        const getTradeShows = async () => {

            try {

                setIsLoading(true);
    
                const response = await axios.get(`${backendUrl}/w/trade-shows`);
                setResultsArray(response.data.trade_shows)

                setIsLoading(false);
    
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }

        };

        getTradeShows();

        
        const getImage = async () => {

            const response = await axios.get(`${backendUrl}/w/content/trade-show`);
            setImage(response?.data?.image?.relation_id);

        };

        getImage();

    }, [])


    if ( isLoading ) {
        return <Loading />;
    }

    return ( 
        <Layout title='Trade Shows'>

            <div className="mt-[80px] xl:mt-[96px] mx-auto">
                <div className="flex w-full">
                    <div className="hidden xl:block p-8">
                        <img src={`${staticUrl}/img/media/content/content_${image}.jpg`} className="w-[50vw] max-w-[790px]" alt="" />
                    </div>
                    <div className="px-4 py-4 md:py-8 md:px-8 w-full">
                        <h1 className="text-3xl md:text-5xl xl:text-6xl text-kb-400 pb-10 tracking-wide font-light">Trade Show Schedule</h1>
                        <div className="w-full space-y-4 text-kb-400 pb-4 xl:pb-8 leading-8">
                            <div className="space-y-10 w-full">

                                {resultsArray.length > 0 && (
                                    <div className="space-y-6">
                                        <div>Showing {resultsArray.length} events</div>
                                        <div className="w-full space-y-6">
                                            {resultsArray.map((event, index) => (
                                                <div key={generateRandomKey()} className={`space-y-1 pb-9`}>
                                                    <div className="text-kb-300 font-bold">{event.data_1}</div>
                                                    <div className="text-kb-300 font-extrabold">{event.name}</div>
                                                    <div className="text-sm">{event.data_2}</div>
                                                    <div className="text-sm">{event.data_3}</div>
                                                    <div className="text-sm">{event.data_4}</div>
                                                    <div className="text-sm">{event.data_5}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="xl:hidden">
                <img src={`${staticUrl}/img/media/content/content_${image}.jpg`} className="w-full p-4 md:p-8" alt="" />
            </div>

        </Layout>
    );
}
 
export default TradeShows;

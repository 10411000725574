import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import { staticUrl } from "../../../config";


const VerticalSlider = (props) => {
    const sliderRef = useRef(null);
    
    const settings = {
        dots: false,
        infinite: true,
        vertical: false,
        arrows: false,
        slidesToShow: 1
    };

    const goToNext = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const goToPrev = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    // Handle image click event
    const handleImageClick = (imageUrl, imageDesc) => {
        props.onImageClick(imageUrl, imageDesc);
    };

    // Generate Thumbnails
    const [images, setImages] = useState([]);

    useEffect(() => {
        const _images = props.images.map((item, index) => ({ thumbnail: `${staticUrl}/img/media/fabrics/thumbnail_${item}.jpg`, url: `${staticUrl}/img/media/fabrics/fabric_${item}.jpg`, description: props.descriptions[index] }));
        setImages(_images);
    }, [props.images]);

    return (
        <div className="flex items-center justify-center">
            <div>
                <i className="fas fa-angle-left text-2xl xl:text-4xl cursor-pointer text-kb-400/50 hover:text-kb-300" onClick={goToPrev}></i>
            </div>
            <div className={`w-[200px] kb-sm:max-w-[450px] px-4`}>
                <Slider ref={sliderRef} {...settings}>
                    {images.map((image, index) => (
                        <div key={index} className="p-4 py-6 cursor-pointer outline-none" onClick={() => handleImageClick(image.url, image.description)}>
                            <img src={image.thumbnail} className="" 
                                style={{
                                    boxShadow: '0 0 18px rgba(112, 128, 144, 0.25)'
                                }}
                                alt={`${image.description}`}
                            />
                        </div>
                    ))}
                </Slider>
            </div>
            <div>
                <i className="fas fa-angle-right text-2xl xl:text-4xl cursor-pointer text-kb-400/50 hover:text-kb-300" onClick={goToNext}></i>
            </div>
        </div>
    );
};

export default VerticalSlider;



/**
 * Validated
 */

import { Link } from "react-router-dom";
import { Logo } from "../../components/Logo";
import Title from '../../components/Title';

const NotFound = () => {
    return (
        <section>
            <Title set="404" />
            <div className="w-full py-16 px-6">
                <div>
                    <h1 className="flex md:block mb-4 tracking-tight font-extrabold text-9xl text-kb-300 justify-center">404</h1>
                    <p className="mb-7 md:text-lg text-gray-500 text-center md:text-left">The site you were looking for couldn't be found.</p>
                    <div className="border-t border-gray-400 py-6 flex md:block justify-center">
                        <Link to="/login">
                            <Logo size="medium" />
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default NotFound;
import React, { forwardRef } from 'react';

const Select = forwardRef(({ options, selectedOption, onChange }, ref) => {
    return (
        <select ref={ref} value={selectedOption} onChange={onChange} className='bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300'>
            {options.map((option, index) => (
                <option key={index} value={option.id}>{option.name}</option>
            ))}
        </select>
    );
});

export default Select;
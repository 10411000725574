import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Logo } from "../../components/Logo";
import AuthCTA from '../../components/AuthCTA';
import Title from '../../components/Title';
import axios from '../../../api/axios';
import { backendUrl } from "../../../config";

const Confirm = () => {

    const { id } = useParams();

    const [username, setUsername] = useState('');
    const [fl, setFL] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [showLoading, setShowLoading] = useState(true);

    const fetchData = async () => {
        try {
            // Get activation status
            const response = await axios.get(`${backendUrl}/w/confirm/${id}`);
            if (response?.status === 200) {
                if ( typeof response.data.username === 'string' && response.data.username.length > 0 ) {
                    setShowLoading(false);
                    setShowSuccess(true);
                    setUsername(response.data.username);
                    setFL(response.data.username.substring(0, 1));
                }
            }
        } catch (error) {
            // Invalid or expired key
            setShowSuccess(false);
            setShowLoading(false);
        }
    };

    useEffect(() => {
        fetchData(); // Call the async function here
    }, []);


    return ( 
        <section className="select-none">
            
            <Title set="Account Recovery" />

            { showSuccess && ( 
                <div className="grid lg:h-screen lg:grid-cols-2">
                    <AuthCTA />
                    <div className="flex items-center justify-center px-4 py-6 lg:py-0 sm:px-0">
                        <form className="w-full max-w-md space-y-6 md:space-y-8 xl:max-w-xl">
                            <div>
                                <div className="flex justify-center items-center text-2xl font-semibold text-white my-8">
                                    <Link to="/"><Logo size="medium" /></Link>
                                </div>
                                <div className="flex justify-center items-center text-2xl font-bold text-gray-900 my-4 uppercase">Account Created</div>
                                <p className="flex justify-center items-center text-sm text-gray-500">Thank you for confirming your email address! Please, sign in!</p>
                            </div>
                            <div className="flex justify-center">
                                <div className="flex items-center text-sm p-2 rounded-full border border-gray-300 bg-gray-50">
                                    <div className="flex items-center justify-center uppercase rounded-full bg-gray-300 mr-2 w-[32px] h-[32px] pl-[1px] pt-[1px]">
                                        <div className="font-extrabold text-lg text-gray-900">{fl}</div>
                                    </div>
                                    <div className="text-gray-900 mr-1">{username}</div>
                                </div>
                            </div>
                            <div className="flex items-center justify-center">
                                <Link to="/login" className="text-sm font-semibold text-kb-300 hover:underline ml-1">Return to Log In</Link>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            { !showSuccess && !showLoading && ( 
                <div className="grid lg:h-screen lg:grid-cols-2">
                    <AuthCTA />
                    <div className="flex items-center justify-center px-4 py-6 lg:py-0 sm:px-0">
                        <form className="w-full max-w-md space-y-6 md:space-y-8 xl:max-w-xl">
                            <div>
                                <div className="flex justify-center items-center text-2xl font-semibold text-white my-8">
                                    <Link to="/"><Logo size="medium" /></Link>
                                </div>
                                <div className="flex justify-center items-center text-2xl font-bold text-gray-900 my-4 uppercase">Invalid Activation Key</div>
                                <p className="flex justify-center items-center text-sm text-gray-500">Your activation key is either not valid or has expired.</p>
                            </div>
                            <div className="flex items-center justify-center">
                                <Link to="/login" className="text-sm font-semibold text-kb-300 hover:underline ml-1">Return to Log In</Link>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            { showLoading && ( 
                <div className="grid lg:h-screen lg:grid-cols-2">
                    <AuthCTA />
                    <div className="flex items-center justify-center px-4 py-6 lg:py-0 sm:px-0">
                        <form className="w-full max-w-md space-y-6 md:space-y-8 xl:max-w-xl">
                            <div>
                                <div className="flex justify-center items-center text-2xl font-semibold text-white my-8">
                                    <Link to="/"><Logo size="medium" /></Link>
                                </div>
                                <div className="flex justify-center items-center text-2xl font-bold text-gray-900 my-4 uppercase">Checking Activation Key</div>
                                <p className="flex justify-center items-center text-sm text-gray-500">Processing your request, please wait.</p>
                            </div>
                            <div className="flex items-center justify-center">
                                <Link to="/login" className="text-sm font-semibold text-kb-300 hover:underline ml-1">Return to Log In</Link>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            
        </section>
    );
}

export default Confirm;

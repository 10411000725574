import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

const Toast = () => {
    const [toasts, setToasts] = useState([]);

    const addToast = (code) => {
        const [toastData] = window.ErrorCodes.filter((item) => item.code === code);
        const type = toastData.type;
        const title = toastData.title;
        const message = toastData.message;

        const id = uuidv4(); // Generate a unique ID
        setToasts((prevToasts) => [...prevToasts, { id, type, title, message }]);
        setTimeout(() => removeToast(id), 5000);
    };

    const removeToast = (id) => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };

    useEffect(() => {
        window.showToast = addToast;
    }, []);

    return (
        <div className="fixed top-0 right-0 m-4 space-y-4 z-50">
        {toasts.map((toast) => (
            <div key={toast.id} className={`flex items-center w-full max-w-xs p-4 text-gray-500 rounded shadow ${ toast.type === 'success' ? 'bg-emerald-700' : 'bg-red-700' }`} role="alert">
                {toast.type === 'success' && (
                    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg">
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                        </svg>
                        <span className="sr-only">Check icon</span>
                    </div>
                )}
                {toast.type === 'error' && (
                    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg">
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
                        </svg>
                        <span className="sr-only">Error icon</span>
                    </div>
                )}
                <div className='w-full'>
                    <div className="mx-3 text-sm font-bold text-white">{toast.title}</div>
                    <div className="mx-3 text-sm font-normal text-white">{toast.message}</div>
                </div>
            </div>
        ))}
        </div>
    );
};

export default Toast;

import React, { useState } from 'react';

const SlugInput = React.forwardRef(({ value, onChange }, ref) => {
    const [internalValue, setInternalValue] = useState(value);

    const handleKeyDown = (e) => {
        if ((e.key === '-' && internalValue.endsWith('-')) || (e.key === '-' && internalValue.length === 0)) {
            e.preventDefault();
        }
    };

    const handleKeyPress = (e) => {
        const regex = /^[a-z0-9-]$/;
        if (!regex.test(e.key)) {
            e.preventDefault();
        }
    };

    const handleChange = (e) => {
        const newValue = e.target.value;
        setInternalValue(newValue);
        onChange(newValue);
    };

    return (
        <input
            ref={ref}
            type="text"
            value={internalValue}
            onKeyDown={handleKeyDown}
            onKeyPress={handleKeyPress}
            onChange={handleChange}
            className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full"
        />
    );
});

export default SlugInput;

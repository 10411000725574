import { Outlet, Route, Routes, useNavigate, useParams } from "react-router-dom";
import Layout from "../../../layout/logged_out/Layout";
import { useEffect, useState } from "react";
import axios from "axios";
import generateRandomKey from "../../components/GenerateRandomKey";
import ProductCategoriesItem from "./components/ProductCategoriesItem";
import ProductSubcategories from "./ProductSubcategories";
import Loading from '../../components/Loading';
import NotFound from '../../../views/pages/error/404';
import { backendUrl } from "../../../config";
import ProductFilter from "./components/ProductFilter";

const ProductCategories = () => {

    const { '*': collectionId } = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [showMainCategory, setShowMainCategory] = useState(false);
    const [products, setProducts] = useState([]);
    const [colors, setColors] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    const [groupedByCollection, setGroupedByCollection] = useState([]);

    const filter = async (category_slug) => {
        try {

            setIsLoading(true);

            let data = {
                category_slug: category_slug
            };

            if ( category_slug === 1 ) {
                data = {
                    show_new: 'show_new'
                };
            }

            const response = await axios.post(`${backendUrl}/w/filter`, data);

            setProducts(response.data.products);
            setColors(response.data.colors);
            setCategoryName((response.data.category && response.data.category.name) ? response.data.category.name : '');

            // Group products by 'collection_name'
            const _groupedByCollection = response.data.products.reduce((acc, product) => {
                const collectionName = product.collection_name;

                if (!acc[collectionName]) {
                    acc[collectionName] = [];
                }

                acc[collectionName].push(product);
                return acc;
            }, {});

            setGroupedByCollection(_groupedByCollection);
            setIsLoading(false);
            
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {

        if ( collectionId.split('/').length === 1 && collectionId.split('/')[0].match(/[a-z]+/) ) {

            if ( collectionId.split('/')[0] === 'new' ) {
                filter(1);
                setShowNew(true);
                setShowMainCategory(false);
            } else {
                filter(collectionId.split('/')[0]);
                setShowNew(false);
                setShowMainCategory(true);
            }

        } else if ( collectionId.split('/').length === 2 ) {
            setShowNew(false);
            setShowMainCategory(false);
        } else {
            navigate('/');
        }

    }, [navigate]);






    if ( isLoading ) {
        return <Loading />;
    }


    

    return ( 
        <>
            <Layout title='Products'>
                <main className='mt-[81px] kb-menu:mt-[97px] text-lg tracking-wider'>

                    {!showMainCategory && (
                        <>
                            <Routes>
                                <Route path=":mainCategoryId/:subCategoryId" element={<ProductSubcategories />} />
                            </Routes>
                            <Outlet />
                        </>
                    )}

                    {showNew && (
                        <div className='max-w-screen-2xl mx-auto py-8 px-4 md:px-8 kb-xl:px-0'>
                            <div>
                                <h2 className="flex justify-center xl:justify-start text-3xl md:text-5xl xl:text-6xl text-kb-400 tracking-wide font-light pb-7">New</h2>

                                { products.length === 0 ? (
                                    <>
                                        <div className="text-kb-400 py-7 text-center kb-menu:text-left">Showing 0 of 0</div>
                                        <div className="w-full flex justify-center text-kb-400 py-10">
                                            This category is empty.
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="text-kb-400 py-7">Showing 1-{products.length} of {products.length}</div>

                                        {Object.entries(groupedByCollection).map(([collectionName, products]) => (
                                            <div key={generateRandomKey()}>
                                                <h2 className="flex justify-center xl:justify-start text-xl md:text-3xl xl:text-4xl text-kb-300 tracking-wide pb-7 uppercase">{collectionName}</h2>
                                                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-14">
                                                    {products.map((product, index) => (
                                                        <a key={generateRandomKey()} href={`/products/${product.slug}`}>
                                                            <ProductCategoriesItem product={product} colors={colors} />
                                                        </a>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                        
                                    </>
                                ) }

                            </div>
                        </div>
                    )}


                    {showMainCategory && (
                        <div className='max-w-screen-2xl mx-auto py-8 px-4 md:px-8 kb-xl:px-0'>
                            <div>
                                { products.length > 0 ? (
                                    <>
                                        <h2 className="flex justify-center xl:justify-start text-3xl md:text-5xl xl:text-6xl text-kb-400 tracking-wide font-light pb-7">{categoryName}</h2>
                                        <ProductFilter products={products} colors={colors} slug={collectionId.split('/')[0]} key={collectionId.split('/')[0]} />
                                    </>
                                ) : (
                                    <NotFound />
                                ) }

                            </div>
                        </div>
                    )}

                </main>
            </Layout>
        </>
    );
}
 
export default ProductCategories;
import { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { validate_first_name, validate_last_name, validate_email, validate_password, validate_generic_text, validate_country } from '../../../lib/validate';
import Layout from "../../../layout/logged_out/Layout";
import axios from '../../../api/axios';
import { backendUrl, staticUrl } from "../../../config";
import generateRandomKey from "../../components/GenerateRandomKey";
import { useMegaMenu } from "../../../lib/MegaMenuContext";

const ApplyMediaLibrary = () => {

    const { megaMenuData } = useMegaMenu();
    const [fl, setFL] = useState('');
    const [inputType, setInputType] = useState('password');
    const [showSuccess, setShowSuccess] = useState(false);
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const [passwordSwitch, setPasswordSwitch] = useState('Show');
    const [showOther, setShowOther] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState('');
    const [image, setImage] = useState('');

    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const companyRef = useRef(null);
    const addressRef = useRef(null);
    const cityRef = useRef(null);
    const stateRef = useRef(null);
    const zipRef = useRef(null);
    const phoneRef = useRef(null);
    const faxRef = useRef(null);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const otherRef = useRef(null);
    const affiliationRef = useRef(null);
    const formRef = useRef(null);
    const countryRef = useRef(null);
    
    useEffect(() => {

        const getCountries = async () => {

            try {
        
                const response = await axios.get(`/data/countries.json`);
                setCountries(response?.data);
                

            } catch ( error ) {

                console.log(error);

                if ( !error?.response ) {
                    window.showToast('WCL19900');
                }
                
            }
        };

        getCountries();

        const getImage = async () => {

            const response = await axios.get(`${backendUrl}/w/content/media-library`);
            setImage(response?.data?.image?.relation_id);
    
        };

        getImage();

    }, []);
    

    const handleCountryChange = (e) => {
        setCountry(e.target.value);
    };

    const handleOther = () => {

        if ( affiliationRef?.current?.value === '3' ) {
            setShowOther(true);
        } else {
            setShowOther(false);
        }

    };

    const handlePasswordSwitch = () => {
        if ( passwordSwitch === 'Show' ) {
            setPasswordSwitch('Hide');
            setInputType('text');
        } else {
            setPasswordSwitch('Show');
            setInputType('password');
        }
    };

    // reCAPTCHA
    const recaptchaRef = useRef();
    const executeReCaptcha = () => {
        return new Promise((resolve, reject) => {
            recaptchaRef.current.execute("submit_form")
            .then((token) => {
                resolve(token);
            })
            .catch((error) => {
                reject(error);
            });
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsFormDisabled(true);

        const isFirstNameValid = validate_first_name(firstNameRef?.current?.value);
        const isLastNameValid = validate_last_name(lastNameRef?.current?.value);
        const isUsernameValid = validate_email(emailRef?.current?.value);
        const isPasswordValid = validate_password(passwordRef?.current?.value);
        const isCompanyNameValid = validate_generic_text(companyRef?.current?.value);
        const isAddressValid = validate_generic_text(addressRef?.current?.value);
        const isCityValid = validate_generic_text(cityRef?.current?.value);
        const isStateValid = validate_generic_text(stateRef?.current?.value);
        const isZipValid = validate_generic_text(zipRef?.current?.value);
        const isPAValid = affiliationRef?.current?.value !== '';
        const isCountryValid = validate_country(countryRef?.current?.value)
        
        let isOtherValid = true;

        if ( affiliationRef?.current?.value === '3' ) {
            isOtherValid = validate_generic_text(otherRef?.current?.value);
        }


        if ( isFirstNameValid && isLastNameValid && isUsernameValid && isPasswordValid && isCompanyNameValid && isCityValid && isPAValid && isAddressValid && isOtherValid && isCountryValid ) {

            try {
            
                // Get reCAPTCHA token
                const token = await executeReCaptcha();

                const data = {
                    token: token,
                    first_name: firstNameRef?.current?.value,
                    last_name: lastNameRef?.current?.value,
                    username: emailRef?.current?.value,
                    password: passwordRef?.current?.value,
                    company: companyRef?.current?.value,
                    city: cityRef?.current?.value,
                    address: addressRef?.current?.value,
                    state: stateRef?.current?.value,
                    zip: zipRef?.current?.value,
                    phone: phoneRef?.current?.value,
                    fax: faxRef?.current?.value,
                    affiliation: ( affiliationRef?.current?.value === '3' ) ? otherRef?.current?.value : affiliationRef?.current?.value,
                    country: countryRef?.current?.value,
                };

                try {
        
                    // Get sign-up status
                    const response = await axios.post(`${backendUrl}/w/sign-up`, data);
                    
                    if (response?.status === 200) {
                        setEmailAddress(emailRef?.current?.value);
                        setFL(emailRef?.current?.value?.substring(0, 1));
                        formRef.current.reset();
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        setShowSuccess(true);
                    }

                    setTimeout(() => {
                        setIsFormDisabled(false);
                        setShowSuccess(false);
                        setShowOther(false);
                        setEmailAddress('');
                        setFL('');
                    }, 5000);

                } catch ( error ) {

                    console.log(error);

                    if ( !error?.response ) {
                        window.showToast('WCL19900');
                    } else if ( error?.response?.data?.code ) {
                        window.showToast(error?.response?.data?.code);
                    } else {
                        window.showToast('WCL19904');
                    }

                    setTimeout(() => {
                        setIsFormDisabled(false);
                    }, 5000);
                    
                }                

            } catch ( error ) {
                window.showToast('WCL20398');
                return console.error('reCAPTCHA validation failed:', error);
            }

        } else {
            if ( !isFirstNameValid ) {
                window.showToast('WCL19905');
            } else if ( !isLastNameValid ) {
                window.showToast('WCL19906');
            } else if ( !isUsernameValid ) {
                window.showToast('WCL19907');
            } else if ( !isPasswordValid ) {
                window.showToast('WCL19908');
            } else if ( !isCompanyNameValid ) {
                window.showToast('WCL19909');
            } else if ( !isCityValid ) {
                window.showToast('WCL19910');
            } else if ( !isPAValid ) {
                window.showToast('WCL19913');
            } else if ( !isAddressValid ) {
                window.showToast('WCL19914');
            } else if ( !isOtherValid ) {
                window.showToast('WCL19915');
            } else if ( !isCountryValid ) {
                window.showToast('WCL20501');
            }
        }

    };

    return (
        <Layout title='Image Library Signup'>

            <div className="mt-[80px] xl:mt-[96px] max-w-screen-2xl mx-auto">
                <div className="xl:grid grid-cols-2 gap-16">
                    <div className="hidden xl:block p-8">
                        <img src={`${staticUrl}/img/media/content/content_${image}.jpg`} className="w-[50vw] max-w-[730px]" alt="" />
                    </div>
                    <div className="p-4 md:py-8">
                        <h1 className="text-3xl md:text-5xl xl:text-6xl text-kb-400 tracking-wide font-light">Image Library</h1>
                        <div className="w-full text-kb-400 pb-4 leading-8">

                            { showSuccess && (
                                <div className="">
                                    <div className="flex items-center px-4 py-6 lg:py-0 sm:px-0">
                                        <div className="w-full space-y-6 md:space-y-8">
                                            <div className="pt-12">
                                                <p className="flex items-center text-sm text-gray-500 text-center">Review in progress. Shortly, you will receive an email with instructions. Thank you for your patience.</p>
                                            </div>
                                            <div className="flex">
                                                <div className="flex items-center text-sm p-2 rounded-full border border-kb-400 bg-gray-50">
                                                    <div className="flex items-center justify-center uppercase rounded-full bg-gray-300 mr-2 w-[32px] h-[32px]">
                                                        <div className="font-extrabold text-lg text-gray-900">{fl}</div>
                                                    </div>
                                                    <div className="text-gray-900 mr-1">{emailAddress}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) }

                            { !showSuccess && (
                                <div className="">
                                    <div className="flex items-center justify-center sm:px-0">
                                        <form className="w-full space-y-6" onSubmit={handleSubmit} disabled={isFormDisabled} ref={formRef}>
                                            { process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY && ( <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY} size="invisible" ref={recaptchaRef} /> )}
                                            <div>
                                                <p className="flex items-center text-sm pb-6">
                                                    Already have an account? <Link to="/login" className="font-semibold text-kb-300 hover:underline ml-1">Log in</Link>
                                                </p>
                                                <p className="flex items-center text-sm">
                                                    To apply to access the Kingsley Bate Image Library, please complete the form below. You will be contacted directly once your information has been reviewed.
                                                </p>
                                            </div>
                                            <div className="space-y-4">
                                                <div className="flex gap gap-x-3">
                                                    <div className="flex w-full">
                                                        <div className="w-full">
                                                            <label for="first_name">First Name *</label>
                                                            <input type="text" id="first_name" name="first_name" ref={firstNameRef} defaultValue='' className="bg-gray-50 border border-kb-400 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2" placeholder="First name" required="" />
                                                        </div>
                                                    </div>
                                                    <div className="flex w-full">
                                                        <div className="w-full">
                                                            <label for="last_name">Last Name *</label>
                                                            <input type="text" id="last_name" name="last_name" ref={lastNameRef} defaultValue='' className="bg-gray-50 border border-kb-400 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-500 block w-full py-2" placeholder="Last name" required="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>
                                                        <label for="company">Company Name *</label>
                                                        <input type="text" id="company" name="company" ref={companyRef} defaultValue='' className="bg-gray-50 border border-kb-400 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2" placeholder="Company Name" required="" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>
                                                        <label for="address">Address *</label>
                                                        <input type="text" id="address" name="address" ref={addressRef} defaultValue='' className="bg-gray-50 border border-kb-400 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2" placeholder="Mailing Address" required="" />
                                                    </div>
                                                </div>
                                                <div className="md:flex space-y-4 md:space-y-0 gap gap-x-3">
                                                    <div className="w-full">
                                                        <label for="city">City *</label>
                                                        <input type="text" id="city" name="city" ref={cityRef} defaultValue='' className="bg-gray-50 border border-kb-400 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2" placeholder="City" required="" />
                                                    </div>
                                                    <div className="w-full">
                                                        <label for="state">State</label>
                                                        <select id="state" name="state" ref={stateRef} defaultValue='' className="bg-gray-50 border border-kb-400 text-gray-600 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2">
                                                            <option defaultValue>State</option>
                                                            <option value="AL">ALABAMA</option>
                                                            <option value="AK">ALASKA</option>
                                                            <option value="AS">AMERICAN SAMOA</option>
                                                            <option value="AZ">ARIZONA</option>
                                                            <option value="AR">ARKANSAS</option>
                                                            <option value="CA">CALIFORNIA</option>
                                                            <option value="CO">COLORADO</option>
                                                            <option value="CT">CONNECTICUT</option>
                                                            <option value="DE">DELAWARE</option>
                                                            <option value="DC">DISTRICT OF COLUMBIA</option>
                                                            <option value="FM">FEDERATED STATES OF MICRONESIA</option>
                                                            <option value="FL">FLORIDA</option>
                                                            <option value="GA">GEORGIA</option>
                                                            <option value="GU">GUAM</option>
                                                            <option value="HI">HAWAII</option>
                                                            <option value="ID">IDAHO</option>
                                                            <option value="IL">ILLINOIS</option>
                                                            <option value="IN">INDIANA</option>
                                                            <option value="IA">IOWA</option>
                                                            <option value="KS">KANSAS</option>
                                                            <option value="KY">KENTUCKY</option>
                                                            <option value="LA">LOUISIANA</option>
                                                            <option value="ME">MAINE</option>
                                                            <option value="MH">MARSHALL ISLANDS</option>
                                                            <option value="MD">MARYLAND</option>
                                                            <option value="MA">MASSACHUSETTS</option>
                                                            <option value="MI">MICHIGAN</option>
                                                            <option value="MN">MINNESOTA</option>
                                                            <option value="MS">MISSISSIPPI</option>
                                                            <option value="MO">MISSOURI</option>
                                                            <option value="MT">MONTANA</option>
                                                            <option value="NE">NEBRASKA</option>
                                                            <option value="NV">NEVADA</option>
                                                            <option value="NH">NEW HAMPSHIRE</option>
                                                            <option value="NJ">NEW JERSEY</option>
                                                            <option value="NM">NEW MEXICO</option>
                                                            <option value="NY">NEW YORK</option>
                                                            <option value="NC">NORTH CAROLINA</option>
                                                            <option value="ND">NORTH DAKOTA</option>
                                                            <option value="MP">NORTHERN MARIANA ISLANDS</option>
                                                            <option value="OH">OHIO</option>
                                                            <option value="OK">OKLAHOMA</option>
                                                            <option value="OR">OREGON</option>
                                                            <option value="PW">PALAU</option>
                                                            <option value="PA">PENNSYLVANIA</option>
                                                            <option value="PR">PUERTO RICO</option>
                                                            <option value="RI">RHODE ISLAND</option>
                                                            <option value="SC">SOUTH CAROLINA</option>
                                                            <option value="SD">SOUTH DAKOTA</option>
                                                            <option value="TN">TENNESSEE</option>
                                                            <option value="TX">TEXAS</option>
                                                            <option value="UT">UTAH</option>
                                                            <option value="VT">VERMONT</option>
                                                            <option value="VI">VIRGIN ISLANDS</option>
                                                            <option value="VA">VIRGINIA</option>
                                                            <option value="WA">WASHINGTON</option>
                                                            <option value="WV">WEST VIRGINIA</option>
                                                            <option value="WI">WISCONSIN</option>
                                                            <option value="WY">WYOMING</option>
                                                        </select>                                      
                                                    </div>
                                                    <div className="w-full">
                                                        <label for="zip">Postal Code</label>
                                                        <input type="text" id="zip" name="zip" ref={zipRef} defaultValue='' className="bg-gray-50 border border-kb-400 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2" placeholder="Postal Code" />
                                                    </div>
                                                </div>
                                                <div>
                                                <div>
                                                    <label for="country">Country *</label>
                                                    <select
                                                        ref={countryRef}
                                                        id="country"
                                                        name="country"
                                                        value={country}
                                                        onChange={handleCountryChange}
                                                        className={`bg-gray-50 border border-kb-400 text-gray-600 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2`}
                                                        required
                                                    >
                                                        <option value=''>Select Country</option>
                                                        <option disabled></option>
                                                        <option value='US'>United States</option>
                                                        <option disabled></option>
                                                        { countries?.map((country) => (
                                                            country.code !== 'US' && (
                                                                <option value={country.code} key={generateRandomKey()}>{country.name}</option>
                                                            )
                                                        )) }
                                                    </select>
                                                </div>
                                                </div>
                                                <div className="flex gap gap-x-3">
                                                    <div className="w-1/2">
                                                        <label for="phone">Phone *</label>
                                                        <input type="number" id="phone" name="phone" ref={phoneRef} defaultValue='' className="no-spinner bg-gray-50 border border-kb-400 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2" placeholder="Phone (eg. 123456789)" required="" />
                                                    </div>
                                                    <div className="w-1/2">
                                                        <label for="fax">Fax</label>
                                                        <input type="number" id="fax" name="fax" ref={faxRef} defaultValue='' className="no-spinner bg-gray-50 border border-kb-400 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2" placeholder="Fax (eg. 123456789)" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <label for="username">Username *</label>
                                                    <input type="email" id="username" name="username" ref={emailRef} defaultValue='' className="bg-gray-50 border border-kb-400 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2" placeholder="Email address" required="" />
                                                </div>
                                                <div>
                                                    <div>
                                                        <label for="password">Password *</label>
                                                        <input type={inputType} id="password" name="password" ref={passwordRef} autoComplete="new-password" className="bg-gray-50 border border-kb-400 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2" placeholder="Password" required="" />
                                                    </div>
                                                    <div className="flex justify-between space-x-8 items-center py-2">
                                                        <div className="text-sm text-kb-400">Password must be a minimum of 8 characters in length and include at least one uppercase letter, one lowercase letter, one digit, and one special character.</div>
                                                        <div onClick={(e) => { e.preventDefault(); handlePasswordSwitch(); }}><button className="text-sm font-medium text-kb-300 hover:underline">{passwordSwitch}</button></div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label for="affiliation">Professional Affiliation *</label>
                                                    <select id="affiliation" name="affiliation" ref={affiliationRef} onChange={handleOther} defaultValue='' className="bg-gray-50 border border-kb-400 text-gray-600 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2" required="">
                                                        <option value=''>Select Professional Affiliation</option>
                                                        <option value='Member of the Media'>Member of the Media</option>
                                                        <option value='Authorized Kingsley Bate Dealer'>Authorized Kingsley Bate Dealer</option>
                                                        <option value='3'>Other</option>
                                                    </select>
                                                    { showOther && (
                                                        <div className="py-4">
                                                            <input type='text' name="other" ref={otherRef} className="bg-gray-50 border border-kb-400 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2" placeholder="If other, please explain. (Required)" required="" />
                                                        </div>
                                                    ) }
                                                </div>
                                            </div>

                                            <p className="text-sm text-gray-500">
                                                By clicking 'Apply for an Account', I agree to Kingsley Bate's <a href={`${staticUrl}/documents/terms/terms_${megaMenuData?.terms}.pdf`} target='_blank' className="font-semibold text-kb-300 hover:underline">Terms &amp; Conditions</a> and <a href={`${staticUrl}/documents/privacy/privacy_${megaMenuData?.privacy}.pdf`} target='_blank' className="font-semibold text-kb-300 hover:underline">Privacy Policy</a>.
                                            </p>
                                            <button type="submit" className="w-full text-white bg-kb-300 hover:bg-kb-600 focus:ring-4 focus:outline-none focus:ring-kb-300 font-medium rounded text-sm px-5 text-center py-3.5 disabled:bg-kb-500 disabled:text-kb-400">Apply for an Account</button>
                                            <div className="flex items-center">
                                                <div className="w-full h-0.5 bg-gray-200"></div>
                                                <div className="px-5 text-center text-gray-500">or</div>
                                                <div className="w-full h-0.5 bg-gray-200"></div>
                                            </div>
                                            <div className="flex items-center justify-center">
                                                <Link to="/recovery" className="text-sm font-semibold text-kb-300 hover:underline">Forgot your password?</Link>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
            <div className="xl:hidden">
                <img src={`${staticUrl}/img/media/content/content_${image}.jpg`} className="w-full p-4 md:p-8" alt="" />
            </div>


        </Layout>

     );
}
 
export default ApplyMediaLibrary;
import axios from "axios";
import { useEffect, useState } from "react";
import generateRandomKey from "../../../components/GenerateRandomKey";
import { backendUrl, staticUrl } from "../../../../config";

const Colors = () => {

    const [content, setContent] = useState([]);

    const fetchContent = async () => {
        try {
            // setIsloading(true);
            const response = await axios.get(`${backendUrl}/w/image-library/colors`, { withCredentials: true });
            setContent(response?.data?.colors);
            // setIsloading(false);

        } catch (error) {
            console.log(error);
            // setIsloading(false);
        }
    };

    useEffect(() => {
        fetchContent();
    }, []);

    const handleClick = async (target) => {

        try {

            const response = await axios.get(`${backendUrl}/w/image-library/download/${target}`, { withCredentials: true, responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `original_${target}.jpg`);
            document.body.appendChild(link);
            link.click();

        } catch (error) {
            console.log(error);

        }

    };

    return (
        <>
            <div className="uppercase text-4xl pb-6">Colors</div>
            <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-10 gap-4">
                { content?.length > 0 && content?.map((color) => (
                    <div key={generateRandomKey()} className="text-center space-y-2 hover:cursor-pointer hover:text-kb-300" onClick={() => { handleClick(color.relation_id) }}>
                        <img src={`${staticUrl}/img/media/colors/color_${color.relation_id}.jpg`} alt="" className="border border-gray-200" />
                        <div>
                            {color?.name?.split('[break]').map((line, index) => (
                                <div className='text-xs text-center' key={index}>{line}</div>
                            ))}
                        </div>
                    </div>
                )) }
            </div>
        </>
    );

};

export default Colors;

const Set = (data) => {
    localStorage.setItem('user_data', JSON.stringify(data));
}

const Get = () => {
    return JSON.parse(localStorage.getItem('user_data'));
}

const Remove = () => {
    localStorage.removeItem('user_data');
}

export { Set, Get, Remove };
import React from 'react';
import generateRandomKey from '../../../components/GenerateRandomKey';

function TextParser(text) {

    if ( text ) {

        // Regular expression patterns
        const subheaderRegex = /\[subheader="(.*?)"\]/g;
        const linkRegex = /\[label="(.*?)"\s*url="(.*?)"\]/g;
        const boldRegex = /\[bold="(.*?)"\]/g;
        const breakRegex = /\[break\]/g;
        const newlineRegex = /\[nl\]/g;

        // Split the text into segments based on the specified patterns
        let segments = text?.split(subheaderRegex);

        // Iterate over each segment and apply formatting
        segments = segments?.map((segment, index) => {
            // Check if the segment matches the subheader pattern
            if (index % 2 === 1) {
                // Render subheader
                return <h2 key={generateRandomKey()} className='font-bold pb-4'>{segment}</h2>;
            }

            // Split segment based on bold pattern
            let boldParts = segment.split(boldRegex);
            boldParts = boldParts.map((part, index) => {
                // Check if the part matches the bold pattern
                if (index % 2 === 1) {
                    // Render bold text
                    return <span key={generateRandomKey()} className="font-bold">{part}</span>;
                }
                return part;
            });

            // Split boldParts based on link pattern
            let linkParts = [];
            boldParts.forEach(part => {
                if (typeof part === 'string') {
                    let tempParts = part.split(linkRegex);
                    tempParts.forEach((tempPart, tempIndex) => {
                        if (tempIndex % 3 === 0) {
                            linkParts.push(tempPart);
                        } else if (tempIndex % 3 === 1) {
                            // Render link with URL
                            linkParts.push(<a key={generateRandomKey()} href={tempParts[tempIndex + 1]} className='hover:text-kb-300 underline underline-offset-4'>{tempPart}</a>);
                        }
                    });
                } else {
                    linkParts.push(part);
                }
            });

            return linkParts;
        });

        // Flatten the segments array and replace [break] with <div>
        segments = segments?.flat().map((part, index) => {
            if (typeof part === 'string') {
                let parts = part.split(breakRegex);
                return parts.map((subPart, subIndex) => {
                    if (subIndex < parts.length - 1) {
                        return <React.Fragment key={generateRandomKey()}>{subPart}<div key={generateRandomKey()} className='block h-[20px] w-full'></div></React.Fragment>;
                    }
                    return subPart;
                });
            }
            return part;
        });

        let result = segments?.flat().map((part, index) => {
            if (typeof part === 'string') {
                let parts = part.split(newlineRegex);
                return parts.map((subPart, subIndex) => {
                    if (subIndex < parts.length - 1) {
                        return <React.Fragment key={generateRandomKey()}>{subPart}<div key={generateRandomKey()} className='block w-full'></div></React.Fragment>;
                    }
                    return subPart;
                });
            }
            return part;
        });

        return <div className="text-lg">{result}</div>;

    } else {
        return <div></div>;
    }

}

export default TextParser;

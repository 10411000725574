import { useState, useEffect } from 'react';
import Slider from "react-slick";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";
import generateRandomKey from '../../views/components/GenerateRandomKey';
import { staticUrl } from "../../config";

const ProductSlider = ({ images }) => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
        };
    }, []);

    const NextArrow = (props) => {
        const { onClick } = props;
        return (
            <button className="text-kb-400/30 hover:text-kb-400 text-2xl xl:text-5xl focus:outline-none absolute -top-12 bottom-0 -right-10 xl:-right-16 z-10 mt-auto mb-auto" onClick={onClick}>
                <HiChevronRight />
            </button>
        );
    };
      
    const PrevArrow = (props) => {
        const { onClick } = props;
        return (
            <button className="text-kb-400/30 hover:text-kb-400 text-2xl xl:text-5xl focus:outline-none absolute -top-12 bottom-0 -left-10 xl:-left-16 z-10 mt-auto mb-auto" onClick={onClick}>
                <HiChevronLeft />
            </button>
        );
    };

    const settings = {
        dots: false,
        fade: false,
        infinite: true,
        draggable: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 8000,
        waitForAnimate: true,
        pauseOnHover: false,
        className: "product-slider relative", // Add relative positioning to the parent container
        cssEase: 'linear',
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    return (
        <div>
            {isMounted && (
                <Slider {...settings}>
                    {images.map((image, index) => {

                        if ( image.relation_id ) {

                            if (image.slug) {
                                return (
                                    <a href={`/categories/${image.slug}`} key={generateRandomKey()}>
                                        <div className='relative'>
                                            <div className='p-3 mb-14'>
                                                <img src={`${staticUrl}/img/media/products/product_${image.relation_id}.jpg`} style={{
                                                    boxShadow: '0 0 18px rgba(0, 0, 0, 0.075)'
                                                }} />
                                            </div>
        
                                            <div className='absolute -bottom-14 left-0 right-0 uppercase tracking-widest font-light text-kb-400 text-center text-lg xl:text-2xl'>
                                                {image.title}
                                            </div>
                                        </div>
                                    </a>
                                );
                            } else {
                                return (
                                    <div className='relative' key={generateRandomKey()}>
                                        <div className='p-3 mb-14'>
                                            <img src={`${staticUrl}/img/media/products/product_${image.relation_id}.jpg`} style={{
                                                boxShadow: '0 0 18px rgba(0, 0, 0, 0.075)'
                                            }} />
                                        </div>
        
                                        <div className='absolute -bottom-14 left-0 right-0 uppercase tracking-widest font-light text-kb-400 text-center text-lg xl:text-2xl'>
                                            {image.title}
                                        </div>
                                    </div>
                                );
                            }

                        }                    
                    
                    })}
                </Slider>
            )}
        </div>
    );
}

export default ProductSlider;

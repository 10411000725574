

const Sidenav = ({ isOpen }) => {

    const translate = isOpen ? 'fixed top-0 md:top-[66px] left-0 w-64 h-screen transition-transform translate-x-0' : 'fixed top-0 md:top-[66px] left-0 w-64 h-screen transition-transform -translate-x-full';

    return (
        <>

            {(

            <aside className={translate} aria-label="Sidebar">
                <div className="overflow-y-auto py-5 px-3 h-full bg-white border-r border-gray-200 mt-[66px] md:mt-0">

                    <div className="pt-2 pl-2 mb-4 text-sm text-gray-500">
                        <h3>Media</h3>
                    </div>
                    <ul className="pl-2 space-y-4 pb-4 border-b border-gray-200">
                        <li>
                            <a href="/image-library" className="flex items-center text-base font-medium text-gray-900 rounded transition duration-75 hover:underline group">
                                <span className="w-4 h-4 rounded bg-red-600"></span>
                                <span className="ml-3">Media Image Library</span>
                            </a>
                        </li>
                    </ul>

                    <div className="pt-2 pl-2 mb-4 text-sm text-gray-500 mt-2">
                        <h3>Components</h3>
                    </div>
                    <ul className="pl-2 space-y-4 pb-4 border-b border-gray-200">
                        <li>
                            <a href="/admin/mega-menu" className="flex items-center text-base font-medium text-gray-900 rounded transition duration-75 hover:underline group">
                                <span className="w-4 h-4 rounded bg-blue-600"></span>
                                <span className="ml-3">Mega Menu</span>
                            </a>
                        </li>
                        <li>
                            <a href="/admin/pages/landing-page" className="flex items-center text-base font-medium text-gray-900 rounded transition duration-75 hover:underline group">
                                <span className="w-4 h-4 rounded bg-blue-600"></span>
                                <span className="ml-3">Pages</span>
                            </a>
                        </li>
                        <li>
                            <a href="/admin/products/list" className="flex items-center text-base font-medium text-gray-900 rounded transition duration-75 hover:underline group">
                                <span className="w-4 h-4 rounded bg-blue-600"></span>
                                <span className="ml-3">Content</span>
                            </a>
                        </li>
                        <li>
                            <a href="/admin/filters/products/groups" className="flex items-center text-base font-medium text-gray-900 rounded transition duration-75 hover:underline group">
                                <span className="w-4 h-4 rounded bg-blue-600"></span>
                                <span className="ml-3">Filters</span>
                            </a>
                        </li>
                    </ul>

                    <div className="pt-2 pl-2 mb-4 text-sm text-gray-500 mt-2">
                        <h3>Libraries</h3>
                    </div>
                    <ul className="pl-2 space-y-4 pb-4 border-b border-gray-200">
                        <li>
                            <a href="/admin/images/products" className="flex items-center text-base font-medium text-gray-900 rounded transition duration-75 hover:underline group">
                                <span className="w-4 h-4 rounded bg-blue-600"></span>
                                <span className="ml-3">Image Library</span>
                            </a>
                        </li>
                        <li>
                            <a href="/admin/documents/care" className="flex items-center text-base font-medium text-gray-900 rounded transition duration-75 hover:underline group">
                                <span className="w-4 h-4 rounded bg-blue-600"></span>
                                <span className="ml-3">Document Library</span>
                            </a>
                        </li>
                        <li>
                            <a href="/admin/data/dealers" className="flex items-center text-base font-medium text-gray-900 rounded transition duration-75 hover:underline group">
                                <span className="w-4 h-4 rounded bg-blue-600"></span>
                                <span className="ml-3">Data Library</span>
                            </a>
                        </li>
                    </ul>

                </div>

                <div className="hidden lg:block absolute -bottom-9 md:bottom-[30px] left-0 justify-center p-4 w-full z-20 border-r border-gray-200 bg-white">
                    {/* <ul className="pb-4 pl-2 mb-4 space-y-2 border-b border-gray-200">
                        <li>
                            <Link to="/settings" className="flex items-center text-sm text-gray-500 hover:text-gray-900 group">
                                <HiCog size={20} className="flex-shrink-0 w-4 h-4 text-gray-500 transition duration-75 group-hover:text-gray-900" />
                                <span className="ml-2">Settings</span>
                            </Link>
                        </li>
                        <li>
                            <a href="#" className="flex items-center text-sm text-gray-500 hover:text-gray-900">
                                <HiAnnotation size={20} className="flex-shrink-0 w-4 h-4 text-gray-500 transition duration-75 group-hover:text-gray-900" />
                                <span className="ml-2">Help & Getting Started</span>
                            </a>
                        </li>
                    </ul> */}
                </div>
            </aside>

            )}

        </>
    );
}
 
export default Sidenav;
const Loading = () => {
    return (
        <>
            <div className="flex w-full h-screen items-center justify-center bg-blue-5">
                <div className="px-3 py-1 text-xs font-medium leading-none text-center text-blue-800 bg-blue-200 rounded animate-pulse">Loading...</div>
            </div>
        </>
     );
}
 
export default Loading;
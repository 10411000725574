import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Session from '../../../../api/session';
import Title from '../../../components/Title';
import Navbar from '../../../../layout/logged_in/Navbar';
import Sidenav from '../../../../layout/logged_in/Sidenav';
import Loading from '../../../components/Loading';
import MegaMenuItems from '../../../components/MegaMenu/MegaMenuItems';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import Select from '../../../components/elements/Select';
import SlugInput from '../../../components/SlugInput';
import generateRandomKey from '../../../components/GenerateRandomKey';
import { backendUrl } from '../../../../config';

const MegaMenu = () => {

    // Misc
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    // Select
    const [selectedOption, setSelectedOption] = useState('');

    // Modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const [modalMainID, setModalMainID] = useState(false);
    const [modalSubID, setModalSubID] = useState(false);
    const [modalMainName, setModalMainName] = useState(false);
    const [modalSubName, setModalSubName] = useState(false);

    // Refs
    const modalMainNewNameRef = useRef(null);
    const modalMainEditNameRef = useRef(null);
    const checkboxSubNewRef = useRef(null);
    const modalSubNewNameRef = useRef(null);
    const modalSubEditNameRef = useRef(null);
    const selectSubNewRef = useRef(null);
    const newCategoryImageRef = useRef(null);
    const editCategoryImageRef = useRef(null);

    const modalMainNewSlugRef = useRef(null);
    const modalSubNewSlugRef = useRef(null);
    const modalSubEditSlugRef = useRef(null);
    const modalMainEditSlugRef = useRef(null);

    const [editCategoryImage, setEditCategoryImage]  = useState('');
    const [newCategoryImage, setNewCategoryImage] = useState('');
    const [images, setImages] = useState([]);

    const [slugMainCreateValue, setSlugMainCreateValue] = useState('');
    const [modalSubEditSlug, setModalSubEditSlug] = useState('');
    const [modalSubNewSlug, setModalSubNewSlug] = useState('');
    const [modalMainEditSlug, setModalMainEditSlug] = useState('');
    const [modalSubGroup, setModalSubGroup] = useState(0);

    const openModal = (type, mID, sID, name, slug, is_group, image) => {
        setModalMainID(mID);
        setModalSubID(sID);
        setModalMainName(name);
        setModalSubName(name);
        setModalType(type);
        setModalSubEditSlug((slug) ? slug : '');
        setModalMainEditSlug((slug) ? slug : '');
        setModalSubNewSlug('');
        setSlugMainCreateValue('');
        setNewCategoryImage('');
        setEditCategoryImage((image) ? image : '');
        setModalSubGroup(is_group);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const fetchImages = async () => {
        try {
            const response = await axios.get(`${backendUrl}/a/menu/categories/images`, { withCredentials: true });
            setImages(response.data.images);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${backendUrl}/a/menu/categories`, { withCredentials: true });
            setCategories(response.data.categories);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchImages();
        fetchCategories();
        setLoading(false);
    }, []);

    const handleDragEnd = (reorderedCategories) => {
        setCategories(reorderedCategories);
        updateCategoryOrder(reorderedCategories);
    };

    const updateCategoryOrder = async (reorderedCategories) => {
        try {
            await axios.post(`${backendUrl}/a/menu/categories`, reorderedCategories, { withCredentials: true });
        } catch (error) {
            console.log(error);
        }
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const submitCategoryUpdate = async () => {
        try {

            const name = {
                name: modalMainEditNameRef.current.value,
                slug: modalMainEditSlugRef.current.value,
                image: editCategoryImageRef.current.value
            };
            await axios.post(`${backendUrl}/a/menu/categories/update/${modalMainID}`, name, { withCredentials: true });
            
            window.showToast('WCL20404');
            setIsModalOpen(false);
            fetchCategories();

        } catch (error) {
            window.showToast('WCL20405');
            console.log(error);
        }
    };

    const submitCategoryNew = async () => {
        try {

            const data = {
                name: modalMainNewNameRef.current.value,
                slug: modalMainNewSlugRef.current.value,
                image: newCategoryImageRef.current.value
            };

            await axios.post(`${backendUrl}/a/menu/categories/create`, data, { withCredentials: true });
            
            window.showToast('WCL20411');
            setIsModalOpen(false);
            fetchCategories();

        } catch (error) {
            window.showToast('WCL20412');
            console.log(error);
        }
    };

    const submitSubcategoryNew = async () => {
        try {

            const data = {
                id: selectSubNewRef.current.value,
                name: modalSubNewNameRef.current.value,
                checked: (( checkboxSubNewRef.current.checked ) ? 1 : 0),
                slug: modalSubNewSlugRef.current.value
            };

            await axios.post(`${backendUrl}/a/menu/items`, data, { withCredentials: true });
            
            window.showToast('WCL20409');
            setIsModalOpen(false);
            fetchCategories();

        } catch (error) {
            window.showToast('WCL20410');
            console.log(error);
        }
    };

    const submitSubcategoryUpdate = async () => {
        try {

            const name = {
                name: modalSubEditNameRef.current.value,
                slug: modalSubEditSlugRef.current.value
            };
            await axios.post(`${backendUrl}/a/menu/items/update/${modalSubID}`, name, { withCredentials: true });
            
            window.showToast('WCL20402');
            setIsModalOpen(false);
            fetchCategories();

        } catch (error) {
            window.showToast('WCL20403');
            console.log(error);
        }
    };

    const submitSubcategoryDelete = async () => {
        try {

            await axios.get(`${backendUrl}/a/menu/items/delete/${modalSubID}`, { withCredentials: true });
            
            window.showToast('WCL20407');
            setIsModalOpen(false);
            fetchCategories();

        } catch (error) {
            window.showToast('WCL20408');
            console.log(error);
        }
    };

    const submitCategoryDelete = async () => {
        try {

            await axios.get(`${backendUrl}/a/menu/categories/delete/${modalMainID}`, { withCredentials: true });
            
            window.showToast('WCL20413');
            setIsModalOpen(false);
            fetchCategories();

        } catch (error) {
            window.showToast('WCL20414');
            console.log(error);
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <Session />
            <Title set="Mega Menu" />
            <div className="App">

                <div className="antialiased">
                    <Navbar />
                    <Sidenav />
                    <main className="p-4 lg:pl-[270px] h-screen max-w-screen-xl pt-20 z-0 mx-auto">
                        <div className='lg:flex justify-between items-center'>
                            <div className='flex-col'>
                                <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light py-7 uppercase'>Mega Menu</h2>
                            </div>
                        </div>
                        <div className='text-xl bg-gray-200 p-4'>Menu Options</div>
                        <div className='lg:flex justify-between items-center mb-10 w-full bg-gray-50 border border-gray-200'>
                            <div className='justify-center lg:justify-start flex gap-x-3 p-4'>
                                <Button size='sm' text='NEW SUBCATEGORY' onClick={() => { openModal('newSubCategory') }} />
                                <Button size='sm' text='NEW CATEGORY' onClick={() => { openModal('newCategory')} } />
                            </div>
                        </div>
                        <MegaMenuItems categories={categories} onDragEnd={handleDragEnd} openModal={openModal} />
                    </main>
                </div>


                <Modal isOpen={isModalOpen} onClose={closeModal} className='p-0 space-y-6' button width='w-[90%] md:w-[80%] lg:w-[60%] xl:w-[50%] 2xl:w-[40%]'>

                    {modalType === 'editCategory' && (
                        <>
                            <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Edit Category</h2>
                            <div className='space-y-6'>
                                <div className='space-y-2'>
                                    <div className='font-bold'>Category Name</div>
                                    <input type="text" ref={modalMainEditNameRef} defaultValue={modalMainName} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                </div>
                                <div className='space-y-2'>
                                    <div className='font-bold'>Slug</div>
                                    <SlugInput ref={modalMainEditSlugRef} value={modalMainEditSlug} onChange={() => { setModalMainEditSlug(modalMainEditSlugRef.current.value) }} />
                                </div>
                                <div className='space-y-2'>
                                    <div className='font-bold'>Menu Image</div>
                                    <select ref={editCategoryImageRef} value={editCategoryImage} onChange={(e) => setEditCategoryImage(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                        <option value=''>No Image</option>
                                        <option disabled className='text-gray-300'>-------------------------------------</option>
                                        {images.map((item, index) => (
                                            <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <Button size='sm' text='UPDATE' onClick={submitCategoryUpdate} />
                            </div>
                        </>
                    )}

                    {modalType === 'newSubCategory' && (
                        <>
                            <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Create Subcategory</h2>
                            <div className='space-y-6'>
                                <div className='space-y-5'>
                                    <div className='space-y-2'>
                                        <div className='font-bold'>Select Category</div>
                                        <Select options={categories} selectedOption={selectedOption} onChange={handleOptionChange} ref={selectSubNewRef} />
                                    </div>
                                    <div className='space-y-2'>
                                        <div className='font-bold'>Subcategory Name</div>
                                        <input type="text" ref={modalSubNewNameRef} defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                    </div>
                                    <div className='space-y-2'>
                                        <div className='font-bold'>Slug</div>
                                        <SlugInput ref={modalSubNewSlugRef} value={modalSubNewSlug} onChange={() => { setModalSubNewSlug(modalSubNewSlugRef.current.value) }} />
                                    </div>
                                    <div className='flex gap-x-2'>
                                        <input type="checkbox" ref={checkboxSubNewRef} className="form-checkbox h-5 w-5 text-kb-300 cursor-pointer" />
                                        <div className='font-bold'>Group Subcategory?</div>
                                    </div>
                                </div>
                                <Button size='sm' text='CREATE' onClick={submitSubcategoryNew} />
                            </div>
                        </>
                    )}

                    {modalType === 'editSubCategory' && (
                        <>
                            <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Edit Subcategory</h2>
                            <div className='space-y-6'>
                                <div className='space-y-2'>
                                    <div className='font-bold'>Subcategory Name</div>
                                    <input type="text" ref={modalSubEditNameRef} defaultValue={modalSubName} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                </div>
                                { modalSubGroup === 0 && (
                                    <div className='space-y-2'>
                                        <div className='font-bold'>Slug</div>
                                        <SlugInput ref={modalSubEditSlugRef} value={modalSubEditSlug} onChange={() => { setModalSubEditSlug(modalSubEditSlugRef.current.value) }} />
                                    </div>
                                ) }
                                <Button size='sm' text='UPDATE' onClick={submitSubcategoryUpdate} />
                            </div>
                        </>
                    )}

                    {modalType === 'deleteCategory' && (
                        <>
                            <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete Category</h2>
                            <div className='space-y-6'>
                                <div className='font-bold mb-10 text-xl'>{modalMainName}</div>
                                <Button size='sm' text='DELETE' onClick={submitCategoryDelete} />
                            </div>
                        </>
                    )}

                    {modalType === 'deleteSubCategory' && (
                        <>
                            <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete Subcategory</h2>
                            <div className='space-y-6'>
                                <div className='font-bold mb-10 text-xl'>{modalSubName}</div>
                                <Button size='sm' text='DELETE' onClick={submitSubcategoryDelete} />
                            </div>
                        </>
                    )}

                    {modalType === 'newCategory' && (
                        <>
                            <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>New Category</h2>

                            <div className='space-y-2'>
                                <div className='font-bold'>Category Name</div>
                                <input type="text" ref={modalMainNewNameRef} defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                            </div>
                            <div className='space-y-2'>
                                <div className='font-bold'>Slug</div>
                                <SlugInput ref={modalMainNewSlugRef} value={slugMainCreateValue} onChange={() => { setSlugMainCreateValue(modalMainNewSlugRef.current.value) }} />
                            </div>
                            <div className='space-y-2'>
                                <div className='font-bold'>Menu Image</div>
                                <select ref={newCategoryImageRef} value={newCategoryImage} onChange={(e) => setNewCategoryImage(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                    <option value=''>No Image</option>
                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                    {images.map((item, index) => (
                                        <option key={generateRandomKey()} value={item.relation_id}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <Button size='sm' text='CREATE CATEGORY' onClick={submitCategoryNew} />
                        </>
                    )}

                </Modal>

            </div>
        </>
    );
};

export default MegaMenu;
